import React from "react";
import CustomScroll from "../../common/CustomScroll";
import InfiniteScroll from 'react-infinite-scroll-component'

const GroupsCodeList = ({
  filterUniqueGroups,
  combinedFilteredData,
  handleGroupClick,
}) => {
  return (
      // <InfiniteScroll
      //     height={"50vh"}
      //     dataLength={combinedFilteredData.length}
      //     loader={<i className="fas fa-spinner fa-spin"></i>}
      //   >
      <CustomScroll maxHeight="50vh" smallerScroll={true}>
      {filterUniqueGroups(combinedFilteredData).length > 0 ? (
        filterUniqueGroups(combinedFilteredData).map((item, index) => (
          <div
            className={`d-flex row rounded pointer ${
              index % 2 !== 0 ? "lightgray" : ""
            }`}
            key={index}
            onClick={() => handleGroupClick(item.groupDesc)}
          >
            <div className="col-1 p-2 text-center">
              <span>{item.groupCode.replace(/\([^)]*\)/, "")}</span>
            </div>
            {item.groupCode.match(/\([^)]*\)/) && (
              <div className="col-2 p-2 text-center">
                <span>{item.groupCode.match(/\(([^)]*)\)/)[1]}</span>
              </div>
            )}
            <div className="col-9 p-2 text-end">
              <span>{item.groupDesc}</span>
            </div>
          </div>
        ))
      ) : (
        <span>No groups available</span>
      )}
    </CustomScroll>
    // {/* </InfiniteScroll> */}
  );
};

export default GroupsCodeList;
