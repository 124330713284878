import React, { useState, useEffect, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup'; 
import Modal from 'react-modal';  
import '../../styles/components/login.scss';
import LoginHeader from '../common/LoginHeader'; 
import MainFooter from '../common/MainFooter';
import specialitiesService from '../../services/Patient/specialities';
import specialties from "../../assets/Codes/specialties.json";
import { Link } from 'react-router-dom';
import SignUpService from '../../services/authentication/SignUpService';
import '../../styles/variables/_mixins.scss';
import vectorImage from '../../assets/Vector.png'; 
import vectorFullBGImage from '../../assets/Vectorfull.png'; 
import debounce from 'lodash/debounce';
const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    fullName: Yup.string().required('Name and Surname are required'),
    region: Yup.string().required('Region is required'),
    contactNo: Yup.string()
        .required('Phone number is required')
        .matches(
            /^(\+27|0)\d{9}$/,
            'Please enter a valid South African phone number (e.g., 0123456789 or +27123456789)'
        ),
    agreeToTerms: Yup.boolean().oneOf([true], "Please agree to the terms").required("Please agree to the terms"),
});

const SignupPage = () => {
    const signupService = SignUpService();
    const navigate = useNavigate()
    const [isChecked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalSelected, setModalSelected] = useState(false);
    const [responseDataList, setResponseDataList] = useState({});
    const [searchTermProc, setSearchTermProc] = useState("");
    const [filteredSpecialties, setFilteredSpecialties] = useState(specialties.specialty); // Assuming specialties.specialty is an array
    const [showSearchIcon, setShowSearchIcon] = useState(true);

    const handleCheckboxChange = () => {
        setChecked(!isChecked);
    };

    const handlePressPrivacyPolicy = () => {
        window.open("https://xpedient.co.za/privacy-policy/", "_blank");
    };
    const southAfricanRegions = [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "KwaZulu-Natal",
        "Limpopo",
        "Mpumalanga",
        "Northern Cape",
        "North West",
        "Western Cape"
    ];
    const debouncedDiscipline = useCallback(
        debounce((text) => {
            setSearchTermProc(text);
        }, 250),
        []
    );

    useEffect(() => {
            const filteredData = specialties.specialty.filter((item) => 
                item.toLowerCase().includes(searchTermProc.toLowerCase())
            )
            setFilteredSpecialties(filteredData);
            }, [searchTermProc]);

    const handleFormSubmit = async (values, actions) => {
        setLoading(true);
        const [firstName, ...rest] = values.fullName.split(" ");
        const surname = rest.join(" ");
        const trimmedEmail = values.email.trim();

        const registerDetails = {
            name: firstName,
            surname,
            discipline: values.disType,
            contactNumber: values.contactNo,
            email: trimmedEmail,
            practiceNumber: '',
            Region: values.region,
        };
        try {
            const responseData = await SignUpService(values.email).registerService(registerDetails);

            if (responseData.status === 0) {
                navigate("/createpassword", {
                    state: {
                        registerDetails: registerDetails 
                    },
                  });
                setResponseDataList(responseData.message);
            } else if (responseData.status < 0){
                setResponseDataList(responseData.message);
                navigate("/confirmlogin", { state: { confirmationMessage: responseData.message, isSuccess: false } }); // Pass error message

            }else {
                // setModalSelected(true);
                setResponseDataList(responseData.message);
                navigate("/confirmlogin",{state:{confirmationMessage:"Thank you for your enquiry, your information has successfully been submitted. Our sales team will reach out shortly to activate your account.",isSuccess: true}})
            }
        } catch (error) {
            console.error("Error:", error);
            actions.setErrors({ auth: error.message || "Submission failed" });
        } finally {
            setLoading(false);
        }
    };

    const renderModal = () => {
        return modalSelected ? (
            <div className="modal">
                <div className="modal-content">
                    <button onClick={() => setModalSelected(false)}>Close</button>
                    <h2>Thank you for your enquiry</h2>
                    <p>Your information has been successfully submitted. Our sales team will reach out shortly to activate your account.</p>
                </div>
            </div>
        ) : null;
    };

    return (
        <div className="login-page-wrapper page-bg-img">
            <img src={vectorImage} alt="Vector" className="vectorBig" />
            <img src={vectorFullBGImage} alt="Vector Background" className="vectorFullBGImage" />
            {/* Other images... */}
                <div className='row'>
                <div className='col-md-6 col-12'>
                <div className="container shadow bg-white rounded px-4 authContainer">
                <div className="row">
                    <div className="col-12">
                        <LoginHeader />
                    </div>
                    <div className="col-12">
                        <div className="mt-4">
                            <Formik
                                initialValues={{ email: '', fullName: '', contactNo: '', disType: '', agreeToTerms: false, region: '',  }}
                                validationSchema={validationSchema}
                                onSubmit={handleFormSubmit}
                            >
                                {({ errors, touched, isSubmitting }) => (
                                    <Form>
                                         <div className='mb-3 col-11'>
                                            <label htmlFor="fullName">Name & Surname*</label>
                                            <Field
                                                type="text"
                                                name="fullName"
                                                className={`form-control ${touched.fullName && errors.fullName ? 'is-invalid' : ''}`}
                                                id="fullName"
                                            />
                                            <ErrorMessage 
                                                name="fullName" 
                                                component="div" 
                                                className="error-text"
                                            />
                                        </div>
                                        <div className='mb-3 col-11'>
                                            <label htmlFor="contactNo">Mobile Number</label>
                                            <Field
                                                type="tel"
                                                name="contactNo"
                                                className={`form-control ${touched.contactNo && errors.contactNo ? 'is-invalid' : ''}`}
                                                id="contactNo"
                                            />
                                            <ErrorMessage 
                                                name="contactNo" 
                                                component="div" 
                                                className="error-text"
                                               />
                                        </div>
                                        <div className='mb-3 col-11'>
                                            <label htmlFor="email">Email*</label>
                                            <Field
                                                type="email"
                                                name="email"
                                                className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                            />
                                            <ErrorMessage 
                                                name="email" 
                                                component="div" 
                                                className="error-text"
                                            />
                                        </div>
                                        <div className='mb-3 col-11'>
                                            <label htmlFor="disType">Discipline Type</label>
                                            <Field as="select" name="disType" className="form-control" id="disType">
                                                <option value="" label="Select discipline type" />
                                                {filteredSpecialties.map((item, index) => (
                                                    <option key={index} value={item}>
                                                        {item}
                                                    </option>
                                                ))}
                                            </Field>
                                        </div>
                                        <div className='mb-3 col-11'>
                                            <label htmlFor="region">Region*</label>
                                            <Field as="select" name="region" className="form-control" id="region">
                                                <option value="">Select your region</option>
                                                {southAfricanRegions.map((region, index) => (
                                                    <option key={index} value={region}>
                                                        {region}
                                                    </option>
                                                ))}
                                            </Field>
                                            <ErrorMessage 
                                                name="region" 
                                                component="div" 
                                                className="error-text"
                                            />
                                        </div>
                                        <div className='mb-3 col-11' >
                                            <div className="form-check d-flex align-items-center">
                                                <Field
                                                    type="checkbox"
                                                    id="agreeToTerms"
                                                    className="form-check-input me-2"
                                                    name="agreeToTerms"
                                                />
                                                <label htmlFor="agreeToTerms" className="form-check-label text-black textinput">
                                                By continuing, you agree to our <a href="/T&Cs" className="termsConditions"  rel="noopener noreferrer">Terms of Service</a> and <a href="https://xpedient.co.za/privacy-policy/" className="termsConditions" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.

                                               
                                                
                                                </label>
                                            </div>
                                            <ErrorMessage name="agreeToTerms" component="div" className="error-text" />
                                        </div>

                                        <div className='mb-3 col-11'>
                                            <button type="submit" className="w-100 formButton" disabled={isSubmitting || loading}>
                                                <div className="text-white d-flex align-items-center justify-content-center">
                                                    {loading && <i className="fas fa-spinner fa-spin mr-2"></i>}
                                                    {loading ? 'Loading...' : 'Sign Up'}
                                                </div>
                                            </button>
                                        </div>
                                        <div className='mb-3'>
                                            <div className="text-black">
                                                Already have an account? {""}
                                                <span>
                                                    <Link to="/" className="font-bold">
                                                        Sign in
                                                    </Link>
                                                </span>
                                            </div>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                <div >
                    <MainFooter />
                </div>
                </div>
            </div>
                </div>
                <div className='col-md-6 col-0'></div>
                </div>
        
            {renderModal()}
        </div>
    );
};

export default SignupPage;
