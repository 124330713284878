import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import LoginHeader from "../common/LoginHeader";
import MainFooter from "../common/MainFooter";
import vectorImage from "../../assets/Vector.png";
import vectorFullBGImage from "../../assets/Vectorfull.png";
import OrganisationOverview from "./OrganisationOverview";
import Select from "react-select";
import SearchEntitiesService from "../../services/XPLoginUsers/SearchEnntitiesService";
import { UserContext } from "../../services/authentication/UserContext";
import { AuthContext } from "../../services/authentication/LoginService";
import { useAuth } from "../../services/authentication/LoginService";

const XPUserLogin = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const { email, password } = useContext(UserContext); 
  const { handleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  const [entities, setEntities] = useState([]);
  const [error, setError] = useState(false);
  const [entitiesFetched, setEntitiesFetched] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const { token } = useAuth();
  const searchEntity = SearchEntitiesService(token);

  useEffect(() => {
    const fetchEntities = async () => {
      setIsLoading(true);
      try {
        const result = await searchEntity.fetchSearchEntityHistory(searchTerm);
        if (result.status === 0) { 
          setEntities(result.options);
          setEntitiesFetched(true);
        } else {
          setError(true);
        }
      } catch (error) {
        console.error("Failed to fetch entities:", error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEntities();
  }, [searchTerm]);

  // Fixed option mapping to avoid duplication
  const entityOptions = entities.map(entity => ({
    value: entity.id,
    label: entity.name,
  }));

  const handleSelectEntity = async (option) => {
    if (!option) return;
    
    const entityId = option.value;
    const entityName = option.label;
    
    setIsLoading(true);
    
    try {
      const signInResult = await handleSignIn(email, password, entityId);
      if (signInResult.success) {
        navigateToHome(entityName, signInResult.subTaskCount);
      } else {
        alert(`Sign-in Failed: ${signInResult.error}`);
      }
    } catch (error) {
      console.error("Error during entity selection:", error);
      alert("Failed to sign in with selected entity. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  // Navigate to home page after successful sign in
  const navigateToHome = (entityName, subTaskCount) => {
    navigate("/clientportal", { 
      state: { 
        entityName: entityName,
        subTaskCount: subTaskCount 
      } 
    });
  };

  // Handle search input change
  const handleSearchChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  // Custom option formatter for the dropdown
  const formatOptionLabel = ({ label, entityId }) => (
    <div>
      <span> {label}</span>
    </div>
  );

  return (
    <div className="page-bg-img">
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorBig"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImage"
      />
      <img
        src={vectorFullBGImage}
        alt="../../assets/Vectorfull.png"
        className="vectorFullBGImageLeft"
      />
      <img
        src={vectorImage}
        alt="../../assets/Vector.png"
        className="vectorImageRight"
      />

      {/* White Container */}
      <div className="container d-flex justify-content-center flex-column" style={{backgroundColor:'transparent'}}>
        <div className="d-flex justify-content-center align-items-center">
          <div className="col-md-3 col-12 mt-5">
            <LoginHeader />
          </div>
        </div> 
        <div className="d-flex flex-column justify-content-center align-items-center mb-5 mt-5 col-12">
          <div className="d-flex align-items-center justify-content-start col-md-6 col-12">
            <span className="dropdownHeader">Xpedient Client</span>
          </div>
          
          {isLoading && <div className="text-center mb-2">Loading entities...</div>}
          
          <Select
            options={entityOptions}
            value={selectedOption}
            onChange={(option) => {
              setSelectedOption(option);
              handleSelectEntity(option);
            }}
            formatOptionLabel={formatOptionLabel}
            className="col-md-6 col-12"
            isSearchable={true}
            onInputChange={handleSearchChange}
            placeholder="Search and select an entity..."
            isLoading={isLoading}
            noOptionsMessage={() => 
              error ? "Error loading entities" : 
              entities.length === 0 ? "No entities found" : "No matches found"
            }
          />
        </div>

        <OrganisationOverview />
        <MainFooter />
      </div>
    </div>
  );
};

export default XPUserLogin;