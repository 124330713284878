import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ICD10Service from "../../services/Billing/ICD10Service";
import { useAuth } from "../../services/authentication/LoginService";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import { debounce } from "lodash";
import { useCallback } from "react";
import NavigateBack from "../NavigateBack";
import Loader from "../../utils/Loader";
function CategoriesPageFromChapters() {
  const location = useLocation();

  const { token } = useAuth();
  const groupDesc = location?.state?.groupDesc;
  const icd10Service = ICD10Service(token);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [filteredICD10DataGroups, setFilteredICD10DataGroups] = useState([]);
  const [combinedFilteredData, setCombinedFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [copiedData, setcopiedData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchICD10Codes = async () => {
      setLoading(true);
      try {
        const data = await icd10Service.ICD10Data();

        if (data) {
            const filteredDataGroups = data.filter(
              (item) => item.groupDesc.toLowerCase() === groupDesc.toLowerCase()
            );

            const filterCodes = filteredDataGroups.filter((item) => {
              const res = generateValuesInAlphanumericRange(
                item.groupCode.match(/\([^)]*\)/)[0]
              );

              return res.includes(item.icd10Code);
            });

            setcopiedData(filterCodes);
            setFilteredICD10DataGroups(filterCodes);
        } else {
          setErrorMessage("Failed to fetch data");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch ICD-10 codes");
      } finally {
        setLoading(false);
      }
    };

    fetchICD10Codes();
  }, []);

  const handleCategoryClick = (items) => {
    navigate("/codepagefromchapters", { state: { items } });
  };

  const searchCategories = () => {
    const codeFilter = copiedData.filter((each) =>
      each.icd10Code.includes(searchText)
    );

    const filteredData =
      codeFilter.length > 0
        ? codeFilter
        : copiedData.filter((item) =>
            item.icd103Code_Desc
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
    setFilteredICD10DataGroups(filteredData);
  };
  const handleSearch = useCallback(
    debounce(() => {
      searchCategories();
    }, 10),
    [searchText]
  ); // Adjust the delay as neededq
  useEffect(() => {
      handleSearch();
  }, [searchText]);
  useEffect(() => {
    setCombinedFilteredData(filteredICD10DataGroups);
  }, [filteredICD10DataGroups]);
  function generateValuesInAlphanumericRange(range) {
    const result = [];
    const match = range.match(/\(([A-Za-z]+)(\d+)-([A-Za-z]+)(\d+)\)/);

    if (match) {
      const startChar = match[1];
      const startNum = parseInt(match[2], 10);
      const endChar = match[3];
      const endNum = parseInt(match[4], 10);

      for (let i = startNum; i <= endNum; i++) {
        const formattedNum = i < 10 ? `0${i}` : `${i}`;
        result.push(`${startChar}${formattedNum}`);
      }
    }
    return result;
  }
  return (
    <Sidebar heading={"ICD10 & PROCEDURE CODE SEARCH"}>
      <div className="container mt-4">
        <NavigateBack text={"CATEGORIES"} />
        <div className="mt-4">
          <div className="lightColor centered-items mt-2 ">
            <div className="position-relative">
              <input
                onChange={(event) => {
                  const text = event.target.value;
                  setSearchText(text);
                }}
                type="text"
                className="border border-light lightColor input-custom"
                placeholder="Search"
                aria-label="Username"
              />
              <i
                onClick={() => searchCategories()}
                //    onClick={() => searchPatientInfo(values.patientID)}
                className="fa fa-search absolute-pos pointer"
              />
            </div>
          </div>
        </div>
        <Loader loading={loading} />
        {!loading && combinedFilteredData.length > 0 ? (
          <div className="mt-4">
            {combinedFilteredData.map((item, index) => {
              return (
                <div
                  className={`d-flex justify-content-between align-left rounded col-12 pointer ${
                    index % 2 !== 0 ? "lightgray" : ""
                  }`}
                  key={index}
                  onClick={() =>
                    handleCategoryClick([item.icd10Code, item.icd103Code_Desc])
                  }
                >
                  <span className="p-3 col-4 ">{item.icd103Code_Desc}</span>

                  <div className="p-3 col-4 ">
                    {item.groupCode.match(/\([^)]*\)/) && (
                      <div>{item.icd10Code}</div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>No categories available</div>
        )}
      </div>
    </Sidebar>
  );
}

export default CategoriesPageFromChapters;
