import * as Yup from "yup";
const assistantCodes = ["0008", "0009", "0076", "0029"];
const weightCodes = ["0019", "0016", "0018"];
const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];
//----------------------------------------- VALIDATION SCHEMA --------------------------------------------------//
const validationSchema = (
  serviceCenterData,
  treatingDocData,
  filteredSpecialties,
  filteredAssistants,
  showAssistant,
  isServCAdIconClicked,
  isRefDocAdIconClicked,
  times,
  selectedProcedureCodes
) =>

  Yup.object({
    // email: Yup.string().email('Invalid email address').required('Email is required'),
   
    serviceCenter:
      !isServCAdIconClicked &&
      Yup.string()
        .test("is-valid-plan", "Invalid Service Center Selected", (value) =>
          {
            return serviceCenterData.some(
              (option) => option.name.toUpperCase() === value?.toUpperCase()
            )
          
          }      
          
        )
        .required("Service Center is required")
        .matches(/\S/, "One non-whitespace required"),
    treatingDoctor: Yup.string().test(
      "is-valid-plan",
      "Invalid Treating Doctor Selected, please select a current Doctor",
      (value) =>
        treatingDocData.some(
          (option) => option.name.toUpperCase() === value?.toUpperCase()
        )
    ),

    nameAndsurname:
      isRefDocAdIconClicked &&
      Yup.string()
        //.matches(/^[a-zA-Z]+\s+[a-zA-Z]+$/, 'Please enter both a First name and a Last name separated by a space')
        .required("Full name is required"),

    pracNumber:
      isRefDocAdIconClicked &&
      isRefDocAdIconClicked &&
      Yup.string()
        .matches(/^[0-9]+$/, "Only numbers are allowed")
        .required("Field is required"),

    specCode:
      isRefDocAdIconClicked &&
      Yup.string()
        .test("is-valid-plan", "Invalid Speciality", (value) =>
          filteredSpecialties.some(
            (option) => option.toUpperCase() === value.toUpperCase()
          )
        )
        .required("Speciality is required")
        .matches(/\S/, "One non-whitespace required"),

    servCenterName:
      isServCAdIconClicked &&
      Yup.string()
        .required("Field is required")
        .matches(/\S/, "One non-whitespace required"),

    serviceDate: Yup.string().required("Service Date is required"),

    servCenterType:
      isServCAdIconClicked && Yup.string().required("Field is required"),

    selectedICD10Codes: Yup.array()
      .min(1, "At least one ICD10 code is required")
      .test(
        "hasVWXYZ",
        "The S/T code diagnosis must be followed by an external cause code (V/W/X/Y)",
        function (value) {
          const hasSTICD10 = value.some(
            (code) =>
              code.icd10Code.charAt(0).toUpperCase() === "S" ||
              code.icd10Code.charAt(0).toUpperCase() === "T"
          );
          const hasVWXYZICD10 = value.some(
            (code) =>
              code.icd10Code.charAt(0).toUpperCase() === "V" ||
              code.icd10Code.charAt(0).toUpperCase() === "W" ||
              code.icd10Code.charAt(0).toUpperCase() === "X" ||
              code.icd10Code.charAt(0).toUpperCase() === "Y"
          );
          return !(hasSTICD10 && !hasVWXYZICD10);
        }
      ),
    selectedProcedureCodes: Yup.array().min(
      1,
      "At least one procedure code is required"
    ),

    assistants: Yup.object().test(
      "assistant-validation",
      "Assistant information is required", // Generic error message
      function(value) {
        const { selectedProcedureCodes } = this.parent;
        if (!selectedProcedureCodes?.some(code => assistantCodes.includes(code.code))) {
          return true;
        }
        if (showAssistant){
          return true;
        }
         if (Object.keys(value).length === 0){
          return true;
         }
        for (const key in value){
          if (!value[key]?.name){
            return false;
          }
        }
        return true;   
      }
    ),
    nameandsurname: Yup.string().when("selectedProcedureCodes", {
      is: (codes) =>
        showAssistant &&
        codes.some((code) => assistantCodes.includes(code.code)),
      then: (schema) => schema.required("Full name is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    specialty: Yup.string().when("selectedProcedureCodes", {
      is: (codes) =>
        showAssistant &&
        codes.some((code) => assistantCodes.includes(code.code)),
      then: (schema) =>
        schema
          .required("Specialty is required")
          .matches(/\S/, "One non-whitespace required")
          .test(
            "is-valid-speciality",
            "Please choose a valid specialty",
            (value) => filteredSpecialties.some((item) => item === value)
          ),
      otherwise: (schema) => schema.notRequired(),
    }),
    fromTime: Yup.string().test(
      'fromTime-required',
      'From time is required',
      function (value) {
        if (
          selectedProcedureCodes &&
          selectedProcedureCodes.some((code) => timeFromToCodes.includes(code.code))
        ) {

          const isValid = selectedProcedureCodes
          .filter(code => timeFromToCodes.includes(code.code))
          .every(code => {

            const timeEntry = times[code.code];
            return timeEntry && timeEntry.fromTime !== "";
          });
          return isValid
        }

        return true;
      }
    ),
    toTime: Yup.string().test(
      'toTime-required',
      'To time is required',
      function (value) {
        if (
          selectedProcedureCodes &&
          selectedProcedureCodes.some((code) => timeFromToCodes.includes(code.code))
        ) {

          const isValid = selectedProcedureCodes
          .filter(code => timeFromToCodes.includes(code.code))
          .every(code => {

            const timeEntry = times[code.code];
            return timeEntry && timeEntry.toTime !== "";
          });
          return isValid
        }

        return true;
      }
    ),
    weight: Yup.number().when("selectedProcedureCodes", {
      is: (codes) => codes.some((code) => weightCodes.includes(code.code)),
      then: (schema) =>
        schema
          .required("Weight is required") // Ensure this line is kept
          .test("weight-required", "Weight is required", function (value) {
            const { selectedProcedureCodes } = this.parent;
            const hasWeightCode = selectedProcedureCodes.some((code) =>
              weightCodes.includes(code.code)
            );
            return hasWeightCode ? value !== undefined && value !== "" : true;
          })
          .positive("Weight must be a positive number"),

      otherwise: (schema) => schema.notRequired(),
    }),
    height: Yup.number().when("selectedProcedureCodes", {
      is: (codes) => codes.some((code) => code.code === "0018"),
      then: (schema) =>
        schema
          .test("height-required", "Height is required", function (value) {
            const { selectedProcedureCodes } = this.parent;
            return selectedProcedureCodes.some((code) => code.code === "0018")
              ? !!value
              : true;
          })
          .positive("Height must be a positive number"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export default validationSchema;
