// useProcedureCodesStore.js
import {create} from 'zustand';

const useTreatingDoctorStore = create((set) => ({
    selectedTreatingDoc:null,
    defaultTreatingDoc:"",
    defaultTreatingDocID:"",
    treatingDocData:[], 
    setTreatingDocData:(treatingDocData)=>set({treatingDocData}),
    setDefaultTreatingDocID:(defaultTreatingDocID)=>set({defaultTreatingDocID}),
    setDefaultTreatingDoc:(defaultTreatingDoc)=>set({defaultTreatingDoc}),
    setSelectedTreatingDoc:(selectedTreatingDoc)=>set({selectedTreatingDoc}),
    clearTreatingDoctorStore: () => set({
        selectedTreatingDoc:null,
        defaultTreatingDoc:"",
        defaultTreatingDocID:"",
    })
}));

export default useTreatingDoctorStore;
