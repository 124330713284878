import React, { useState, useCallback, useEffect } from "react";
import { useAuth } from "../../services/authentication/LoginService";
import FavouriteHistoryService from "../../services/Favourites/FavouriteHistoryService";
import FavouriteDetailsService from "../../services/Favourites/FavouriteDetailsService";
import RenameFavouriteService from "../../services/Favourites/RenameFavouriteService.js";
import DeleteFavouriteService from "../../services/Favourites/DeleteFavouriteService.js";
import debounce from "lodash.debounce";
import Sidebar from "../Sidebar/Sidebar.js";
import {faPlus,faEdit} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddFavouriteService from "../../services/Favourites/AddFavouriteService.js";
import "../../styles/components/invoice.scss";
import SideNavbar from "../common/SideNavbar.js";
import EditFavouritesItems from "./EditFavouritesItems.js";
import CustomScroll from "../common/CustomScroll.js";
import GenericButton from "../GenericButton.js";
import useProcedureCodesStore from "../state/ProcedureCodeStore.js";
import ProcedureCode from "../Invoices/ProcedureCodes.js";
import { Formik } from "formik";
import ICD10 from "../Invoices/ICD10Codes.js";
import NappiCodeSelector from "../Invoices/NappiCodes.js";
import GenericModal from "../GenericModal.js";
import SearchCodes from "./SearchCodes.js";
import AllFavourites from "./AllFavourites.js";
import Loader from "../../utils/Loader.js";
const FavouriteHistoryScreen = ({ route }) => {
  const { token } = useAuth();
  const addFavouriteService = AddFavouriteService(token);
  const deleteService = DeleteFavouriteService(token);
  const [codeType, setCodeType] = useState(1);
  const renameService = RenameFavouriteService(token);
  const { handleAddCodeFavourite } = RenameFavouriteService(token);
  const [addOrEdit, setAddOrEdit] = useState(true); //Check if user wants to Edit Favourites or Add new Favourites, true=edit,false=add new favourite
  const [favourites, setFavourites] = useState({});
  const [icd10Codes, seticd10Codes] = useState([]);
  const [prodCodes, setProdCodes] = useState([]);
  const [errorFavourite, setErrorFavourite] = useState("");
  const [currentKey, setCurrKey] = useState("");
  const [selectedICD10CodesCopy, setSelectedICD10CodesCopy] = useState([]);
  const [selectedProdCodesCopy, setSelectedProdCodesCopy] = useState([]);
  const [selectedNappiCodesCopy, setSelectedNappiCodesCopy] = useState([]);
  const {
    selectedProcedureCodes,
    setSelectedProcedureCodes,
    selectedICD10Codes,
    setSelectedICD10Codes,
    selectedNappiCodes,
    setselectedNappiCodes,
  } = useProcedureCodesStore();
  const [isFirstModalVisible, setIsFirstModalVisible] = useState(false);
  const [isSecondModalVisible, setIsSecondModalVisible] = useState(false);
  const [selectedCodeType, setSelectedCodeType] = useState(1);
  const [loading, setLoading] = useState(false);
  const [loadingState, setLoadingState] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [nappiCodes, setNappiCodes] = useState([]);
  const [filteredFavourites, setFilteredFavourites] = useState([]);
  const [descriptionSubmit,setDescriptionSubmit] = useState("")
  const [description, setDescription] = useState("");
  const [descriptionCompare, setDescriptionCompare] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [activeCodeType, setActiveCodeType] = useState("icd10");
  const [icd10Data, setIcd10Data] = useState([]);
  const [procedureData, setProcedureData] = useState([]);
  const [codeAddType, setCodeAddType] = useState("ICD10");
  const [allNappiData, setAllNappiData] = useState([]);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeButton, setActiveButton] = useState("ICD10");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const [error, setError] = useState("");
  const [emptyTextErr, setEmptyTextErr] = useState("");

  useEffect(() => {
    if (!isSidebarOpen && !isFirstModalVisible) {
      if (selectedProcedureCodes.length > 0) {
        setSelectedProcedureCodes([]);
      }
      if (selectedNappiCodes.length > 0) {
        setselectedNappiCodes([]);
      }
      if (selectedICD10Codes.length > 0) {
        setSelectedICD10Codes([]);
      }
    }
  }, [isSidebarOpen, isFirstModalVisible]);
  const handleDelete = async (key) => {
    setLoading(true);
    try {
      await deleteService.handleDeleteFavourite(key);
      fetchFavourites();
    } catch (error) {
      console.error("Error deleting favourite:", error);
    } finally {
      setLoading(false);
      closeModal();
    }
  };
  useEffect(() => {
    if (addOrEdit) {
      if (selectedProcedureCodes.length > 1) {
        setSelectedProcedureCodes(selectedProcedureCodes.slice(1));
      } else if (selectedICD10Codes.length > 1) {
        setSelectedICD10Codes(selectedICD10Codes.slice(1));
      } else if (selectedNappiCodes.length > 1) {
        setselectedNappiCodes(selectedNappiCodes.slice(1));
      }
    }
  }, [selectedNappiCodes, selectedICD10Codes, selectedProcedureCodes]);

  const fetchFavourites = async () => {
    setLoading(true);
    try {
      const service = FavouriteHistoryService(token);
      const favouritesData = await service.fetchFavouriteHistory();
      setFavourites(favouritesData.itemList);
      setFilteredFavourites(favouritesData.itemList);

      // Setting the initial loading state for items
      const falseObject = favouritesData.itemList.reduce((obj, item) => {
        obj[item.id] = false;
        return obj;
      }, {});

      setLoadingState(falseObject);
    } catch (error) {
      console.error("Error fetching favourites:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFavourites();
  }, []);

  const toggleBothModals = () => {
    setIsFirstModalVisible(false);
    setIsSidebarOpen(false);
  };
  const fetchFavouriteDetails = async (billingID, codeType) => {

    if (
      icd10Codes.hasOwnProperty(billingID) ||
      prodCodes.hasOwnProperty(billingID) ||
      nappiCodes.hasOwnProperty(billingID)
    ) {
      return;
    }
    setLoading(true)
    try {
      const service = FavouriteDetailsService(token);
      const favouriteDetailsData = await service.fetchFavouriteDetails(
        billingID,
        codeType
      );
      if (codeType === 1) {
        seticd10Codes((prevState) => ({
          ...prevState,
          [billingID]: favouriteDetailsData.itemList,
        }));
      } else if (codeType === 2) {
        setProdCodes((prevState) => ({
          ...prevState,
          [billingID]: favouriteDetailsData.itemList,
        }));
      } else if (codeType === 3) {
        setNappiCodes((prevState) => ({
          ...prevState,
          [billingID]: favouriteDetailsData.itemList,
        }));
      }
    } catch (error) {
      console.error("Error fetching favourite details:", error);
    } finally {
      setLoading(false)
    }
  };

  const removeNewCode = (codeType) => {
    if (codeType === 1) {
      setSelectedICD10Codes([]);
    } else if (codeType === 2) {
      setSelectedProcedureCodes([]);
    } else {
      setselectedNappiCodes([]);
    }
  };

  const descriptionCompareSet = (key) => {
    if (favourites.length>0){
      favourites.map((item)=>{
        if (item.id === key){
          setDescription(item.description)
          setDescriptionCompare(item.description);
          return
        }
      })
    }
    // setDescription("")
    // setDescriptionCompare("");
  }
  const handleEditIconClick = (key) => {
    setAddOrEdit(true);
    setCurrKey(key);
    descriptionCompareSet(key)
    const icd10CodesCopy = icd10Codes[key] || {};
    const prodCodesCopy = prodCodes[key] || {};
    const nappiCodesCopy = nappiCodes[key] || {};

    setSelectedICD10CodesCopy(icd10CodesCopy);
    setSelectedProdCodesCopy(prodCodesCopy);
    setSelectedNappiCodesCopy(nappiCodesCopy);
    setIsFirstModalVisible(true);
  };
  const toggleFirstModal = () => {

    setIsFirstModalVisible(!isFirstModalVisible);
  };

  const handleSaveEdit = async (key) => {
    if (key !== null && description.trim()) {
      try {
        if (emptyTextErr.length > 0) {
          setEmptyTextErr("");
        }
        setDescriptionSubmit(description)
        await renameService.handleRenameFavourite(key, description);

        setFavourites((prevFavourites) => ({
          ...prevFavourites,
          [key]: description,
        }));
        setDescription("");
      } catch (error) {
        console.error("Error", "Failed to rename favourite. Please try again.");
      }
    } else {
      setEmptyTextErr("Description cannot be empty.");
    }
  };
  const handleAddCode = async () => {
    if (!description.length > 0) {
      setEmptyTextErr("Description cannot be empty");
      return;
    }
    if (currentKey) {
      if (
        selectedICD10Codes.length > 0 ||
        selectedProcedureCodes.length > 0 ||
        selectedNappiCodes.length > 0
      ) {
        try {
          if (selectedCodeType === 1) {
            setSelectedICD10CodesCopy((prev) => ({
              ...prev,
              [selectedICD10Codes[0].icd10Code]: {
                quantity: 1,
              },
            }));
            seticd10Codes((prevCodes) => {
              const updatedObj = { ...prevCodes };
              if (updatedObj[currentKey]) {
                updatedObj[currentKey] = {
                  ...updatedObj[currentKey],
                  [selectedICD10Codes[0].icd10Code]:
                    selectedICD10Codes[0].chapterDesc,
                };
              }

              return updatedObj; // Return the updated object
            });
          } else if (selectedCodeType === 2) {
            setSelectedProdCodesCopy((prev) => ({
              ...prev,
              [selectedProcedureCodes[0].code]: {
                quantity: 1,
              },
            }));
            setProdCodes((prevCodes) => {
              const updatedObj = { ...prevCodes };
              if (updatedObj[currentKey]) {
                updatedObj[currentKey] = {
                  ...updatedObj[currentKey],
                  [selectedProcedureCodes[0].code]:
                    selectedProcedureCodes[0].description,
                };
              }

              return updatedObj;
            });
          } else if (selectedCodeType === 3) {
            setSelectedNappiCodesCopy((prev) => ({
              ...prev,
              [selectedNappiCodes[0].code]: {
                quantity: 1,
              },
            }));
            setNappiCodes((prevCodes) => {
              const updatedObj = { ...prevCodes };
              if (updatedObj[currentKey]) {
                updatedObj[currentKey] = {
                  ...updatedObj[currentKey],
                  [selectedNappiCodes[0].code]: selectedNappiCodes[0].quantity,
                };
              }
              return updatedObj;
            });
          }
          setLoading(true);
          const code =
            codeAddType === "ICD10"
              ? selectedICD10Codes[0].icd10Code
              : codeAddType === "ProcedureCode"
              ? selectedProcedureCodes[0].code
              : selectedNappiCodes[0].code;
          await handleAddCodeFavourite(currentKey, code, selectedCodeType);
          setLoading(false);
          toggleBothModals();
        } catch (error) {
          console.error("Error adding code:", error);
        }
      } else {
        if (descriptionCompare !== description) {  //edit description
          await handleSaveEdit(currentKey);
          await fetchFavourites();
          toggleBothModals();
        } else {
          setEmptyTextErr("Please Enter a Code Value or edit the description");
        }
      }
    } else {
      if (descriptionCompare !== description) { 
        handleSaveEdit(currentKey);
      } else {
        console.error("Error", "Please select a favourite.");
      }
    }
  };
  const handleDeleteCode = async (currentKey, code, codeType) => {
    try {
      const response = await deleteService.handleDeleteCodeFavourite(
        currentKey,
        code
      );
      if (response.status === 0) {
        switch (codeType) {
          case 1: // ICD-10
            setSelectedICD10CodesCopy((prev) => {
              const updatedCodes = { ...prev };
              delete updatedCodes[code];
              return updatedCodes;
            });
            seticd10Codes(() => {
              const updatedObj = { ...icd10Codes }; // Create a shallow copy of the original object

              if (updatedObj[currentKey]) {
                delete updatedObj[currentKey][code]; // Delete the specific code
              }

              return updatedObj; // Return the updated object
            });

            break;
          case 2: // Procedure Code
            setSelectedProdCodesCopy((prev) => {
              const updatedCodes = { ...prev };
              delete updatedCodes[code];
              return updatedCodes;
            });
            setProdCodes(() => {
              const updatedObj = { ...prodCodes }; // Create a shallow copy of the original object

              if (updatedObj[currentKey]) {
                delete updatedObj[currentKey][code]; // Delete the specific code
              }

              return updatedObj; // Return the updated object
            });
            break;
          case 3: // Nappi Code
            setSelectedNappiCodesCopy((prev) => {
              const updatedCodes = { ...prev };
              delete updatedCodes[code];
              return updatedCodes;
            });
            setNappiCodes(() => {
              const updatedObj = { ...nappiCodes }; // Create a shallow copy of the original object

              if (updatedObj[currentKey]) {
                delete updatedObj[currentKey][code]; // Delete the specific code
              }

              return updatedObj; // Return the updated object
            });
            break;
          default:
            console.warn("Unknown code type:", codeType);
        }
      } else {
        console.error("Failed to delete code:", response.message);
      }
    } catch (error) {
      console.error("Error deleting code:", error);
    }
  };
  const handlePlusIconClick = (codeType) => {
    setIsFirstModalVisible(false);
    setSelectedCodeType(codeType);
    setActiveCodeType(codeType);
    setSearchTerm("");
  };

  useEffect(() => {
    if (!isFirstModalVisible && selectedCodeType !== null) {
      setIsSecondModalVisible(true);
      setSearchTerm("");
    }
  }, [isFirstModalVisible, selectedCodeType]);


  function handleDescText(text){
    if (text.length>0){
      if (emptyTextErr.length>0){
        setEmptyTextErr("")
      }
    }
    setDescription(text)
  }
  const filterData = (text, data, codeType) => {
    const lowerCasedText = text.toLowerCase();
    const dataArray = Array.isArray(data) ? data : Object.values(data);

    return dataArray
      .filter((item) => {
        switch (codeType) {
          case 1:
            return (
              (item.icd10Code &&
                item.icd10Code.toLowerCase().includes(lowerCasedText)) ||
              (item.whoFullDesc &&
                item.whoFullDesc.toLowerCase().includes(lowerCasedText))
            );
          case 2:
            return (
              (item.code && item.code.toLowerCase().includes(lowerCasedText)) ||
              (item.description &&
                item.description.toLowerCase().includes(lowerCasedText))
            );
          case 3:
            return (
              (item.code && item.code.toLowerCase().includes(lowerCasedText)) ||
              (item.description &&
                item.description.toLowerCase().includes(lowerCasedText))
            );
          default:
            return false;
        }
      })
      .slice(0, 20);
  };

  const currentKeySet = (key) =>{
    setCurrKey(key)
  }
  const debouncedSearch = useCallback(
    debounce((text) => {
      let data;
      switch (selectedCodeType) {
        case 1:
          data = icd10Data;
          break;
        case 2:
          data = procedureData;
          break;
        case 3:
          data = allNappiData;
          break;
        default:
          data = [];
      }
      const filtered = filterData(text, data, selectedCodeType);
      setFilteredData(filtered);
    }, 250),
    [selectedCodeType, icd10Data, procedureData, allNappiData]
  );

  useEffect(() => {
    debouncedSearch(searchTerm);
    return () => {
      debouncedSearch.cancel();
    };
  }, [searchTerm, debouncedSearch]);

  const handleSaveToFavorites = async () => {
    const icd10Codes = selectedICD10Codes.map((code) => code.icd10Code);
    const procedureCodes = selectedProcedureCodes.map((code) => code.code);
    const nappicodes = selectedNappiCodes.map((code) => code.code);

    const favouriteDetails = {
      billingDesc: description,
      diagnosisCodes: icd10Codes,
      billingCodes: procedureCodes,
      nappiCodes: nappicodes,
    };

    if (!description.trim()) {
      setErrorFavourite("Description is required");
      return;
    }
    try {
      await addFavouriteService.handleAddFavourite(
        favouriteDetails
      );
      await fetchFavourites();
    } catch (error) {
      console.error("Error adding to favorites:", error);
    } finally {
      toggleBothModals();
    }
  };
  const toggleNewFavourite = () => {
    setSelectedICD10CodesCopy([]);
    setSelectedProdCodesCopy([]);
    setSelectedNappiCodesCopy([]);
    setAddOrEdit(false);
    setEmptyTextErr("")
    setDescription("")
    setIsSidebarOpen(true)
  };

  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const toggleSidebar = () => {
    if (isFirstModalVisible) {
      setIsFirstModalVisible(false);
    }
    setIsSidebarOpen(true);
  };

  const handleButtonClick = (buttonName) => {
    if (buttonName === "ICD10") {
      setSelectedCodeType(1);
    } else if (buttonName === "ProcedureCode") {
      setSelectedCodeType(2);
    } else {
      setSelectedCodeType(3);
    }
    setCodeAddType(buttonName);
    setActiveButton(buttonName);
  };

  return (
    <div>
      <Sidebar heading={"FAVOURITES"}>
        <div className="container">
          <Formik
            initialValues={{
              selectedProcedureCodes: "",
            }}
          >
            {({ setFieldValue, handleBlur }) => (
              <div>
                {/* add fav button */}
                <div className="button-container-fav">
                  <button className="addnewfav" onClick={toggleNewFavourite}>
                    {" "}
                    <FontAwesomeIcon
                      icon={faPlus}
                      className="plus-icon-fav"
                    />{" "}
                    Add New Favourites
                  </button>
                </div>
                {/* search name of code bundle */}
                <SearchCodes
                  description={descriptionSubmit}
                  faves={favourites}
                  filteredFavourites={setFilteredFavourites}
                />
                <AllFavourites
                 filteredFavourites={filteredFavourites}
                 handleEditIconClick={handleEditIconClick}
                 loadingState={loadingState}
                 openModal={openModal}
                 icd10Codes={icd10Codes}
                 prodCodes={prodCodes}
                 nappiCodes={nappiCodes}
                 favourites={favourites}
                 currentKey={currentKey}
                 fetchFavouriteDetails={fetchFavouriteDetails}
                 setCurrKey={currentKeySet}            
                />
                {/* Sidebar Add Codes*/}
                <SideNavbar
                  proceed={addOrEdit ? handleAddCode : handleSaveToFavorites }//false = new
                  heading={addOrEdit ? "Edit Codes" : "Add Codes"}
                  state={isSidebarOpen}
                  close={closeSidebar}
                  buttonText={"Save"}
                >
                  <div className="mt-5 p-2">
                  <small className="bold-txt">
                      {addOrEdit ? <span>Rename Code Bundle</span> : <span>Enter Name of Code Bundle</span>}
                    </small>
                    <input
                      type="text"
                      id="bundleName"
                      className="description-input"
                      placeholder="Description"
                      value={description}
                      onChange={(e) => handleDescText(e.target.value)}
                    />
                    <span className="text-danger">{emptyTextErr}</span>
                    <GenericButton
                      styling={`w-100 formButton text-white codescolors ${
                        activeButton === "ICD10" ? "active" : ""
                      }`}
                      text={"ICD10"}
                      func={() => handleButtonClick("ICD10")}
                    />
                    <GenericButton
                      styling={`w-100 formButton text-white codescolors ${
                        activeButton === "ProcedureCode" ? "active" : ""
                      }`}
                      text={"Procedure Code"}
                      func={() => handleButtonClick("ProcedureCode")}
                    />
                    <GenericButton
                      styling={`w-100 formButton text-white codescolors ${
                        activeButton === "NappiCode" ? "active" : ""
                      }`}
                      text={"Nappi Code"}
                      func={() => handleButtonClick("NappiCode")}
                    />
                    {/* <CustomScroll maxHeight={"800px"} smallerScroll={true} > */}
                    {activeButton === "ICD10" ? (
                    
                      <ICD10 />
             
                    ) : activeButton === "ProcedureCode" ? (
                      <ProcedureCode
                      hideHeadings={true}
                        reInvoice={null}
                        sidebar={true}
                      />
              
                    ) : (

                      <NappiCodeSelector sidebar={true} />

                    )}

                    {/* </CustomScroll> */}
                  </div>
                </SideNavbar>
                {/* Delete modal confirmation */}
                <GenericModal
                  heading={"Confirm Deletion"}
                  description={"Are you sure you want to delete this template?"}
                  proceed={() => handleDelete(currentKey)}
                  close={() => closeModal()}
                  conditional={isModalOpen}
                />
                <Loader loading={loading}/>
                <SideNavbar
                hideBtn={true}
                  heading={addOrEdit ? "Edit Favourites" : "Add New Favourite"}
                  notice={false}
                  state={isFirstModalVisible}
                  close={() => setIsFirstModalVisible(false)}
                  validationMsg={true}
                >
                  <div className="mt-4 p-2">
                    <div className="mt-4">
                      <CustomScroll maxHeight={"500px"} smallerScroll={true}>
                        {codeType === 1 && (
                          <EditFavouritesItems
                            existing={addOrEdit}
                            editCode={() => handlePlusIconClick(1)} // For ICD10 Codes
                            deleteCode={handleDeleteCode}
                            data={
                              addOrEdit
                                ? selectedICD10CodesCopy
                                : selectedICD10Codes
                            }
                            title={"ICD10 Codes"}
                            currentKey={currentKey}
                            codeType={1}
                            dataAdded={selectedICD10Codes}
                            removeNewCode={removeNewCode}
                          />
                        )}
                        <EditFavouritesItems
                          existing={addOrEdit}
                          editCode={() => handlePlusIconClick(2)} // For ICD10 Codes
                          deleteCode={handleDeleteCode}
                          data={
                            addOrEdit
                              ? selectedProdCodesCopy
                              : selectedProcedureCodes
                          }
                          title={"Procedure Codes"}
                          currentKey={currentKey}
                          codeType={2}
                          dataAdded={selectedProcedureCodes}
                          removeNewCode={removeNewCode}
                        />
                        <EditFavouritesItems
                          existing={addOrEdit}
                          editCode={() => handlePlusIconClick(3)} // For ICD10 Codes
                          deleteCode={handleDeleteCode}
                          data={
                            addOrEdit
                              ? selectedNappiCodesCopy
                              : selectedNappiCodes
                          }
                          title={"Nappi Codes"}
                          currentKey={currentKey}
                          codeType={3}
                          dataAdded={selectedNappiCodes}
                          removeNewCode={removeNewCode}
                        />
                        <GenericButton
                          styling={"bg-secondary text-white rounded mt-4 p-1"}
                          text={"Edit Codes"}
                          src={"fas fa-edit me-1"}
                          func={() => toggleSidebar()}
                        />
                      </CustomScroll>
                    </div>
                  </div>
                </SideNavbar>
              </div>
            )}
          </Formik>
        </div>
      </Sidebar>
    </div>
  );
};

export default FavouriteHistoryScreen;
