import React from "react";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";
import PropTypes from 'prop-types';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const MonthlyReports = ({ reports, ageAnalysis, ageAnalysisStatus, ledger }) => {
  const filteredReports = reports.map(report => ({
    ...report,
    month_list: Object.fromEntries(
      Object.entries(report.month_list).filter(([key, value]) => !isNaN(value) && key !== 'Average' && key !== 'Total')
    )
  }));

  const months = filteredReports.length ? Object.keys(filteredReports[0].month_list) : [];
  // const months = filteredReports.length ? ["Average", "December", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "Total"] : [];
  const turnover = [];
  const receipting = [];

  reports.forEach(report => {
    if (report.breakdown_type === "Turnover") {
      turnover.push(Object.values(report.month_list).map(value => Math.abs(parseFloat(value)) || 0));
    }
    if (report.breakdown_type === "Receipting") {
      receipting.push(Object.values(report.month_list).map(value => Math.abs(parseFloat(value)) || 0));
    }
  });

  const flatTurnover = turnover.length ? turnover[0] : [];
  const flatReceipting = receipting.length ? receipting[0] : [];
  const turnoverReport = reports.find(r => r.breakdown_type === "Turnover");
  const receiptingReport = reports.find(r => r.breakdown_type === "Receipting");


  const verticalChartData = months.flatMap(month => {
    const turnoverValue = parseFloat(turnoverReport?.month_list[month]) || 0;
    const receiptingValue = parseFloat(receiptingReport?.month_list[month]) || 0;
    return [
      { value: turnoverValue, spacing: 10, label: month.slice(0, 3) },
      { value: Math.abs(receiptingValue), frontColor: "#7660a9" }
    ];
  });
  // Calculate chart data for all visualizations
  const {
    maxVerticalValue,
    stepValue,
    totalTurnover,
    totalReceipting,
    maxHorizontalValue
  } = (() => {
    const turnoverReport = reports.find(r => r.breakdown_type === "Turnover");
    const receiptingReport = reports.find(r => r.breakdown_type === "Receipting");

    const verticalChartData = months.flatMap(month => {
      const turnoverValue = parseFloat(turnoverReport?.month_list[month]) || 0;
      const receiptingValue = parseFloat(receiptingReport?.month_list[month]) || 0;
      return [
        { value: turnoverValue, spacing: 10, label: month.slice(0, 3) },
        { value: Math.abs(receiptingValue), frontColor: "#7660a9" }
      ];
    });

    const maxVerticalValue = Math.max(...verticalChartData.map(d => d.value));
    const stepValue = Math.ceil(maxVerticalValue / 10_000) * 10_000 / 10;

    const totalTurnover = Object.values(turnoverReport?.month_list || {})
      .reduce((sum, val) => {
        const parsedVal = parseFloat(val);
        return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
      }, 0);

    const totalReceipting = Math.abs(Object.values(receiptingReport?.month_list || {})
      .reduce((sum, val) => {
        const parsedVal = parseFloat(val);
        return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
      }, 0));

    const maxHorizontalValue = Math.max(totalTurnover, totalReceipting) * 1.2;

    return {
      verticalChartData,
      maxVerticalValue,
      stepValue,
      totalTurnover,
      totalReceipting,
      maxHorizontalValue
    };
  })();

  const chartOptions = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Turnover vs Receipting (Absolute Values)',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const originalValue = reports.find(r => 
              r.breakdown_type === tooltipItem.dataset.label
            )?.month_list[tooltipItem.label];
            return `${tooltipItem.dataset.label}: ${originalValue}`;
          },
        },
      },
      legend: {
        position: 'top',
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 2000000,
          callback: function (value) {
            return `${value}`;
          },
        },
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
      },
    },
  };
  const formatCurrency = (value) => {
    if (!value) return 'R0.00';
    
    const numValue = typeof value === 'string' ? parseFloat(value) : value;
    return `R${numValue.toLocaleString('en-ZA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };
  

  const chartData = {
    labels: months,
    datasets: [
      {
        label: 'Turnover',
        data: flatTurnover,
        backgroundColor: '#7999CC',
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 0.8,
      },
      {
        label: 'Receipting',
        data: flatReceipting,
        backgroundColor: '#7660A9',
        borderWidth: 0,
        categoryPercentage: 0.8,
        barPercentage: 0.8,
      },
    ],
  };

  const generateHorizontalBarChart = (turnoverValue, receiptingValue, maxValue) => {
    const width = 600;
    const height = 200;
    const padding = {
      left: 120,
      right: 100,
      top: 40,
      bottom: 60
    };
    const barHeight = 30;
    const spacing = 30;

    const chartWidth = width - padding.left - padding.right;
    const xScale = chartWidth / maxValue;

    const firstBarY = padding.top;
    const secondBarY = firstBarY + barHeight + spacing;

    const numberOfLabels = 6;
    const roundedMax = Math.ceil(maxValue / 100000) * 100000;
    const increment = roundedMax / (numberOfLabels - 1);

    return `
      <svg width="${width}" height="${height}" style="max-width: 100%;">
        ${Array.from({length: numberOfLabels}, (_, i) => {
          const value = i * increment;
          const x = padding.left + (value * xScale);
          return `
            <line 
              x1="${x}" 
              y1="${padding.top - 10}" 
              x2="${x}" 
              y2="${height - padding.bottom}"
              stroke="#DEDEDE"
              stroke-width="1"
              stroke-dasharray="4,4"
            />`;
        }).join('')}
        <rect 
          x="${padding.left}"
          y="${firstBarY}"
          width="${turnoverValue * xScale}"
          height="${barHeight}"
          fill="#7999CC"
        />
        <text 
          x="${padding.left - 15}"
          y="${firstBarY + barHeight/2}"
          text-anchor="end"
          dominant-baseline="middle"
          font-size="12"
        >Turnover</text>
        <text 
          x="${padding.left + turnoverValue * xScale + 10}"
          y="${firstBarY + barHeight/2}"
          dominant-baseline="middle"
          font-size="12"
        >R${turnoverValue.toLocaleString()}</text>
        <rect 
          x="${padding.left}"
          y="${secondBarY}"
          width="${receiptingValue * xScale}"
          height="${barHeight}"
          fill="#7660a9"
        />
        <text 
          x="${padding.left - 15}"
          y="${secondBarY + barHeight/2}"
          text-anchor="end"
          dominant-baseline="middle"
          font-size="12"
        >Receipting</text>
        <text 
          x="${padding.left + receiptingValue * xScale + 10}"
          y="${secondBarY + barHeight/2}"
          dominant-baseline="middle"
          font-size="12"
        >R${receiptingValue.toLocaleString()}</text>
        <line 
          x1="${padding.left}"
          y1="${height - padding.bottom}"
          x2="${width - padding.right}"
          y2="${height - padding.bottom}"
          stroke="#7660a9"
          stroke-width="1"
        />
        <line 
          x1="${padding.left}"
          y1="${padding.top - 10}"
          x2="${padding.left}"
          y2="${height - padding.bottom}"
          stroke="#7660a9"
          stroke-width="1"
        />
        ${Array.from({length: numberOfLabels}, (_, i) => {
          const value = i * increment;
          const x = padding.left + (value * xScale);
          return `
            <text 
              x="${x}"
              y="${height - padding.bottom + 20}"
              text-anchor="middle"
              font-size="12"
              transform="rotate(-45, ${x}, ${height - padding.bottom + 20})"
            >R${value.toLocaleString()}</text>`;
        }).join('')}
      </svg>`;
  };

  const generateVerticalBarChart = (data, maxVal, stepValue) => {
    const width = 800;
    const height = 400;
    const padding = 40;
    const barWidth = 15;
    const spacing = 10;

    const chartWidth = width - 2 * padding;
    const chartHeight = height - 2 * padding;
    const yScale = chartHeight / maxVal;

    let bars = '';
    let xLabels = '';
    let yLabels = '';
    let valueLabels = '';

    data.forEach((item, index) => {
      const x = padding + (barWidth + spacing) * index;
      const barHeight = item.value * yScale;
      const y = height - padding - barHeight;

      // Add bar
      bars += `
        <rect 
          x="${x}" 
          y="${y}" 
          width="${barWidth}" 
          height="${barHeight}" 
          fill="${item.frontColor || '#7999CC'}"
        />`;

      // Add value label above bar
      valueLabels += `
        <text 
          x="${x + barWidth/2}" 
          y="${y - 5}" 
          text-anchor="middle" 
          font-size="10"
          transform="rotate(-45, ${x + barWidth/2}, ${y - 5})"
        >R${Math.round(item.value).toLocaleString()}</text>`;

      if (item.label) {
        xLabels += `
          <text 
            x="${x + barWidth/2}" 
            y="${height - padding + 20}" 
            text-anchor="middle" 
            font-size="12"
          >${item.label}</text>`;
      }
    });

    for (let i = 0; i <= maxVal; i += stepValue) {
      yLabels += `
        <text 
          x="${padding - 10}" 
          y="${height - padding - (i * yScale)}" 
          text-anchor="end" 
          font-size="12"
        >R${i.toLocaleString()}</text>`;
    }

    // Add legend
    const legend = `
      <g transform="translate(${padding}, ${height - 10})">
        <rect x="0" y="0" width="12" height="12" fill="#7999CC"/>
        <text x="20" y="10" font-size="12">Turnover</text>
        <rect x="100" y="0" width="12" height="12" fill="#7660A9"/>
        <text x="120" y="10" font-size="12">Receipting</text>
      </g>
    `;

    return `
      <svg width="${width}" height="${height}" style="max-width: 100%;">
        ${bars}
        ${valueLabels}
        ${xLabels}
        ${yLabels}
        ${legend}
        <line 
          x1="${padding}" 
          y1="${height - padding}" 
          x2="${width - padding}" 
          y2="${height - padding}" 
          stroke="black" 
          stroke-width="1"
        />
        <line 
          x1="${padding}" 
          y1="${padding}" 
          x2="${padding}" 
          y2="${height - padding}" 
          stroke="black" 
          stroke-width="1"
        />
      </svg>`;
  };

  return (
    <div className="w-full space-y-8">
        <h4 className="text-xl font-bold mb-4">Performance</h4>
        <div className="table-responsive">
          <table className="table table-bordered table-striped">
            <thead>
              <tr>
                <th>Type</th>
                {months.map((month) => (
                  <th key={month}>{month}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {reports.map((report, index) => (
                <tr key={index}>
                  <td>{report.breakdown_type}</td>
                  {months.map((month) => (
                    <td key={month}>{
                      typeof report.month_list[month] === 'number' 
                        ? report.month_list[month].toLocaleString('en-ZA', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })
                        : report.month_list[month]
                    }</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      <div>
        <div className="w-full" dangerouslySetInnerHTML={{ 
          __html: generateVerticalBarChart(verticalChartData, maxVerticalValue, stepValue) 
        }} />
      </div>

      <div>
        <div className="w-full" dangerouslySetInnerHTML={{ 
          __html: generateHorizontalBarChart(totalTurnover, totalReceipting, maxHorizontalValue) 
        }} />
      </div>
      
      {/* Age Analysis Table */}
      <div className="d-flex align-items-center mt-4">
  <h4 className="bold-color">Age Analysis</h4>
</div>
<div className='table-responsive'>
  <table className="table table-striped">
    <thead>
      <tr>
        <th>Age Range</th>
        <th>Total</th>
        <th>Current</th>
        <th>30 Days</th>
        <th>60 Days</th>
        <th>90 Days</th>
        <th>120 Days</th>
        <th>150 Days</th>
        <th>180+ Days</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Total</td>
        <td>{formatCurrency(ageAnalysis.total)}</td>
        <td>{formatCurrency(ageAnalysis.current)}</td>
        <td>{formatCurrency(ageAnalysis.days_30)}</td>
        <td>{formatCurrency(ageAnalysis.days_60)}</td>
        <td>{formatCurrency(ageAnalysis.days_90)}</td>
        <td>{formatCurrency(ageAnalysis.days_120)}</td>
        <td>{formatCurrency(ageAnalysis.days_150)}</td>
        <td>{formatCurrency(ageAnalysis.days_180_plus)}</td>
      </tr>
    </tbody>
  </table>


      {/* Age Analysis by Status Table */}
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Age Analysis by Status</h4>
       </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Description</th>
            <th>Total</th>
            <th>Current</th>
            <th>30 Days</th>
            <th>60 Days</th>
            <th>90 Days</th>
            <th>120 Days</th>
            <th>150 Days</th>
            <th>180+ Days</th>
          </tr>
        </thead>
        <tbody>
          {ageAnalysisStatus.map((status, index) => (
            <tr key={index}>
              <td>{status.account_status}</td>
              <td>{status.age_analysis.total}</td>
              <td>{status.age_analysis.current}</td>
              <td>{status.age_analysis.days_30}</td>
              <td>{status.age_analysis.days_60}</td>
              <td>{status.age_analysis.days_90}</td>
              <td>{status.age_analysis.days_120}</td>
              <td>{status.age_analysis.days_150}</td>
              <td>{status.age_analysis.days_180_plus}</td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
     

      {/* Ledger Table */}
      <div className="d-flex align-items-center mt-4">
          <h4 className="bold-color">Ledger for the Month</h4>
       </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Date</th>
            <th>Description</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Total</th>
            <th>Running Total</th>
          </tr>
        </thead>
        <tbody>
          {ledger.map((entry, index) => (
            <tr key={index}>
              <td>{entry.date}</td>
              <td>{entry.description}</td>
              <td>{entry.debit}</td>
              <td>{entry.credit}</td>
              <td>{entry.total}</td>
              <td>{entry.running_total}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

MonthlyReports.propTypes = {
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      breakdown_type: PropTypes.string.isRequired,
      month_list: PropTypes.objectOf(PropTypes.number).isRequired, 
      turnover: PropTypes.number.isRequired, 
      receipting: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default MonthlyReports;
