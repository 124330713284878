import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import PatientSearch from "./patientSearch";
import "../../styles/components/BillingHistory.scss";
import NavigationButton from "../NavigationButton";
import useClinicalNotePatient from "../state/ClinicalNotePatient";
function BillingHistory() {
  const [selectedTab, setSelectedTab] = useState("Invoice History");
  const {
    selectedPatient,
    setSelectedPatient,
    isPatientSelected,
    setIsPatientSelected,
    hideSearchBar,
    setHideSearchBar,
    isFound,
    setIsFound,
  } = useClinicalNotePatient();

  const handlePatientSelect = (patientData) => {
    setSelectedPatient(patientData);
    setIsPatientSelected(true);
    setIsFound(false);
    setHideSearchBar(true);
  };
  const navigate = useNavigate();
  return (
    <div>
      <Sidebar heading={"CLINICAL NOTES"}>
        <div className="container mt-4">
          {isPatientSelected && (
            <div className="d-flex justify-content-start align-items-start backbtnContainer bold-txt my-2 flex-column">
              <button
                type="button"
                className="btn "
                onClick={() => {
                  setIsPatientSelected("");
                  setIsFound(true);
                  setHideSearchBar(false);
                }}
              >
                <i
                  style={{ color: "black" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>{" "}
                Clinical Notes
              </button>
            </div>
          )}
          <PatientSearch
            onPatientSelect={handlePatientSelect}
            retrieveMultiple={true}
            isBillingHistory={true}
            showListClinicalNotes={isFound}
            hideSearchBar={hideSearchBar}
          >
            {isPatientSelected && (
              <>
                <div className="d-flex  flex-column align-items-start p-3 bg-light-cn mt-2">
                  <div>
                    <div className="d-flex flex-column text-start">
                      <span className="billinghistoryHeader">
                        Patient Name & Surname:
                      </span>
                      <span className="billinghistoryText">
                        {selectedPatient.name} {selectedPatient.surname}
                      </span>
                    </div>
                    <div className="d-flex flex-column text-start">
                      <span className="billinghistoryHeader">
                        Patient ID Number:
                      </span>
                      <span className="billinghistoryText">
                        {selectedPatient.id}
                      </span>
                    </div>
                    <div className="d-flex flex-column text-start">
                      <span className="billinghistoryHeader">
                        Account Number:
                      </span>
                      <span className="billinghistoryText">
                        {selectedPatient.debtor_number}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="responsive-flex mt-4">
                  <NavigationButton
                    header={"Submit CLOM"}
                    color={"gray"}
                    img={require("../../assets/home3.png")} //questionmarkwithcricle
                    // route="queryhistory"
                  />
                  <NavigationButton
                    header={"Clinical Notes History"}
                    color={"white"}
                    img={require("../../assets/write.png")} //note with pen
                    handleClick={() =>
                      navigate("/clinicalnoteshistory", {
                        state: {
                          patientID: selectedPatient.id,
                          name: selectedPatient.name,
                          surname: selectedPatient.surname,
                          accNum: selectedPatient.debtor_number,
                          debtor_dbid: selectedPatient.debtor_dbid,
                          patient_dbid:selectedPatient.patient_dbid,

                        },
                      })
                    }
                  />
                  <NavigationButton
                    header={"Templates"}
                    color={"gray"}
                    img={require("../../assets/billing2.png")} // clock ticking
                    // route="invoicehistory"
                  />
                </div>
              </>
            )}
          </PatientSearch>
        </div>
      </Sidebar>
    </div>
  );
}

export default BillingHistory;
