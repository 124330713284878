import React, { useState, useEffect, useRef } from "react";
import "../../styles/components/ManualEntry.scss";
import "../../styles/components/ClinicalNotesHistory.scss";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../services/authentication/LoginService";
import ClinicalNoteHistoryService from "../../services/ClinicalNotes/ClinicalNoteHistoryService";
import NavigationButton from "../NavigationButton";
import generatePDF from "react-to-pdf";
import CustomScroll from "../common/CustomScroll";
import FromToDate from "../FromToDate";
import { Formik } from "formik";
import NotesModal from "./ClinicalNoteModal";
import AddClinicalNote from "../AddClinicalNote";
import EnlargeItem from "../EnlargeItem";
import Sidebar from "../Sidebar/Sidebar";
import NavigateBack from "../NavigateBack";
function ClinicalNoteHistory() {
  const { token } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [isAddClinicalNoteOpen, setIsAddClinicalNoteOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState("");
  const clinicalNoteRef = useRef();
  const openModal = (note, date) => {
    setSelectedNote(note);
    setSelectedDate(date);
    setIsModalOpen(true);
  };
  const location = useLocation();
  const patientID = location.state?.patientID;
  const debtor_dbid = location.state?.debtor_dbid
  const patient_dbid = location.state?.patient_dbid
  const fullname = `${location.state?.name}  ${location.state?.surname}`
  const accNum =  location.state?.accNum;
  const closeModal = () => setIsModalOpen(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [patientData, setPatientData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [patientFound, setPatientFound] = useState(false);
  const toggleClinicalNoteSidebar = () => {
    setIsAddClinicalNoteOpen(false);
  };
  const searchClinicalInvoiceHistory = async () => {
    setLoading(true);
    try {
      const result = await ClinicalNoteHistoryService(
        token
      ).fetchClinicalHistory(patientID);
      const sortedData = sortQueryDataByDate(result.patient_notes);
      setPatientData(sortedData);
      setFilteredData(sortedData);
      setPatientFound(sortedData.length > 0);
      setSearchPerformed(true);
    } catch (error) {
      console.error("Error searching patient invoice history:", error);
      setPatientFound(false);
      setSearchPerformed(true);
    } finally {
      setLoading(false);
    }
  };
  const onChangeFromDate = (date, setFieldValue) => {
    setFromDate(date);
    setFieldValue("fromDate", date);
  };

  const onChangeToDate = (date, setFieldValue) => {
    setToDate(date);
    setFieldValue("toDate", date);
  };
  const downloadNotes = () => {
    generatePDF(clinicalNoteRef, { filename: `${"ClinicalNotes"}.pdf` });
  };

  useEffect(() => {
    searchClinicalInvoiceHistory();
  }, [patientID]);

  useEffect(() => {
    filterData(fromDate, toDate);
  }, [fromDate, toDate]);

  const filterData = (fromDate, toDate) => {
    if (!fromDate && !toDate) {
      setFilteredData(patientData);
      return;
    }
    const fromDateObj = new Date(fromDate);
    const toDateObj = new Date(toDate);
    let data;
    if (fromDate && !toDate) {
      data = patientData.filter((item) => {
        const caseDate = new Date(item.CreatedDate);
        return caseDate >= fromDateObj;
      });
    } else if (!fromDate && toDate) {
      data = patientData.filter((item) => {
        const caseDate = new Date(item.CreatedDate);
        return caseDate <= toDateObj;
      });
    } else if (fromDate && toDate) {
      data = patientData.filter((item) => {
        const caseDate = new Date(item.CreatedDate);
        return caseDate >= fromDateObj && caseDate <= toDateObj;
      });
    }
    setFilteredData(data);
  };

  const sortQueryDataByDate = (data) => {
    return [...data].sort(
      (a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate)
    );
  };

  return (
    <Sidebar heading="Clinical Notes History">
      
      <Formik
        initialValues={{
          toDate: "",
          fromDate: "",
        }}
      >
        {({ values, setFieldValue }) => (
          <div className="container">
      <NavigateBack
      text={"CLINICAL NOTES HISTORY"}
      
      />
            <div className="mt-4">
           
           
              <FromToDate
                changeDateFrom={(e) =>
                  onChangeFromDate(e.target.value, setFieldValue)
                }
                changeDateTo={(e) =>
                  onChangeToDate(e.target.value, setFieldValue)
                }
                values={values}
              />
            </div>
            {isAddClinicalNoteOpen && (
              <>
                <AddClinicalNote
                  clinicalNoteClicked={isAddClinicalNoteOpen}
                  patientID={patientID}
                  closeClinicalNote={toggleClinicalNoteSidebar}
                  patient_dbid={patient_dbid}
                  debtor_dbid={debtor_dbid}
                />
              </>
            )}

            {isModalOpen && (
              <NotesModal
                selectedNote={selectedNote}
                selectedDate={selectedDate}
                closeModal={() => closeModal()}
              />
            )}
            <div ref={clinicalNoteRef} className="row mb-4">
            <div className="mt-3 mb-2 d-flex flex-column justify-content-start ml-3">
              <span className="text-start">Patient ID Number: <span className="boldColor">{patientID}</span></span>
              <span className="text-start">Patient Name & Surname: <span className="boldColor">{fullname}</span></span>
              <span className="text-start">Account Number: <span className="boldColor">{accNum}</span></span>
              </div>
              <CustomScroll smallerScroll={true} maxHeight="60vh">
                {searchPerformed && patientFound ? (
                  filteredData.map((item, idx) => (
                    <div
                      key={`${item.ClinicalNotesID}_${idx}`}
                      className={`${
                        idx % 2 > 0 ? "background" : "bg-light"
                      } col-12 d-flex p-3 ml-lg-3`}
                    >
                      <div className="col-4">
                        {idx === 0 && (
                          <div className="w-100 d-flex justify-content-start">
                            <span className="bold-font">Date Logged</span>
                          </div>
                        )}
                        <div className="d-flex justify-content-start py-2">
                          <span>
                            {new Date(item.CreatedDate).toLocaleDateString(
                              "en-GB",
                              {
                                day: "numeric",
                                month: "short",
                                year: "numeric",
                              }
                            )}
                          </span>
                        </div>
                      </div>

                      <div className="col-6">
                        {idx === 0 && (
                          <div className="w-100 d-flex justify-content-start">
                            <span className="bold-font">Clinical Notes</span>
                          </div>
                        )}
                        <div className="d-flex justify-content-start py-2">
                          <span className="text-truncate">
                            {item.Note.length > 50
                              ? `${item.Note.slice(0, 50)}...`
                              : item.Note}
                          </span>
                        </div>
                      </div>
                      
                      <EnlargeItem
                        width={40}
                        openFunction={() =>
                          openModal(item.Note, item.CreatedDate)
                        }
                      />
                    </div>
                  ))
                ) : (
                  <div className="col-12">
                    <p>
                      {searchPerformed
                        ? "No Clinical Notes"
                        : "Search not performed"}
                    </p>
                  </div>
                )}
              </CustomScroll>
            </div>
            <div className="row">
              <div className="mb-3 col-md-6 col-12">
                <NavigationButton
                  header={"Add Clinical Note"}
                  color="clinicalNotesBtn"
                  handleClick={() => setIsAddClinicalNoteOpen(true)}
                />
              </div>
              <div className="mb-3 col-md-6 col-12 ">
                <NavigationButton
                  header={"Download History"}
                  color="clinicalNotesBtn"
                  handleClick={downloadNotes}
                />
              </div>
            </div>
          </div>
        )}
      </Formik>
    </Sidebar>
  );
}

export default ClinicalNoteHistory;
