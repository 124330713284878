import React from 'react'
import MainFooter from './common/MainFooter'

const TermsAndConditions = () => {
  return (
      <div className='container'>
        <div >
          <div >
            <h3 className='text-danger mt-4' >TERMS AND CONDITIONS</h3>
            <p className='text-start'>
              PLEASE READ CAREFULLY BEFORE DOWNLOADING OR INSTALLING THE APPLICATION PART 1: GENERAL TERMS AND CONDITIONS OF USAGE ("TERMS”) APPLICABLE TO ALL APPLETS<br />
            </p>

            <h3 className='text-danger text-start' >1. INTRODUCTION</h3>
            <p  className='text-start'>
              1.1 These Terms are a legally binding agreement between you and Xpedient Medical. This Agreement is for your use of the Application, and the Software managed by Xpedient Medical.<br />
            </p>
            <p className='text-start' >
              1.2 Xpedient Medical licences the use of the Application to you on the basis of these Terms and your use of this Application is also subject to any rules or policies applied by, if applicable, any application store provider or operator ("Appstore Rules") from whose website you downloaded the Application ("Appstore").<br />
            </p>
            <p className='text-start' >
              1.3 We do not sell the Application to you under these Terms and we remain the owner of the Application at all times.<br />
            </p>
            <p  className='text-start'>
              1.4 This Application requires a compatible smartphone or tablet computer ("Device") that enables Internet access and compatible operating systems, such as iOS, Android.<br />
            </p>
            <p className='text-start' >
              1.5 If the Device does not belong to you, by accepting these terms, you confirm that you have obtained permission from the owner of the Device to download or install a copy of the Application onto the Device.<br />
            </p>

            <h3 className='text-danger text-start'>2. DEFINITIONS AND INTERPRETATION</h3>
            <p  className='text-start'>
              In these Terms: The following words or expressions have the following meanings:<br /><br />
              2.1 "Application" means the Xpedient Medical Mobile App mobile application software and associated media, which includes the Applets, made available by Xpedient Medical to users for communicating data, text, audio and video, with compatible mobile devices and for receiving advertisements, incentives and other material using the Software developed and managed by Xpedient Medical;<br /><br />
              2.2 "Applet/s" means unique services that are made available within the Xpedient Medical Mobile App.<br /><br />
              2.4 "Xpedient Medical" or "we" or "us" means Xpedient Medical Limited, Registration Number 2017/174510/07 having its registered address at Corporate Place, 13 Mispel Avenue, Tyger Valley, Cape Town, 7530, South Africa;<br /><br />
              2.5 "Software" means the software and other media, enabling the use of the Application;<br /><br />
              2.6 "User" or "you" means any person who accepts installation of the Software or uses the Application and who has concluded a Services Agreement with Xpedient Medical.<br /><br />
              2.7 References to the singular includes the plural and vice versa;<br /><br />
              2.8 Links contained in these Terms to other documents should be deemed part of these Terms in terms of section 11(3) of the Electronic Communications and Transactions Act 25 of 2002 ("ECT Act"). Although links may, from time to time, be non-operational, this shall not affect the validity and interpretation of these Terms.<br /><br />
              2.9 Any words or phrases not defined in these Terms but defined in the ECT Act will bear the same meaning given to them in the ECT Act.<br /><br />
              2.10 A copy of the ECT Act can be viewed and downloaded at http://www.polity.org.za/article/electronic-communications-and-transactions-act-no-25-of-2002-2002-01-01. It is your responsibility to ensure that the copy downloaded is the most recent version of the ECT Act.<br /><br />
              2.11 Clause headings are for convenience and not to be used in interpreting these terms and conditions.
            </p>

            <h3 className='text-danger text-start'>3. ACCEPTANCE OF THE TERMS</h3>
            <p className='text-start' >
              Upon conclusion of our Services Agreement with you. You will then be registered on our Xpedient Medical database as a User of the Application.<br />
            </p>

            <h3 className='text-danger text-start'>4. INFORMATION PROVIDED ON THE APPLICATION</h3>
            <p className='text-start'>
              4.1 Subject to clause 9, the User has free access to this Application.<br /><br />
              4.2 The Application provides information to all Users who are clients of Xpedient Medical.<br /><br />
              4.3 The Application is intended to assist Users with the submission of claims to medical schemes, storage of and access to clinical notes……. <br /><br />
              4.5 While every effort has been made to ensure the accuracy of information made available on this Application, Xpedient Medical, their affiliates and/or partners and directors, officers and employees of Xpedient Medical, their affiliates and/or partners, provide no representation or warranty, express or implied, regarding the accuracy, completeness or correctness of any information made available through the Application.<br /><br />
              4.6 If there is a conflict between the information contained on the Application and the provisions of your policy, contract, the rules, or other relevant document, such, contract, rules or other document will prevail and will be binding on the User and Xpedient Medical.
            </p>

            <h3 className='text-danger text-start'>5. GRANT OF LICENCE AND USE OF THE APPLICATION</h3>
            <p className='text-start' >
              5.1 Xpedient Medical grants users a non-transferable, non-exclusive licence to use the Software and the Application on your Device, subject to these Terms and, where applicable, the Appstore Rules. Xpedient Medical reserves all other rights.<br /><br />
              5.2 The User is permitted to use the Application for lawful purposes only. Except as expressly set out in these Terms or as permitted by law, users agree: (i) not to copy the Application except where such copying is incidental to normal use of the Application, (ii) not to rent, lease, sub-licence, loan, translate, adapt, vary or modify the Application, (iii) not to disassemble, decompile, reverse engineer or create derivative works based on the whole or any part of the Application, (iii) not to use the Application in any unlawful manner, for any unlawful purpose, including posting or transmission of data through the Application which violates or infringes in any way upon Xpedient Medical’s rights or third parties' rights, (iv) not to transmit any material which is unlawful, defamatory, offensive or otherwise objectionable in relation to your use of the Application or any service made available through the Application; (v) not to use the Application or any service in a way that could damage, disable, overburden, impair or compromise Xpedient Medical’s systems or security or interfere with other users; or (vi) not deliver or attempt to deliver, whether intentionally or negligently, any damaging or malicious code or information through the Application.<br /><br />
              5.3 Any unauthorised use of the Application may lead to Xpedient Medical instituting legal proceedings against the User.<br /><br />
              5.4 Although the Application is provided free of charge by Xpedient Medical, the User recognises that there may be a data charge by the User's service provider in respect of the use of the Application. It is the User's responsibility to acquire and maintain at their own cost the necessary accessories and third party services, including but not limited to Internet access to use the Application.
            </p>

            <h3 className='text-danger text-start'>6. INTELLECTUAL PROPERTY RIGHTS</h3>
            <p className='text-start' >
              6.1 Users acknowledge that:<br /><br />
              6.1.1 all intellectual property rights in and to the Application, the associated software, and any trademarks and contents used and/or displayed in connection with the Application, Software and/or the services anywhere in the world belong to Xpedient Medical or our licensors; and<br /><br />
              6.1.2 all rights in and to the Application are the property of or are licensed to Xpedient Medical and as such, you have no rights in, or to the Application other than the right to use the Application as set out in these Terms.<br /><br />
              6.2 Users have no rights to access the Application in source code form.
            </p>

            <h3 className='text-danger text-start'>7. AMENDMENTS AND CHANGES TO TERMS AND CONDITIONS</h3>
            <p className='text-start' >
              7.1 Xpedient Medical may amend these Terms (including its privacy policy in paragraph 8 below) at any time by communicating same to the device. All amended terms shall automatically become effective immediately after they are notified to the User and any subsequent use of the Application or any other services shall be governed by such amended terms. If the User does not accept the amended Terms, the User must uninstall and cease using and/or accessing the Application at any time.<br /><br />
              7.2 Xpedient Medical reserves the right at any time to change or discontinue any aspect or feature of the Application, including but not limited to, content, hours of availability and equipment needed to use the Application.
            </p>

            <h3 className='text-danger text-start'>8. PRIVACY POLICY AND DATA PROTECTION</h3>
            <p className='text-start' >
              8.1 Xpedient Medical undertakes to take all reasonable steps to protect the personal information of Users and is committed to protecting and respecting your privacy in accordance with applicable data protection legislation, including the Protection of Personal Information Act 4 of 2013.<br /><br />
              8.2 Users agree that Xpedient Medical may collect, collate, process, and/or store User's personal information for, amongst other things, providing Users access to the Application and to the extent applicable and subject to, where applicable, any Appstore Rules, the website User's downloaded the Application from and for any purposes set out in paragraph 8.3 below.<br /><br />
              8.3 The types of personal information that Xpedient Medical may collect includes information necessary for our legitimate business interest and the categories of personal information identified in the relevant data protection laws in the Republic of South Africa. These may include:<br /><br />
              8.3.1 the User's full name;<br /><br />
              8.3.2 the User's area code and physical and postal addresses;<br /><br />
              8.3.3 the User's contact number(s);<br /><br />
              8.3.4 non-personal browsing habits and click patterns;<br /><br />
              8.3.5 User's e-mail address and/or IP address;<br /><br />
              8.3.6 IP address (Xpedient Medical does not ordinarily link IP addresses to personal information, which means that your session may remain anonymous. However, we cannot guarantee that this will always be the case, as it may be necessary to identify a particular User when it is necessary to enforce compliance with these Terms and/or the law;<br /><br />
              8.3.7 the brand of the User's Device and model number;<br /><br />
              8.3.8 Demographic information and Geographic Region;<br /><br />
              8.3.9 Income Bracket;<br /><br />
              8.3.10 Country;<br /><br />
              8.3.11 Home Language;<br /><br />
              8.3.12 Gender;<br /><br />
              8.3.13 Date of Birth/Age.<br /><br />
              8.4 Xpedient Medical collects, stores and uses the information listed in paragraph 8.3 for the following purposes:<br /><br />
              8.4.1 to communicate requested information to the User;<br /><br />
              8.4.2 to enable the Application and related services;<br /><br />
              8.4.3 to activate the Software;<br /><br />
              8.4.4 to provide profiled services to the User as requested by the User;<br /><br />
              8.4.5 to notify and authenticate the identity of the User;<br /><br />
              8.4.6 to compile non-personal statistical information; and<br /><br />
              8.4.7 for security, administrative and legal purposes.<br /><br />
              8.5 The information is provided voluntarily by the User or is collected either electronically by using cookies. Cookies are pieces of information a website transfers to a User’s hard drive for record-keeping purposes. Cookies make surfing the web easier by saving User's preferences and tracking User's online habits, traffic patterns and making sure Users do not see the same advertisement too often. The use of cookies is an industry standard. Xpedient Medical may place a "cookie" on your browser to store and sometimes track information about you. Importantly, while most browsers are initially set up to accept cookies you can reset your browser to refuse all cookies or indicate when a cookie is being sent. Please note that some aspects of the Website will not function properly if you refuse cookies.<br /><br />
              8.6 Xpedient Medical may collect, maintain, save, compile, share, disclose and sell any information collected from Users, subject to the following provisions:<br /><br />
              8.6.1 Xpedient Medical shall not disclose personal information from Users unless the User provides his or her prior consent;<br /><br />
              8.6.2 Xpedient Medical shall disclose personal information without the User's consent if required to do so by law; and<br /><br />
              8.6.3 Xpedient Medical may compile, use and share any information that does not relate to any specific individual. In other words, a User's information will be de-identified for such purposes.<br /><br />
              8.7 Xpedient Medical owns and retains all rights to non-personal statistical information collected and compiled by Xpedient Medical.<br /><br />
              8.8 The User agrees that Xpedient Medical may freely disclose statistics based on the Demographic information of its Users, provided that any statistics shall not contain any personal information of the User and the User's personal information (if any) shall be de-identified by Xpedient Medical.<br /><br />
              8.9 Xpedient Medical will take all reasonable measures to ensure that the use of the Application is protected from unauthorised access and that personal information provided by and collected from Users is not disclosed to any unauthorised third parties. Xpedient Medical cannot, however, guarantee that these measures will ensure that your information will always be 100% secure.<br /><br />
              8.10 Users agree that we may transfer and/or store your personal information on servers in a jurisdiction other than South Africa and such jurisdiction may not have data protection legislation comparable to legislation of South Africa.
            </p>

            <h3 className='text-danger text-start'>9. DATA CONSUMPTION</h3>
            <p className='text-start' >
              9.1 The Application uses the data channel (GPRS) of your Device to communicate. Although usage is low, you must be aware that data will be consumed from your airtime. It is highly recommended that a data bundle is purchased with your Network Mobile Operator in order to save on data costs. Data is consumed by performing the following actions (but not limited to): authentication, application start-up, text messaging, sending/receiving pictures, sending/receiving locations, accessing content, group communication (text, picture, location), updating of profile information, viewing profile information, accessing external content via your mobile device's Internet Browser, viewing advertisements, and contact synchronisation.<br /><br />
              9.2 Users should note that when roaming, Users may be subject to higher data pricing. It is a User's responsibility to check with their mobile network carrier for detailed data pricing.
            </p>

            <h3 className='text-danger text-start'>10. DISCLAIMERS AND LIMITATION OF LIABILITY</h3>
            <p className='text-start' >
              10.1 To the extent allowed by law, the information and content of the Application are provided by Xpedient Medical or their affiliates, partners and associates, suppliers or agents without any warranty of any kind, express or implied, (including but not limited to) any implied warranties of reliability, fitness for any particular purpose, timeliness, sequence, completeness, non-infringement of third party rights and or freedom from errors or inaccuracies.<br /><br />
              10.2 You, the User, acknowledge and consent that you use the Application and the information and the Software entirely at your own risk and that Xpedient Medical, and its affiliates, officers, directors, employees, servants, agents or contractors or other persons for whom they in law may be liable to, will not, under any circumstances, be liable to Users for any loss or damages (including, but not limited to, consequential, direct, indirect, special, punitive or incidental damages and damages or losses of any other kind).<br /><br />
              10.3 To the extent allowed by law, and without limiting the generality of the aforesaid, the User acknowledges and consents that Xpedient Medical and their affiliates, officers, directors, employees, servants, agents or contractors or other persons for whom in law they may be liable to, will not, under any circumstances, be liable to Users for any loss or damages (including, but not limited to, consequential, direct, indirect, special, punitive or incidental damages and damages or losses of any other kind) or any other action arising from:<br /><br />
              10.3.1 any defect, fault, malfunction and/or delay in User's Devices and/or software;<br /><br />
              10.3.2 any defect, failure, fault and/or delay in connectivity to the Internet;<br /><br />
              10.3.3 the Application or related services being temporarily unavailable or inaccessible for any reason whatsoever;<br /><br />
              10.3.4 to the extent allowed by law, any defect, failure, fault, delay or unavailability of the Application and/or information for any reason whatsoever, subject to paragraph 10.5 below;<br /><br />
              10.3.5 viruses and/or spam that may infect a User's Device;<br /><br />
              10.3.6 any failure on the part of a merchant to deliver the goods and/or services you purchased using the Application (to the extent applicable);<br /><br />
              10.3.7 the Application, Xpedient Medical’s systems and/or User's information held by us being compromised in any way; or<br /><br />
              10.3.8 a third party gaining access to and using a User's information held by Xpedient Medical.
            </p>

            <h3 className='text-danger text-start'>11. ELIMINATION OF ERRORS</h3>
            <p className='text-start' >
              Users are encouraged to report untrue, inaccurate, defamatory, illegal, infringing, and/or harmful content communicated via the Application to Xpedient Medical. Xpedient Medical undertakes, to the extent possible, to take all reasonable steps to correct and/or remove such content.<br />
            </p>

            <h3 className='text-danger text-start'>12. DISPUTE RESOLUTION</h3>
            <p className='text-start' >
              12.1 The User agrees that in the event of a dispute or alleged breach by Xpedient Medical, the User will, in good faith, work together with a duly authorised representative of Xpedient Medical to attempt to resolve the dispute.<br /><br />
              12.2 If the dispute is not so resolved within 30 (thirty) calendar days, the User or Xpedient Medical may refer the dispute to an arbitrator appointed by the Arbitration Foundation of Southern Africa ("AFSA") for final resolution in accordance with the rules of AFSA, provided that there shall be no right of appeal. Neither the User nor Xpedient Medical shall in any way be precluded from obtaining interim relief on an urgent basis from a South African court of competent jurisdiction pending the decision of the arbitrator.
            </p>

            <h3 className='text-danger text-start'>13. OTHER IMPORTANT TERMS</h3>
            <p className='text-start' >
              13.1 These Terms constitute the entire agreement between Xpedient Medical and the User and shall take precedent over any communications and/or postings received by Xpedient Medical from the User.<br /><br />
              13.2 If Xpedient Medical fails to insist that a User perform any of their obligations under these Terms, or if Xpedient Medical does not enforce its rights against a User, or if Xpedient Medical delays in doing so, that will not mean that Xpedient Medical have waived its rights against such User and will not mean that such User does not have to comply with those obligations. If Xpedient Medical does waive a default by a User, Xpedient Medical will only do so in writing, and that will not mean that Xpedient Medical will automatically waive any later default by such User.<br /><br />
              13.3 In the event that any Terms detailed herein are found unenforceable or invalid for any reason, such term(s) or condition(s) shall be severable from the remaining Terms. The remaining Terms shall remain enforceable and applicable.<br /><br />
              13.4 No provision of these Terms (or any contract governed by these Terms):<br /><br />
              13.4.1 does or purports to limit or exempt us from any liability (including, without limitation, for any loss directly or indirectly attributable to Xpedient Medical’s gross negligence or wilful default or that of any other person acting for or controlled by us) to the extent that the law does not allow such a limitation or exemption;<br /><br />
              13.4.2 requires Users to assume risk or liability for the kind of liability or loss, to the extent that the law does not allow such an assumption of risk or liability; or<br /><br />
              13.4.3 limits or excludes any warranties or obligations which are implied into these Terms (or any contract governed by these Terms) by the Consumer Protection Act, 2008 ("Consumer Protection Act") (to the extent applicable) or which Xpedient Medical give under the Consumer Protection Act (to the extent applicable), to the extent that the law does not allow them to be limited or excluded.<br /><br />
              13.5 If these Terms (or any further contract governed by the terms of these Terms) or the Application or the information made available on the Application is regulated by or subject to the Consumer Protection Act, it is not intended that any provision of the Terms contravenes any provision of the Consumer Protection Act. Therefore all provisions of the Terms must be treated as being qualified, to the extent necessary, to ensure that the provisions of the Consumer Protection Act are complied with.<br /><br />
              13.6 Notwithstanding the User's country of residence and Application use, the Application is subject to South African law enforced by the South African courts and governs the use or inability to use the Application, its content, services, products and these terms and conditions.
            </p>

            <h3 className='text-danger text-start'>14. VARIATION OF CERTAIN DEEMING PROVISIONS IN THE ECT ACT</h3>
            <p className='text-start' >
              By using the Application, Users agree that these Terms create a binding contract between Xpedient Medical and the User, even though the Terms are wholly or partly in the form of a data message. Users agree specifically that:<br /><br />
              14.1 these Terms will be treated as if they were concluded at Xpedient Medical's physical address on the date on which the User first made any access, registration, or use of the Application;<br /><br />
              14.2 an electronic signature is not required by a User or Xpedient Medical for purposes of agreeing to these Terms;<br /><br />
              14.3 a User's use of the Application is sufficient evidence of a User's agreement to these Terms;<br /><br />
              14.4 any data message sent by either a User or Xpedient Medical to the other will be deemed to have been sent from our physical address if neither a User's usual place of business nor residence is located within South Africa;<br /><br />
              14.5 subject to the further provisions of these Terms, any communication sent to Users by an information system programmed to operate automatically on Xpedient Medical's behalf will be a data message attributable to, or authorised by, Xpedient Medical; and<br /><br />
              14.6 subject to the further provisions of these Terms, a data message sent by a User to Xpedient Medical will only be treated as having been received by Xpedient Medical when an acknowledgement of receipt is sent by Xpedient Medical personally or by a person who had authority to act on our behalf in respect of that data message.<br /><br /><br /><br />
            </p>
          </div>
        </div>
        <MainFooter/>
      </div>
  )
}

export default TermsAndConditions