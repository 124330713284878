import { useCallback } from "react";
import useProcedureCodesStore from "../screens/state/ProcedureCodeStore.js";
import useServiceDateStore from "../screens/state/ServiceDateStore.js";
import useServiceCenterCodesStore from "../screens/state/ServiceCenterCodeStore.js";
import useReferringDoctorStore from "../screens/state/ReferringDoctorStore.js";
import useModifiersStore from "../screens/state/ModifiersStore.js";
import useTreatingDoctorStore from "../screens/state/TreatingDoctorStore.js";
import useActiveTab from "../screens/state/ActiveTab.js";
import usePatientDetailsStore from "../screens/state/PatientDetails.js";

const useClearStores = () => {
  const { clearAllData } = useProcedureCodesStore();
  const { clearServiceDate } = useServiceDateStore();
  const { clearServiceCenter } = useServiceCenterCodesStore();
  const { clearReferringDoc } = useReferringDoctorStore();
  const { clearModifiersStore } = useModifiersStore();
  const { clearTreatingDoctorStore } = useTreatingDoctorStore();
  const {resetPatientDetails} = usePatientDetailsStore()
  // Use `useCallback` to memoize the function, which ensures referential stability
  return useCallback(() => {

        clearAllData();
        clearServiceDate();
        clearServiceCenter();
        clearReferringDoc();
        clearModifiersStore();
        clearTreatingDoctorStore();
        resetPatientDetails();
   

  }, [
    clearAllData,
    clearServiceDate,
    clearServiceCenter,
    clearReferringDoc,
    clearModifiersStore,
    clearTreatingDoctorStore,
    resetPatientDetails
    
  ]);
};

export default useClearStores;
