import React from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import "../../styles/components/CodeSearch.scss";
import NavigateBack from "../NavigateBack";
import greenTick from "../../assets/icons/Green Tick Icon.png";
import noTick from "../../assets/XP0299 - Xpedient - Xpedient App - Icons-33.png";
function MoreCategories() {
  const location = useLocation();
  const icd10Data = location?.state.item;
  return (
    <Sidebar heading={"ICD10 & PROCEDURE CODE SEARCH"}>
      <div className="container mt-4">
        <NavigateBack text={"CODES"} />
        <div className="d-flex flex-column text-start">
          <div className="headingContainer">
            <span className="error-text py-1 px-2">
              Code: {icd10Data.CodeID}
            </span>
            <div className="py-1 px-2">
              <span>{icd10Data.CodeDesc}</span>
            </div>
          </div>
          <div className="mt-3">
            <span className="text-secondary">Chapter</span>
            <div>
              <span>{icd10Data.CodeChapter}</span>
            </div>
          </div>
          <div className="mt-3">
            <span className="text-secondary">Group</span>
            <div>
              <span>{icd10Data.CodeGroup}</span>
            </div>
          </div>
          <div className="mt-3">
            <span className="text-secondary">Category</span>
            <div>
              <span>{icd10Data.CodeCategory}</span>
            </div>
          </div>

          <div className="row col-12 p-0 mt-3">
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodeClinicalUse ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">ClinicalUse</span>
            </div>
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodePrimary ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">Primary</span>
            </div>
          </div>
          <div className="row col-12  p-0 mt-3">
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodeAsteris ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">Asterisk</span>
            </div>
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodeDagger ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">Dagger</span>
            </div>
          </div>
          <div className="row col-12  p-0 mt-3 mb-5">
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodeSequelae ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">Sequelea</span>
            </div>
            <div className="col-6">
              <img
                width={20}
                height={20}
                src={icd10Data.CodePMB ? greenTick : noTick}
                alt=""
              />
              <span className="ml-1">PMB</span>
            </div>
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default MoreCategories;
