import React, { useState } from "react";
import { ErrorMessage } from "formik";
import "../../styles/components/invoice.scss";
import SideNavbar from "../common/SideNavbar";
import useServiceDateStore from "../state/ServiceDateStore";

const ServiceDateField = ({
  values,
  setFieldValue,
}) => {
  const { setDateToDisplay,multiDates, setMultiDates } = useServiceDateStore();
  const [selectedDate, setSelectedDate] = useState(values.serviceDate || "");

  const handleDateChange = (e) => {
    const date = e.target.value;

    if (!date){
      return
    }
    if (multiDates.length > 0) {
      setMultiDates(multiDates.pop())
    }
    setSelectedDate(date);
    setDateToDisplay(date);
    setFieldValue("serviceDate", date);
  };


  return (
    <div className="col-12 col-lg text-font">
      <label htmlFor="serviceDate">
        Service Date<span className="error-text">*</span>
      </label>

      <div className="position-relative">
      <input
              type="date"
              onClick={(e) => e.currentTarget.showPicker()}
              id="sidebarServiceDate"
              className="form-control pointer"
              value={selectedDate}
              
              onChange={handleDateChange}
              max={new Date().toISOString().split("T")[0]} // Restrict to past and current dates
            />

        <img
          src={require("../../assets/icons/Calendar Icon.png")}
          alt="Calendar Icon"
          className="calendar-icon"
        />
      </div>

      <ErrorMessage
        name="serviceDate"
        component="div"
        className="error-text d-flex"
      />
    </div>
  );
};

export default ServiceDateField;