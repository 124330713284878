// useProcedureCodesStore.js
import {create} from 'zustand';

const useServiceDateStore = create((set) => ({
    dateToDisplay:"",
    multiDates:[],
    setMultiDates:(multiDates)=>set({multiDates}),
    setDateToDisplay:(dateToDisplay)=>set({dateToDisplay}),
    clearServiceDate: () => set({
        dateToDisplay:""
    })
}));

export default useServiceDateStore;
