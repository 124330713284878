import React, { useState, useEffect } from 'react';
import '../../../styles/components/WeightHeightPickerModal.scss';
import { ErrorMessage, useFormikContext } from 'formik';

const WeightPickerModal = ({ isVisible, onSelect, onCancel }) => {
  const [selectedKg, setSelectedKg] = useState(0);
  const [selectedGrams, setSelectedGrams] = useState(0);
  const {values } = useFormikContext()
  const calculateWeight = (kgValue, gramsValue) => {
    const totalWeightInKg = kgValue + (gramsValue / 100);
    onSelect(totalWeightInKg.toFixed(2)); 
  };

  const handleKgChange = (e) => {
    const kgValue = Number(e.target.value);
    setSelectedKg(kgValue);
    calculateWeight(kgValue, selectedGrams); 
  };

  const handleGramsChange = (e) => {
    const gramsValue = Number(e.target.value);
    setSelectedGrams(gramsValue);
    calculateWeight(selectedKg, gramsValue); 
  };

  const { touched, errors } = useFormikContext();
  const calcGrams = () => {
    if (!values?.weight) return "0"; // Ensure weight exists
    const grams = values.weight.split(".")[1]; // Get decimal part
    return grams ? String(Number(grams)) : "0"; // Ensure string format
  };
  return (
    <div className="modal-container" onClick={(e) => e.stopPropagation()}>
      <h2 className="modal-title height">Select Weight<span className="error-text">*</span></h2>
      <div className="picker-container">
        <select
          className="picker"
          value={Math.floor(values.weight) || 0}
          onChange={handleKgChange}
        >
          {[...Array(301).keys()].map((kg) => (
            <option key={kg} value={kg}>{kg} kg</option>
          ))}
        </select>
        <select
          className="picker"
          value={calcGrams()}
          onChange={handleGramsChange}
        >
          {[...Array(100).keys()].map((grams) => (
            <option key={grams} value={grams}>{grams} g</option>
          ))}
        </select>
      </div>
      <ErrorMessage
          name="weight"
          component="div"
          className="error-text d-flex"
        />
    </div>
  );
};

export default WeightPickerModal;
