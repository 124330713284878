// NewsItem.js
import { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ExampleCarouselImage from './NewsCarouselItem';
import MedicalAidHighlights from './MedicalAidHighlights';
function MonthlyHighlights() {

  return (//bg-blue
    <div className='gray-color rounded mt-3'> 
    <Carousel
    indicators={false}
    controls={false}
    interval={null} 
    >
      <Carousel.Item>
    <MedicalAidHighlights/>
      </Carousel.Item>
      <Carousel.Item>
      <MedicalAidHighlights/>
      </Carousel.Item>
      <Carousel.Item>
      <MedicalAidHighlights/>
      </Carousel.Item>
    </Carousel>
    </div>

  );
}

export default MonthlyHighlights;
