import React, { useState, useEffect } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import specialties from "../../assets/Codes/specialties.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import CustomScroll from "../common/CustomScroll";
import useModifiersStore from "../state/ModifiersStore";
import ManualAssistant from "./ManualAssistant";

const Assistants = ({ item, assistantVals, onAssistantSelect }) => {
  const { setFieldValue, errors, touched, values, setFieldTouched } =
    useFormikContext();
  const [assistantFlatlist, setIsAssistantFlatlist] = useState(false);
  const [showPlusIcon, setshowPlusIcon] = useState(false);
  const {setShowAddAssistant} = useModifiersStore()
  const [localAssistant, setLocalAssistant] = useState({
    name: "",
    nameSurname: "",
    speciality: "",
    pracNumber: "",
    showAddAssistant: false,
  });
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const { setFilteredAssistant, modifier } = useModifiersStore();
  const hasModifier = modifier && modifier[item.code];
  const isAssistantRequired = assistantCodes.includes(item.code) || hasModifier;
  const currentAssistant = values?.assistants?.[item.code] || {};

  useEffect(() => {
    if (currentAssistant?.name) {
      setLocalAssistant((prev) => ({
        ...prev,
        name: currentAssistant.name,
        speciality: currentAssistant.speciality || "",
        pracNumber: currentAssistant.pracNumber || "",
        showAddAssistant: false,
      }));

      
    }
    handleAssistantListText("")
  }, []);

  const handleAssistantListText = (text) => {
    setLocalAssistant((prev) => ({ ...prev, name: text }));
    setFieldValue(`assistants.${item.code}.name`, text);
    setFieldTouched(`assistants.${item.code}`, true, false);

    const matchedAssistant = assistantVals.find(
      (value) => value.name.toUpperCase() === text.toUpperCase()
    );

    if (matchedAssistant) {
      setIsAssistantFlatlist(false);
      setshowPlusIcon(false);
      setLocalAssistant((prev) => ({
        ...prev,
        showAddAssistant: false,
        speciality: matchedAssistant.speciality_code || "",
        pracNumber: matchedAssistant.practice_nr || "",
      }));

      setFieldValue(`assistants.${item.code}`, {
        name: matchedAssistant.name,
        speciality: matchedAssistant.speciality_code || "",
        pracNumber: matchedAssistant.practice_nr || "",
        council_nr: matchedAssistant.council_nr || "",
        isManual:false
      });

      if (typeof onAssistantSelect === "function") {
        onAssistantSelect(item.code, matchedAssistant);
      }
      return;
    }

    setFieldValue(`assistants.${item.code}`, {
      name: "",
      speciality: "",
      pracNumber: "",
      council_nr: "",
    });
    let filteredData = assistantVals.filter((item) =>
      item.name.toLowerCase().includes(text.toLowerCase())
    );

    setFilteredAssistant(filteredData.length > 0 ? filteredData : []);
    setshowPlusIcon(filteredData.length === 0);
    setIsAssistantFlatlist(true);
  };
  const assistantError = errors?.assistants?.[item.code];
  const isTouched = touched?.assistants?.[item.code];

  if (!assistantCodes.includes(item.code) && !hasModifier) {
    return null;
  }

  return (
    <div className="mb-4">
      <label className="flex items-center">
        Assistant{" "}
        {isAssistantRequired && <span className="text-red-500 ml-1">*</span>}
        {hasModifier && (
          <span className="text-sm text-gray-500 ml-2">
            (Required due to modifier)
          </span>
        )}
      </label>
      <div className="position-relative">
        <input
          className={`form-control  ${
            isTouched && assistantError ? "border-red-500" : ""
          }`}
          type="text"
          onChange={(e) => handleAssistantListText(e.target.value)}
          onBlur={() => setFieldTouched(`assistants.${item.code}`, true)}
          value={localAssistant.name}
          placeholder="Search Assistant"
          required={isAssistantRequired}
        />
        {showPlusIcon && (
          <FontAwesomeIcon
            icon={faPlus}
            className="plus-icon cursor-pointer"
            onClick={() => {
              setShowAddAssistant(true)
              setIsAssistantFlatlist(false)
              setLocalAssistant((prev) => ({
                ...prev,
                showAddAssistant: true,
                name: "",
              }));
              setFieldValue(`assistants.${item.code}`, {});
            }}
          />
        )}
      </div>
      {!assistantVals.find(
        (value) =>
          value.name.toUpperCase() === localAssistant.name.toUpperCase()
      ) && (
        <ErrorMessage
          name="assistants"
          component="div"
          className="error-text d-flex"
        />
      )}

      {assistantFlatlist && (
        <CustomScroll smallerScroll={false} maxHeight="250px">
          <div className="flex flex-col justify-start items-start  pointer">
            {assistantVals.map((assistant,index) => (
              <div
                key={assistant.id}
                className={`${index % 2 === 0 ? 'bg-light' : 'bg-white'} p-2 w-full cursor-pointer hover:bg-gray-100`}
                onClick={() => handleAssistantListText(assistant.name)}
              >
                {assistant.name}
              </div>
            ))}
          </div>
        </CustomScroll>
      )}
      {localAssistant.showAddAssistant && (
      <ManualAssistant showAssistant={localAssistant.showAddAssistant}/>
      )}
    </div>
  );
};

export default Assistants;
