import React, {useEffect, useState} from 'react'
import GenericSearch from '../GenericSearch';

const SearchCodes = ({
   faves,
   filteredFavourites,
   description
}) => {
    const [searchTerm, setSearchTerm] = useState("")
    const searchFavourites = () => {
        // Filter the object based on the search term
        const filteredValues = Object.fromEntries(
          Object.entries(faves)
            .filter(([key, value]) =>
              value.description.toLowerCase().includes(searchTerm.toLowerCase() )
            )
        );        
        // Set the filtered favourites with the correct structure
        filteredFavourites(filteredValues);
      };
      useEffect(()=>{
        setSearchTerm("")
      },[description])
  
      return (
    <div className='mt-5' >
    <span className="bold-txt">Enter Name of Code Bundle</span>
    <GenericSearch
    handleChange={(e)=>setSearchTerm(e.target.value)}
    clickFunc={searchFavourites}
    values={searchTerm}
    />
    {/* <div className='lightColor favourite-margin centered-items mt-2'>
        <div className='position-relative' >
            <input type="text"   onChange={(e) => setSearchTerm(e.target.value)} 
                value={searchTerm} className="border border-light lightColor input-custom" placeholder="Search" aria-label="Username" />
            <i onClick={searchFavourites} className='fa fa-search absolute-pos' />
        </div>
    </div> */}
</div> 
  )
}

export default SearchCodes