import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../services/authentication/LoginService";
import PatientIDService from "../../services/PatientIDService";
import InvoiceHistoryService from "../../services/Invoice/InvoiceHistoryService.js";
import GenericSearch from "../GenericSearch.js";
import NavigationButton from "../NavigationButton.js";
import { useNavigate } from "react-router-dom";
import useClearStores from "../../utils/clearStores.js";
import Loader from "../../utils/Loader.js";

const PatientSearch = ({
  onPatientSelect,
  retrieveMultiple,
  isBillingHistory,
  children,
  showListClinicalNotes = false, //when pressing back on clinical notes the list of patients should still show
  hideSearchBar = false,
}) => {
  const clearStores = useClearStores();
  const [loading, setLoading] = useState(false);
  const [patientFound, setPatientFound] = useState(false);
  const [patientList, setPatientList] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const { token } = useAuth();
  const [allInvoiceHistoryInfo, setAllInvoiceHistoryInfo] = useState([]);
  const patientIDservice = PatientIDService(token);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const navigate = useNavigate();
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const searchPatientInfo = async (searchedPatientID, e) => {
    if (
      e.key === "Enter" ||
      (e.type === "mousedown" && searchedPatientID.length > 0)
    ) {
      setLoading(true);
      setPatientFound(false);
      setErrorMessage(null);

      try {
        const patientData = await patientIDservice.fetchPatientData(
          searchedPatientID
        );

        if (patientData.success && patientData.patients.length > 0) {
          // Process patients with their debtor information
          const processedPatients = patientData.patients.map((patient) => {
            // Check if debtor information exists and extract the debtor_idnumber
            let debtorIdNumber = null;
            if (patient.debtor && patient.debtor.length > 0) {
              debtorIdNumber = patient.debtor[0].debtor_idnumber;
            }

            // Return patient with additional debtor information
            return {
              ...patient,
              debtor_idnumber: debtorIdNumber,
            };
          });

          const uniquePatients = Array.from(
            new Map(processedPatients.map((p) => [p.id, p])).values()
          );

          if (retrieveMultiple) {
            setPatientList(uniquePatients);
            // Automatically set selectedPatientID to the first patient's ID if multiple patients are retrieved
            setSelectedPatientID(uniquePatients[0].id);
          } else {
            const filteredPatients = uniquePatients.filter((patient) => {
              const fullName = normalizeName(
                `${patient.name} ${patient.surname}`
              );
              const normalizedSearchText = normalizeName(searchedPatientID);
              return (
                patient.id.includes(searchedPatientID) ||
                fullName.includes(normalizedSearchText)
              );
            });

            setPatientList(filteredPatients.slice(0, 1));

            if (filteredPatients.length > 0) {
              setSelectedPatientID(filteredPatients[0].id);
            }
          }

          setPatientFound(true);
          if (uniquePatients.length > 0) {
            await searchPatientInvoiceHistory(uniquePatients[0]);
          }
        } else {
          setErrorMessage("Patient Not Found");
          setPatientList([]);
        }
      } catch (error) {
        setErrorMessage(error.message);
        setPatientList([]);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (showListClinicalNotes) {
      setPatientFound(true);
    }
  }, [showListClinicalNotes]);
  useEffect(() => {
    clearStores();
  }, []);
  const normalizeName = (name) => {
    const titles = ["mrs", "mr", "miss", "ms", "dr"];
    const normalized = name
      .split(" ")
      .filter((part) => !titles.includes(part.toLowerCase()))
      .join(" ")
      .toLowerCase()
      .trim();
    return normalized;
  };

  const searchPatientInvoiceHistory = async (patient) => {
    try {
      setLoading(true);
      const searchName = `${patient.name} ${patient.surname}`;

      const result = await InvoiceHistoryService(token).fetchInvoiceHistory(
        searchName
      );
      const sortedData = result.invoices;

      const filteredInvoices = sortedData.filter((invoice) => {
        const invoiceName = normalizeName(invoice.PatientName);
        return (
          normalizeName(`${patient.name} ${patient.surname}`) === invoiceName
        );
      });
      setAllInvoiceHistoryInfo(filteredInvoices);
      setFilteredData(filteredInvoices);
    } catch (error) {
      console.error("Error searching patient invoice history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {}, [filteredData]);

  const filterData = (fromDate, toDate) => {
    let data = [...allInvoiceHistoryInfo];

    if (fromDate && toDate) {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);
      data = data.filter((item) => {
        const caseDate = new Date(item.InvoiceDate);
        return caseDate >= fromDateObj && caseDate <= toDateObj;
      });
    } else if (fromDate) {
      const fromDateObj = new Date(fromDate);
      const currentDate = new Date();
      data = data.filter((item) => {
        const caseDate = new Date(item.InvoiceDate);
        return caseDate >= fromDateObj && caseDate <= currentDate;
      });
    }
    setFilteredData(data);
  };
  useEffect(() => {
    if (fromDate && toDate) {
      filterData(fromDate, toDate);
    }
  }, [fromDate, toDate]);

  return (
    <div>
      <Formik
        initialValues={{ patientID: "", fromDate: "", toDate: "" }}
        validationSchema={Yup.object({
          patientID: Yup.string().required("Patient ID is required"),
        })}
        onSubmit={(values) => {
          searchPatientInfo(values.patientID);
        }}
      >
        {({ handleChange, handleSubmit, values, setFieldValue }) => (
          <div style={{ minHeight: "65vh" }}>
            {/* Input Search Section */}
            <div className="mt-4 search-padding">
              {!hideSearchBar && (
                <>
                  <span className="bold-txt">
                    {isBillingHistory
                      ? "Enter: Ref No / Patient Surname / Acc No"
                      : "Enter: ID / Medical Aid No / Acc No / Surname"}
                  </span>
                  <GenericSearch
                    handleChange={handleChange("patientID")}
                    values={values.patientID}
                    clickFunc={(e) => searchPatientInfo(values.patientID, e)}
                  />
                </>
              )}
            </div>
            {/* Search Result Rendering */}
            <div>
              <Loader loading={loading} />
              {patientFound ? (
                <div className="search-padding">
                  {patientList.map((item) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        setPatientFound(false);
                        onPatientSelect(item);
                      }}
                      className="d-flex py-3 raduis col-12"
                    >
                      <div className="px-2 col-4 d-flex justify-content-center align-items-center">
                        <span role="button">
                          Name: {`${item.name} ${item.surname}`}
                        </span>
                      </div>
                      <div className="px-2 col-4 d-flex justify-content-center align-items-center">
                        <span role="button">ID No: {`${item.id}`}</span>
                      </div>
                      <div className="px-2 col-4 d-flex justify-content-center align-items-center">
                        <span role="button">
                          Acc No: {`${item.debtor_number}`}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                errorMessage && (
                  <div className="mt-5">
                    <img
                      alt=""
                      className="py-4"
                      src={require("../../assets/icons/Patient Not Found Icon.png")}
                      width={50}
                    />
                    <h4>Patient Not Found</h4>
                  </div>
                )
              )}
              {children}
              {!isBillingHistory && (
                <div className="mt-5">
                  <div className="mgNotFound">
                    <span>Select an Option to Load New Patient Data</span>
                    <div className="responsive-flex">
                      <div
                        className="w-100 m-0 m-md-2"
                        onClick={() => {
                          navigate("/manualentry");
                        }}
                      >
                        <NavigationButton
                          img={require("../../assets/icons/ManualEntryIcon.png")}
                          header="Manual Entry"
                          handleClick={() => navigate("/manualentry")}
                        />
                      </div>
                      <div
                        className="w-100 m-0 m-md-2"
                        onClick={() => {
                          navigate("/uploaddoc");
                        }}
                      >
                        <NavigationButton
                          img={require("../../assets/icons/Upload Picture or Document Icon.png")}
                          header="Upload A-Slip or Patient Sticker"
                          handleClick={() => navigate("/uploaddoc")}
                        ></NavigationButton>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </Formik>
      {/* <MainFooter/> */}
    </div>
  );
};

export default PatientSearch;
