import { useFormikContext } from 'formik';
import React, { useState, useEffect, useCallback } from 'react'
import specialties from '../../assets/Codes/specialties.json';
import CustomScroll from '../common/CustomScroll';
import { debounce } from "lodash";
import useModifiersStore from '../state/ModifiersStore';
const ManualAssistant = ({showAssistant}) => {

    const {errors,touched,setFieldValue} = useFormikContext()
    const [disciplineFlatlistVisible, setDisciplineFlatlistVisible] = useState(false);
    const [searchTermDisType, setSearchTermDisType] = useState("")
    const {setManualAssistantSpeciality,setManualAssistantName,manualAssistantName,manualAssistantSpeciality} = useModifiersStore()
    const [filteredSpecialties, setfilteredSpecialties] = useState(specialties.specialty);
    const handleSpeciality = (text,setFieldValue) => {
        setManualAssistantSpeciality(text);
        if (text === "") {
          setFieldValue('specialty',"")
          setManualAssistantSpeciality("");
          setDisciplineFlatlistVisible(false)
        } else {
          setDisciplineFlatlistVisible(true);
  
          debouncedDiscipline(text);
          setFieldValue('specialty',text)
        }
    };
    const debouncedDiscipline = useCallback(
        debounce((text) => {
          setSearchTermDisType(text);
        }, 250),
        []
      );
      useEffect(() => {

        const filteredData = specialties.specialty.filter((item) => {
          const lowerCasedSearchTerm = searchTermDisType.toLowerCase();
          return item.toLowerCase().includes(lowerCasedSearchTerm);
        }).slice(0, 20);
    
        setfilteredSpecialties(filteredData);
      }, [searchTermDisType]);
  return (
    <div>
    {showAssistant && (
        <div>
          <div style={{ position: "relative" }}>
            <label>Name & Surname</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) => {
                setManualAssistantName(e.target.value);
                setFieldValue("nameandsurname",e.target.value)
              }}
              value={manualAssistantName}
              placeholder="Enter name and surname"
              style={{ width: "100%" }}
            />
            {errors.nameandsurname && touched.nameandsurname && (
              <div className="error-text">{errors.nameandsurname}</div>
            )}
          </div>

          <div style={{ position: "relative" }}>
            <label>Speciality</label>
            <input
              className="form-control"
              type="text"
              onChange={(e) =>
                handleSpeciality(e.target.value, setFieldValue)
              }
              value={manualAssistantSpeciality}
              placeholder="Enter speciality"
              style={{ width: "100%" }}
            />
            {errors.specialty && touched.specialty && (
              <div className="error-text">{errors.specialty}</div>
            )}
          </div>
          {disciplineFlatlistVisible && filteredSpecialties.length > 0 && (
            <CustomScroll maxHeight={"250px"} smallerScroll={false}>
              <div className="d-flex flex-column justify-content-start align-items-start pointer">
                {filteredSpecialties.map((item, index) => (
                  <div
                    key={index}
                    style={{ padding: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      setDisciplineFlatlistVisible(false);
                      setManualAssistantSpeciality(item);
                      setFieldValue("specialty", item);
                    }}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </CustomScroll>
          )}
        </div>
      )}
    </div>

  )
}

export default ManualAssistant