import React from "react";

const SubTask = ({ subTasks }) => {
  return (
    <>
      {subTasks.subtask ? (
        <div key={subTasks.subtask.SubTaskID} className="row col-12">
          <div className="profile-circle-internal col-3">
            <img
              src={require("../../assets/xp-icon-new.png")}
              alt="Profile"
              style={{ objectFit: "contain" }}
              width={30}
              height={30}
            />
          </div>
          <div className="col-9"> 
            <small className="d-flex text-secondary">
              {subTasks.subtask.SubTaskCreatedDate}
            </small>
            <div className="subtask-box">
              <p className="comment-desc">
                {subTasks.subtask.SubTaskInstruction || ""}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center", margin: "10px" }}>No subtask found</p>
      )}
    </>
  );
};

export default SubTask;
