
 import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/authentication/LoginService";
import useActiveTab from "../screens/state/ActiveTab";

const useLogout = () => {
    const { handleLogout } = useContext(AuthContext);
    const navigate = useNavigate();
    const {setActiveTab } = useActiveTab()
    const logoutFunc = async () => {
        await handleLogout();
        setActiveTab("clientportal");
        navigate('/', { replace: true });
    };

    return logoutFunc;
};

export default useLogout;
