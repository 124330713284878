import React from "react";
import "../../styles/components/OrganisationItem.scss";
const OrganisationItem = ({ img, heading, amount, color }) => {
  return (
    <div className="col-12 row bg-white borderGray p-0 mt-4">
      <div
        className={`col-2 ${
          color === "black" ? "bg-dark" : color === "red" ? "red" : "gray"
        }`}
      >
        <img
        className="orgImage"
          alt="img"
          src={img}
        />
      </div>

      <div className="col-10 d-flex flex-column align-items-start">
        <span className="py-1 fontFamily">{heading}</span>
        <span className="py-1 boldColor">R{amount}</span>
      </div>
    </div>
  );
};

export default OrganisationItem;
