import React from 'react'
import OrganisationItem from './OrganisationItem'
import billingIMG from "../../assets/XP0299-Xpedient-XpedientApp-Icons-24.png"
import pinIMG from "../../assets/pin.svg"
import "../../styles/components/OrganisationItem.scss";
import temp from "../../assets/temp.svg"
import handshake from "../../assets/handshake.svg"
const OrganisationOverview = () => {
  return (
    <div>
        <h5 className='d-flex boldColor'>Organisation Overview</h5>
        <div className='col-12 row'>
        <div className='col-md-6 col-12'>
            <OrganisationItem img={billingIMG} color={"red"} heading={"Invoiced Month to Date"} amount={"230 460 595.98"}/>
            <OrganisationItem img={billingIMG} color={"black"} heading={"Invoiced Today"} amount={"230 460 595.98"}/>
            <OrganisationItem img={temp} color={"gray"} heading={"Age vs Turnover"} amount={"230 460 595.98"}/>
        </div>
        <div className='col-md-6 col-12'>
            <OrganisationItem img={pinIMG} color={"red"} heading={"Monies Receipted Month to Date"} amount={"123 665 358.06"}/>

            <OrganisationItem img={pinIMG} color={"black"} heading={"Months Receipted Today"} amount={"123 665 358.06"}/>
            <OrganisationItem img={handshake} color={"gray"} heading={"MTD Collections vs Invoices"} amount={"123 665 358.06"}/>
        </div>
        </div>
       

    </div>
  )
}

export default OrganisationOverview