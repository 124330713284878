import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import "../../../styles/components/ProcedureCodeSearch.scss"
const CategoriesCodeList = ({filteredICD10Data,firstSlice,fetchMore,hasMore,handleCategoryClick}) => {

  return (
    <div>
    {filteredICD10Data ? (
      filteredICD10Data.length > 0 ? (
        <InfiniteScroll
          height={"50vh"}
          dataLength={firstSlice.length}
          next={fetchMore}
          hasMore={hasMore}
          className='custom-scroll'
          loader={<i className="fas fa-spinner fa-spin"></i>}
        >
          <div>
            {firstSlice.map((item, index) => (
              <div
                onClick={() =>
                  handleCategoryClick([
                    item.icd103Code_Desc,
                    item.icd10Code,
                    item.chapterDesc,
                    item.groupDesc,
                  ])
                }
                className={`d-flex justify-content-between rounded pointer ${
                  index % 2 !== 0 ? "lightgray" : ""
                }`}
                key={index}
              >
                <span className="p-3">{item.icd10Code}</span>
                <span className="p-3">{item.whoFullDesc}</span>

              </div>
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <span>Loading ...</span>
      )
    ) : (
      <span>Loading ...</span>
    )}
  </div>
  )
}

export default CategoriesCodeList