import { openDB } from 'idb';
import icd10data from '../../assets/Codes/icd10code.json';
import PROD_API from '../baseEndpoint';
import axios from 'axios';
const cacheKey = 'icd10CodeData';
const cacheVersion = 1; // Initial version number
const dbName = 'ICD10DB';
const storeName = 'icd10Store';

// Open IndexedDB database
const initDB = async () => {
  return await openDB(dbName, cacheVersion, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName);
      }
    },
  });
};

const ICD10Service = () => {
  const loadFromCache = async () => {
    try {
      const db = await initDB();
      const cachedData = await db.get(storeName, cacheKey);
      if (cachedData) {
        if (cachedData.version === cacheVersion) {
          // console.log('Loaded data from cache for ICD-10 codes');
          return cachedData.data;
        } else {
          // console.log('Cache version mismatch, clearing cache');
          await clearCache();
        }
      }
    } catch (error) {
      console.error('Error loading from cache:', error);
    }
    return null;
  };

  const saveToCache = async (data) => {
    try {
      const db = await initDB();
      const dataToCache = { version: cacheVersion, data }; // Include version number in cached data
      await db.put(storeName, dataToCache, cacheKey);
      // console.log('Data cached for ICD-10 codes');
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  };

  const clearCache = async () => {
    try {
      const db = await initDB();
      await db.delete(storeName, cacheKey);
      console.log('Cache cleared');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  };

  const getICD10Data = async (token,codeType,code) => {
    try {
      const apiUrl = PROD_API +`get_code_detail?accessToken=${token}&codeType=${codeType}&code=${code}`;
      const result = await axios.post(apiUrl);
      return result.data;
      
    } catch (error) {
      console.error('Error getting ICD10 Data:', error.message);
    }
  }
  const ICD10Data = async () => {
    try {
      const cachedData = await loadFromCache();
      if (cachedData) {
        return cachedData;
      }

      if (icd10data && icd10data.length > 0) {
        const newData = icd10data.map((item) => ({
          number: item.number,
          chapterNo: item.chapterNo,
          chapterDesc: item.chapterDesc,
          groupCode: item.groupCode,
          groupDesc: item.groupDesc,
          icd103Code: item.icd103Code,
          icd103Code_Desc: item.icd103Code_Desc,
          icd10Code: item.icd10Code,
          whoFullDesc: item.whoFullDesc,
        }));

        await saveToCache(newData);

        return newData;
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch ICD10 data. An error occurred:', error);
      throw error;
    }
  };

  const ICD10DataAlt = async () => {
    try {
      if (icd10data && icd10data.length > 0) {
        const newData = icd10data.map((item) => ({
          number: item.number,
          chapterNo: item.chapterNo,
          chapterDesc: item.chapterDesc,
          groupCode: item.groupCode,
          groupDesc: item.groupDesc,
          icd103Code: item.icd103Code,
          icd103Code_Desc: item.icd103Code_Desc,
          icd10Code: item.icd10Code,
          whoFullDesc: item.whoFullDesc,
        }));

        const sliceSize = 10;
        const numberOfSlices = Math.ceil(newData.length / sliceSize);
        const slices = [];
        for (let i = 0; i < sliceSize; i++) {
          slices.push(newData.slice(i * numberOfSlices, (i + 1) * numberOfSlices));
        }

        return [newData, slices];
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch ICD10 data. An error occurred:', error);
      throw error;
    }
  };

  return {
    ICD10Data,
    clearCache,
    ICD10DataAlt,
    getICD10Data
  };
};

export default ICD10Service;
