import axios from "axios";
import PROD_API from "../baseEndpoint";

const SearchEntitiesService = (token) => {
  const fetchSearchEntityHistory = async (searchparm = "") => {
    try {
      const apiUrl = PROD_API + `get_entity_search?accessToken=${token}&searchTerm=${searchparm}`;
      
      const response = await axios.post(apiUrl);
      
      if (response.status === 200) {
        const { itemList, status, message, parm_extra } = response.data;
        
        // Transform itemList from object to array format expected by the component
        const options = itemList ? Object.entries(itemList).map(([id, name]) => ({
          id,
          name
        })) : [];
        
        return {
          options,         // Now returns an array of objects with id and name
          status,           
          message,          
          parm_extra       
        };
      } else {
        console.error("Failed to retrieve entity data. Status code:", response.status);
        return { 
          options: [],
          status: response.status,
          message: "Failed to retrieve data",
          parm_extra: "No data" 
        };
      }
    } catch (error) {
      console.error("Error fetching entity data:", error);
      return { 
        options: [],
        status: error.response?.status || -1,
        message: error.message || "Error occurred",
        parm_extra: "Error" 
      };
    }
  };
  
  return { fetchSearchEntityHistory };
};

export default SearchEntitiesService;