import React,{useState, useRef} from 'react'
import { useEffect } from 'react'
import { Formik } from 'formik'
import "../../styles/components/FinancialDashboard.scss"
import Sidebar from '../Sidebar/Sidebar'
import GenericFinanceTab from './GenericFinanceTab'
import { useAuth } from '../../services/authentication/LoginService'
import Cookie from 'universal-cookie'
import { getCurrentDate } from '../../utils/dateUtils'
import dailyStatisticService from '../../services/FinancialDashboard/dailyStatisticService'
import useClearStores from '../../utils/clearStores';
import AgeAnalysisTab from './AgeAnalysisTab';
import StatisticsMonthlyTab from './StatisticsMonthlyTab';
import { formatCurrencyWithoutZar } from '../../services/Utils/formatCurrency'

function FinancialDashboard() {
    var cookie = new Cookie();
    // const token = cookie.get("jwt_authentication");
    const { token } = useAuth();
    const clearStores = useClearStores()
    const [activeTab,setActiveTab] = useState("SD") ;
    const [selectedDaily,setSelectedDaily] = useState(0);
    const [dailyStats, setDailyStats] = useState(null);
    const [currentDailyAnalysisData, setCurrentDailyAnalysisData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [error, setError] = useState(null); 
    const [dailyBreakdown, setDailyBreakdown] = useState({
      invoices_created: '',
      monies_receipted: '',
      journals: ''
    });    
    const [dailyBreakdownAvgs, setDailyBreakdownAvgs] = useState({
      invoices_created: '',
      monies_receipted: '',
      journals: ''
    });

    useEffect(()=>{
      clearStores()
    },[])
   
    useEffect(() => {
      const fetchData = async () => {
        setLoading(true);
        setError(null); 
        try {
          const { fetchDailyStatistics } = dailyStatisticService(token);
          const statsData = await fetchDailyStatistics(); 
          // console.log("DAILY STAT", statsData)
          if (statsData) {
            if (statsData.age_analysis) {
              const mappedAnalysis = [
                { heading: "Total", amount: statsData.age_analysis.total },
                { heading: "Current", amount: statsData.age_analysis.current },
                { heading: "30 Days", amount: statsData.age_analysis.days_30 },
                { heading: "60 Days", amount: statsData.age_analysis.days_60 },
                { heading: "90 Days", amount: statsData.age_analysis.days_90 },
                { heading: "120 Days", amount: statsData.age_analysis.days_120 },
                { heading: "150 Days", amount: statsData.age_analysis.days_150 },
                { heading: "180+ Days", amount: statsData.age_analysis.days_180_plus },
              ];
              setCurrentDailyAnalysisData(mappedAnalysis);
            } else {
              setCurrentDailyAnalysisData([]); 
            }
            setDailyBreakdown({
              invoices_created: statsData.daily_breakdown.invoices_created,
              monies_receipted: statsData.daily_breakdown.monies_receipted,
              journals: statsData.daily_breakdown.journals
            });
  
            setDailyBreakdownAvgs({
              invoices_created: statsData.daily_breakdown_avgs.invoices_created,
              monies_receipted: statsData.daily_breakdown_avgs.monies_receipted,
              journals: statsData.daily_breakdown_avgs.journals
            });
            setDailyStats(statsData);
          } else {
            setDailyStats(null);
            setCurrentDailyAnalysisData([]);
          }
        } catch (err) {
          setError("Failed to fetch data.");
          setDailyStats(null);
          setCurrentDailyAnalysisData([]); 
        } finally {
          setLoading(false); 
        }
      };

      fetchData();
    }, [token, selectedDate]);

    return (
            <Sidebar heading={"FINANCIAL DASHBOARD"}>
              <Formik
              initialValues={{
                fromDateMonthly:"",
                toDateMonthly:""
              }}
              >
              {({
              errors,
              touched,
            }) => (
              
          <div className='mt-4'>
            <div className='d-flex justify-content-start pointer position-relative col-12'>
              <span onClick={() => setActiveTab("SD")} className={`text-start ml-4 ${activeTab === "SD" && "bottomBorderPurple"}`}>
                Statistics - Daily
              </span>
              <span onClick={() => setActiveTab("SM")} className={`text-start ml-4 ${activeTab === "SM" && "bottomBorderPurple"}`}>
                Statistics - Monthly
              </span>
              <span onClick={() => setActiveTab("AA")} className={`text-start ml-4 ${activeTab !== "SD" && activeTab !== "SM" && "bottomBorderPurple"}`}>
                Age Analysis
              </span>
            </div>
     
           <div className='w-100 mt-3 border-bottom'></div>       
          <div className='container '>
          {activeTab === "SD" ? (
              <>
              <div className="d-flex flex-column align-items-start">
                    <GenericFinanceTab
                      averages={false}
                      invoicesCreated = {dailyBreakdown.invoices_created}
                      moniesReceipted =  {dailyBreakdown.monies_receipted}
                      journals= {dailyBreakdown.journals}
                      heading="Daily Breakdown"
                      subHeading="Daily Breakdown data are based on the previous day"

                    />
                    
                    <GenericFinanceTab
                      averages={true}
                      invoicesCreated = {dailyBreakdownAvgs.invoices_created}
                      moniesReceipted = {dailyBreakdownAvgs.monies_receipted}
                      journals = {dailyBreakdownAvgs.journals }
                      heading="Daily Breakdown - Averages"
                      subHeading="Averages are based on data of the previous 12 months"
                    />
              </div>  
                <div className='bg-light mt-4'>
                    <div className='p-4'>
                      <h4 className='d-flex bold-color '>Age Analysis</h4>
                      <span className="d-flex" style={{ fontSize: '14px' }}>
                      Age Analysis as at 17h30 for the previous business day.
                      </span>
                    <div className="row">
                      {currentDailyAnalysisData.length > 0 ? (
                        currentDailyAnalysisData.map((item, idx) => (
                          <div
                            onClick={() => setSelectedDaily(idx)}
                            className={`${
                              selectedDaily === idx
                                ? "col-lg col-12 daily-item"
                                : "col-lg col-12 white-custom"
                            } bottom-tabs rounded pointer m-1 border-0`}
                            key={idx}
                          >
                            <div>
                              <span
                                className={`${
                                  selectedDaily === idx ? "text-white" : "text-dark"
                                }`}
                              >
                                {item.heading}
                              </span>
                            </div>
                            <span
                              className={`${
                                selectedDaily === idx ? "text-white" : "text-darkblue bold-color"
                              }`}
                            >
                              {formatCurrencyWithoutZar(item.amount)}
                            </span>
                          </div>
                        ))
                      ) : (
                        <p>No data available.</p> 
                      )}
                  </div>
                  </div>
              </div>
         </>
         )  : activeTab === 'SM' ? (
                 <StatisticsMonthlyTab/>
                 ) : (
                  <AgeAnalysisTab/>
                 )}       
              </div>
                 {/* <MainFooter/> */}
            </div>
             )}             
    </Formik>
    </Sidebar>
  )
}

export default FinancialDashboard

