import React, { useState, useEffect } from "react";
import ProcedureCodeService from "../../services/Billing/ProcedureCodeService";
import ModifiersService from "../../services/Billing/ModifiersService";
import FavouriteHistoryService from "../../services/Favourites/FavouriteHistoryService";
import AssistantService from "../../services/Billing/AssistantService";
import _ from "lodash";
import { useAuth } from "../../services/authentication/LoginService";
import FavouriteDetailsService from "../../services/Favourites/FavouriteDetailsService";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import Modifiers from "./Modifiers";
import useModifiersStore from "../state/ModifiersStore";
import SearchProcedureCode from "./SearchProcedureCode";
import { useFormikContext } from "formik";
const ProcedureCode = ({
  reInvoice = null,
  sidebar,
  hideHeadings = false,
  setSelectedAssistants, // Add this prop
}) => {
  //STATE VARIABLES
  const { token } = useAuth();
  const { setFieldTouched, setFieldValue } = useFormikContext();
  const procedurecodeService = ProcedureCodeService();
  const modifierService = ModifiersService();
  const favouriteService = FavouriteHistoryService(token);
  const assistantLists = AssistantService(token);
  const favouriteDetailsService = FavouriteDetailsService(token);
  const currentDate = new Date().toISOString();
  //---------------------------------------------- MODIFIERS --------------------------------------------------------//
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];
  const weightCodes = ["0019", "0016", "0018"];
  const exceptionCodes = [
    "0008",
    "0009",
    "0076",
    "0029",
    "0011",
    "0011A",
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0018",
    "0019",
  ];
  const [exceptionLineItems, setExceptionLineItems] = useState([]);
  const [filteredUpdatedLineItems, setFilteredUpdatedLineItems] = useState([]);
  const [favouriteUpdatesLinesItems, setFavouriteUpdatesLinesItems] = useState(
    []
  );
  const [hasMounted, setHasMounted] = useState(false);
  const [updatedLineItems, setUpdatedLineItems] = useState([]);
  const [allProcedureData, setAllProcedureData] = useState([]);
  const [filteredProcedureData, setFilteredProcedureData] = useState([]);
  const [modifierData, setModifierData] = useState([]);
  const [assistantVals, setAssistantVals] = useState([]);
  const [favouriteData, setFavouriteData] = useState([]);
  const { setFilteredAssistant } = useModifiersStore();
  const { values } = useFormikContext();
  const {
    selectedProcedureCodes,
    setSelectedProcedureCodes,
    selectedICD10Codes,
    setSelectedICD10Codes,
    selectedNappiCodes,
    setselectedNappiCodes,
    setModifier,
  } = useProcedureCodesStore();
  const handleQuantityChange = (text, index) => {
    const quantity = parseInt(text, 10);
    if (!isNaN(quantity) && quantity >= 0) {
      const updatedProcedureCodes = [...selectedProcedureCodes];
      updatedProcedureCodes[index].quantity = quantity;
      setSelectedProcedureCodes(updatedProcedureCodes);
    }
  };
  const removeAssistantCode = (codeToRemove) => {
    if (assistantCodes.includes(codeToRemove)) {
      const updatedAssistants = { ...values.assistants };
      delete updatedAssistants[codeToRemove];
      setFieldValue("assistants", updatedAssistants);
    }
  };
  const handleAssistantSelect = (code, assistant) => {
    setSelectedAssistants((prev) => ({
      ...prev,
      [code]: assistant,
    }));
    setFieldValue(`assistants.${code}`, {
      name: assistant.name,
      speciality: assistant.speciality_code || "",
      pracNumber: assistant.practice_nr || "",
    });
  };

  const [searchTermProc, setSearchTermProc] = useState("");

  useEffect(() => {
    if (hasMounted) {
      setFieldTouched("selectedICD10Codes", true, true);
    }
  }, [selectedICD10Codes]);
  const handleFavouriteQuantityChange = (text, index, type) => {
    let quantity;
    if (text === "") {
      // Handle empty input
      quantity = ""; // or any default value you prefer
    } else {
      quantity = parseInt(text);
    }

    if (!isNaN(quantity) && quantity >= 0) {
      if (type === "favourite") {
        const updatedFavouriteItems = [...favouriteUpdatesLinesItems];
        updatedFavouriteItems[index].quantity = quantity;
        setFavouriteUpdatesLinesItems(updatedFavouriteItems);
      } else if (type === "updated") {
        const updatedUpdatedItems = [...updatedLineItems];
        updatedUpdatedItems[index].quantity = quantity;
        setUpdatedLineItems(updatedUpdatedItems);
      }
    } else {
      // console.log("Invalid quantity input");
    }
  };
  const handleSelectProcedures = async (item, setFieldValue) => {
    const newItem = {
      ...item,
      quantity: 1,
      modifierCode: item.modifierCode || "",
      modifierDescription: item.modifierDescription || "",
    };

    try {
      // Fetch favorite details based on selected code
      const [favouriteICD10Details, favouriteDetails, favouriteNappiDetails] =
        await Promise.all([
          favouriteDetailsService.fetchFavouriteDetails(newItem.code, 1),
          favouriteDetailsService.fetchFavouriteDetails(newItem.code, 2),
          favouriteDetailsService.fetchFavouriteDetails(newItem.code, 3),
        ]);
      // Handle ICD10 details
      if (favouriteICD10Details && favouriteICD10Details.itemList) {
        const detailICD10Codes = Object.keys(
          favouriteICD10Details.itemList
        ).map((icd10Code) => ({
          icd10Code: icd10Code,
          isFavorite: true,
        }));
        const updatedICD10Codes = [...selectedICD10Codes, ...detailICD10Codes];

        setFieldValue("selectedICD10Codes", updatedICD10Codes);
        setSelectedICD10Codes(updatedICD10Codes);
      }

      // Handle Nappi details
      if (favouriteNappiDetails && favouriteNappiDetails.itemList) {
        const detailNappiCodes = Object.keys(
          favouriteNappiDetails.itemList
        ).map((code) => ({
          code: code,
          quantity: 1,
          isFavorite: true,
        }));
        const updatedNappiCodes = [...selectedNappiCodes, ...detailNappiCodes];
        setselectedNappiCodes(updatedNappiCodes);
        setFieldValue("selectedNappiCodes", updatedNappiCodes);
      }

      // Handle favorite details
      if (favouriteDetails && favouriteDetails.itemList) {
        const detailCodes = Object.entries(favouriteDetails.itemList).map(
          ([code, description]) => ({
            code: code,
            description: description || "",
            isFavorite: true,
          })
        );
        const updatedCodes = [...selectedProcedureCodes, ...detailCodes];
        setSelectedProcedureCodes(updatedCodes);
        setFieldValue("selectedProcedureCodes", updatedCodes);
      } else {
        console.error(
          "Error fetching favourite details: favouriteDetails is undefined"
        );
        return; // Handle errors gracefully
      }
    } catch (error) {
      console.error("Error fetching favourite details:", error);
    }
    // Update selected procedure codes
    const isCodeSelected = selectedProcedureCodes.some(
      (code) => code.code === item.code && code.description === item.description
    );
    let newSelectedCodes;

    if (isCodeSelected) {
      newSelectedCodes = selectedProcedureCodes.filter(
        (code) =>
          !(code.code === item.code && code.description === item.description)
      );
    } else {
      newSelectedCodes = [...selectedProcedureCodes, newItem];
      setSearchTermProc(""); // Clear search term if a new code is selected
    }
    setFieldValue("selectedProcedureCodes", newSelectedCodes);
  };

  const handleRemove0011Item = (codeToRemove) => {
    if (codeToRemove === "0011A") {
      return true; // Do nothing, as "0011A" should not be removed
    }

    if (codeToRemove === "0011") {
      // Remove both "0011" and "0011A"
      const updatedCodes = selectedProcedureCodes.filter(
        (item) => item.code !== "0011A" && item.code !== "0011"
      );
      setSelectedProcedureCodes(updatedCodes);
      setFieldValue("selectedProcedureCodes", updatedCodes);
      return true;
    }

    if (codeToRemove === "0008" || codeToRemove === "0009") {
      const contains0011A = selectedProcedureCodes.some(
        (item) => item.code === "0011A"
      );

      if (contains0011A) {
        // Remove "0011A" and the current assistant code
        const updatedCodes = selectedProcedureCodes.filter(
          (item) => item.code !== "0011A" && item.code !== codeToRemove
        );
        setSelectedProcedureCodes(updatedCodes);
        removeAssistantCode(codeToRemove);
      } else {
        // Simply remove the current code
        const updatedSelectedProcedureCodes = selectedProcedureCodes.filter(
          (item, index) => item.code !== codeToRemove
        );
        setSelectedProcedureCodes(updatedSelectedProcedureCodes);
        setFieldValue("selectedProcedureCodes", updatedSelectedProcedureCodes);
        removeAssistantCode(codeToRemove);
      }
      return true;
    }

    // If none of the above conditions match
    return false;
  };

  const handleRemoveItem = (codeToRemove) => {
    if (handleRemove0011Item(codeToRemove)) {
      return; //if try to remove 0011A
    }
    // Remove the item from the state without triggering the API
    if (favouriteUpdatesLinesItems.length > 0) {
      const favouriteUpdateLineItems = favouriteUpdatesLinesItems.map(
        (item) => item.code !== codeToRemove
      );
      setFavouriteUpdatesLinesItems(favouriteUpdateLineItems);
    }
    const updatedSelectedProcedureCodes = selectedProcedureCodes.filter(
      (item, index) => item.code !== codeToRemove
    );

    setSelectedProcedureCodes(updatedSelectedProcedureCodes);
    setFieldValue("selectedProcedureCodes", updatedSelectedProcedureCodes);
    removeAssistantCode(codeToRemove);
  };
  useEffect(() => {
    const updateLineItems = () => {
      try {
        const values = selectedProcedureCodes
          .map((code, index) => ({
            line_nr: index + 1 + (reInvoice?.invoiceLines?.length || 0),
            code: code.code,
            quantity: code.quantity,
            diagnosis: selectedICD10Codes
              .map((icd10) => icd10.icd10Code)
              .join(","),
            administered_timestamp: currentDate?.toString() || "",
            description: code.description,
          }))
          .filter((code) => code.code !== undefined);
        // Update updatedLineItems
        setUpdatedLineItems((prevItems) => [...prevItems, ...values]);

        // Combine line items
        const combinedLineItems = [
          ...(favouriteUpdatesLinesItems || []),
          ...values, // Use the newly created values instead of updatedLineItems
        ];

        // Filter out exceptions
        const filteredExceptions = combinedLineItems.filter((item) =>
          exceptionCodes.includes(item.code)
        );
        setExceptionLineItems(filteredExceptions);

        // Filter out favourite updates and updated items that are not exceptions
        const filteredUpdatedLines = values.filter(
          (item) => !exceptionCodes.includes(item.code)
        );
        setFilteredUpdatedLineItems(filteredUpdatedLines);
      } catch (error) {
        console.error(
          "An error occurred while filtering out undefined codes:",
          error
        );
      }
    };
    // Call the function to update line items
    updateLineItems();
  }, [selectedProcedureCodes, reInvoice, favouriteUpdatesLinesItems]); // Added dependencies for comprehensive updates

  const checkModifier = (codes) => {
    if (!codes || !Array.isArray(codes)) {
      return false; // Ensure `codes` is an array
    }

    return (
      codes.some((code) => assistantCodes.includes(code)) ||
      codes.some((code) => timeFromToCodes.includes(code)) ||
      codes.some((code) => weightCodes.includes(code))
    );
  };
  useEffect(() => {
    const codes = selectedProcedureCodes.map((codeObj) => codeObj.code);
    const newModifier = checkModifier(codes);
    setModifier(newModifier);
  }, [selectedProcedureCodes]);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [procedureData, modifierData, favouriteData, assistantsData] =
          await Promise.all([
            procedurecodeService.ProcedureCodeData(),
            modifierService.ModifiersData(),
            favouriteService.fetchFavouriteHistory(searchTermProc),
            assistantLists.AssistantData(),
          ]);

        if (favouriteData && favouriteData.itemList) {
          // Process favorites with proper code and description fields
          const favoriteList = favouriteData.itemList.map((item) => ({
            code: item.id, // Use ID as code for favorites
            codeFavourite: item.id,
            description: item.description,
            isFavorite: true,
          }));

          // Combine procedure data with favorites
          const allProcedureData = [
            ...procedureData.map((proc) => ({
              ...proc,
              isFavorite: false,
            })),
            ...favoriteList,
          ];

          setAllProcedureData(allProcedureData);
          setFilteredProcedureData(allProcedureData);
          setModifierData(modifierData);
          setFavouriteData(favoriteList);

          if (assistantsData) {
            setAssistantVals(assistantsData);
            setFilteredAssistant(assistantsData);
          }
        } else {
          setAllProcedureData([]);
          setFilteredProcedureData([]);
          setModifierData([]);
          setFavouriteData([]);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setAllProcedureData([]);
        setModifierData([]);
        setFavouriteData([]);
      }
    };

    fetchData();
    if (reInvoice) {
      const reInvoiceValue =
        reInvoice.invoiceLines?.length > 0
          ? reInvoice.invoiceLines.map((line) => ({
              code: line.Code || line.codeFavourite,
              description: line.Description,
              quantity: line.Quantity,
              administered_timestamp: currentDate.toString() || "",
            }))
          : []; // Return an empty array if no lines are present

      setSelectedProcedureCodes(reInvoiceValue);
    }
  }, []);
  const filteredProcedureDataSet = (data) => {
    setFilteredProcedureData(data);
  };
  return (
    <div className="row align-details">
      <SearchProcedureCode
        allProcedureData={allProcedureData}
        modifierData={modifierData}
        favouriteData={favouriteData}
        sidebar={sidebar}
        handleSelectProcedures={handleSelectProcedures}
        filteredProcedureData={filteredProcedureData}
        setFilteredProcedureData={filteredProcedureDataSet}
      />
      <div className="col-md-8">
        {filteredUpdatedLineItems.map((code, index) => (
          <div key={index} className="row">
            <div className="col-12 col-md-6 mb-md-0">
              {(!hideHeadings || (hideHeadings && index === 0)) && (
                <label>
                  Code<span className="error-text">*</span>
                </label>
              )}
              <input
                type="text"
                disabled
                value={code.code}
                className="form-control bg-light"
              />
            </div>
            <div className="col-12 col-md-6 mb-md-0 position-relative">
              {(!hideHeadings || (hideHeadings && index === 0)) && (
                <label>
                  Quantity<span className="error-text">*</span>
                </label>
              )}
              <input
                type="number"
                value={code.quantity || 1}
                onChange={(e) => handleQuantityChange(e.target.value, index)}
                className="form-control"
              />
              <div className="col-12 text-end">
                <img
                  onClick={() => handleRemoveItem(code.code)}
                  className="trashcan pointer"
                  style={{ objectFit: "contain" }}
                  src={require("../../assets/icons/Bin Icon.png")}
                  width={25}
                  alt="Remove"
                />
              </div>
            </div>
          </div>
        ))}
        <Modifiers
          exceptionLineItems={exceptionLineItems}
          handleFavouriteQuantityChange={handleFavouriteQuantityChange}
          handleRemoveItem={handleRemoveItem}
          weightCodes={weightCodes}
          assistantVals={assistantVals}
          onAssistantSelect={(code, assistant) =>
            handleAssistantSelect(code, assistant)
          }
        />
      </div>
    </div>
  );
};

export default ProcedureCode;
