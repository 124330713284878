import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import ContactUsService from "../../services/Queries/ContactUsService";
import { useAuth } from "../../services/authentication/LoginService";
import { Toast, ToastContainer } from "react-bootstrap";
import "../../styles/components/ContactUs.scss";
import SideNavbar from "../common/SideNavbar";
function ContactUsSidebar({ closeFunction, changeLoading }) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [show, setShow] = useState(false);
  const { token } = useAuth();
  const contactUsService = ContactUsService(token);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleFileChangeDrop = (event) => {
    if (event.dataTransfer.files.length > 0) {
        const file = event.dataTransfer.files[0];
        setSelectedFile(file); // Set the dragged file directly
      }
  }
  const validateForm = () => {
    let isValid = true;
    if (!subject.trim()) {
      setSubjectError("Subject is required");
      isValid = false;
    } else {
      setSubjectError("");
    }
    if (!message.trim()) {
      setMessageError("Message is required");
      isValid = false;
    } else {
      setMessageError("");
    }
    return isValid;
  };

  const sendEmail = async () => {
    if (!validateForm()) {
      return;
    }
    changeLoading(true);
    try {
      const emailBody = `\nSubject: ${subject}\n\nMessage: ${message}`;
      let attachments = [];
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);
        reader.onload = async () => {
          attachments = [
            {
              attachmentData: reader.result.split(",")[1],
              contentType: selectedFile.type || "application/octet-stream",
              fileName: selectedFile.name || "Untitled",
            },
          ];

          const emailData = {
            Email: { Body: emailBody,EmailType:1 },
            Attachments: attachments,
          };
          
          const response = await contactUsService.handleContactUsDetails(
            emailData
          );
          changeLoading(false);
          setShow(true);
        };
      } else {
        const emailData = { Email: { Body: emailBody,EmailType:1 }, Attachments: [] };
        const response = await contactUsService.handleContactUsDetails(
          emailData
        );
        changeLoading(false);

        setShow(true);
    
      }
    } catch (error) {
      console.error("Error sending email:", error);
      changeLoading(false);
      alert("Failed to send email. Please try again later.");
    }
  };
  useEffect(() => {
    validateForm();
  }, [message, subject]);
  return (
    <SideNavbar
      close={closeFunction}
      heading="Contact Us:"
      state={true}
      proceed={sendEmail}
      buttonText={"Send"}
      children={
        <div>
          <div className="container ">
            <div className="container">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>
                    Subject<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={2}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    isInvalid={!!subjectError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {subjectError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>
                    Message<span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    isInvalid={!!messageError}
                  />
                  <Form.Control.Feedback type="invalid">
                    {messageError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Attach a File</Form.Label>
                  <div
                    className="uploadFileBorder"
                    onClick={() => document.getElementById("fileInput").click()}
                    onDrop={(event) => {
                      event.preventDefault();
                        handleFileChangeDrop(event)
                    }}
                    onDragOver={(event) => event.preventDefault()}
                  >
                    <div className="contactUsAdd">
                      <i
                        className="fa fa-plus p-1"
                        style={{ color: "white" }}
                      ></i>
                    </div>
                    <span className="ml-1 uploadText">
                      Upload or drag and drop a file
                    </span>

                    <input
                      id="fileInput"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                  <ToastContainer position="top-end" className="p-3">
                    <Toast
                      show={show}
                      color="black"
                      autohide
                      bg="light"
                      onClose={() => setShow(false)}
                      delay={3000} // 3000ms = 3 seconds
                    >
                      <Toast.Header>
                        <strong className="me-auto">Help</strong>
                        <small>1s ago</small>
                      </Toast.Header>
                      <Toast.Body>Help Request Sent.</Toast.Body>
                    </Toast>
                  </ToastContainer>

                  {selectedFile && (
                    <div>
                 
                      <div className="mt-2">
                        <Button
                          variant="danger"
                          onClick={() => setSelectedFile(null)}
                        >
                          Remove File
                        </Button>
                      </div>
                      <small>File Name: {selectedFile.name}</small>
                    </div>
                  )}
                </Form.Group>
              </Form>
            </div>
          </div>
        </div>
      }
    ></SideNavbar>
  );
}

export default ContactUsSidebar;
