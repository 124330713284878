import proceduredata from '../../assets/Codes/procedurecode.json';

const cacheKey = 'procedureCodeData';
const cacheVersion = 3; 

const ProcedureCodeService = () => {
  const loadFromCache = () => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (parsedData.version === cacheVersion) {
          return parsedData.data;
        } else {
          console.log('Cache version mismatch, clearing cache');
          clearCache();
        }
      }
    } catch (error) {
      console.error('Error loading from cache:', error);
    }
    return null;
  };

  const saveToCache = (data) => {
    try {
      const dataToCache = { version: cacheVersion, data }; 
      localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      console.log('Data cached for procedure code');
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  };

  const clearCache = () => {
    try {
      localStorage.removeItem(cacheKey);
      console.log('Cache cleared');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  };

  const fetchProcedureData = async () => {
    return proceduredata;
  };

  const ProcedureCodeData = async () => {
    try {
      const cachedData = loadFromCache();
      if (cachedData) {
        return cachedData;
      }

      const freshData = await fetchProcedureData();
      if (freshData && freshData.length > 0) {
        const newData = freshData.map((item) => ({
          code: item["procedureCode"],
          description: item["description"],
          modelType: item["modelType"],
        }));

        saveToCache(newData);

        return newData;
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch procedure data. An error occurred:', error);
      throw error;
    }
  };

  return {
    ProcedureCodeData,
    clearCache
  };
};

export default ProcedureCodeService;
