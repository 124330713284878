import React from "react";
import "../../../styles/components/ClientPortal.scss";
import useActiveHistoryReport from "../../state/ActiveHistoryReport";
import { useNavigate } from "react-router-dom";
import { UserContext, } from "../../../services/authentication/UserContext";
import { useContext } from "react";

const ClientPortalTabOptions = () => {
  const { activeReportTab, setActiveReportTab } = useActiveHistoryReport();
  const { customerAccessControl } = useContext(UserContext);

  const navigate = useNavigate();

  const clickReportTab = (tab, route) => {
    setActiveReportTab(tab);
    navigate(route);
  };

  return (
    <div className="d-flex flex-column flex-md-row justify-content-start align-items-center col-12 mt-2 mb-4 border-bottom border-gray pointer">
      <div className="w-100 d-flex flex-column flex-md-row justify-content-between">
      {customerAccessControl?.RatesAndPolicies && (
          <div
            onClick={() => clickReportTab("rpp", "/ratesandpolicies")}
            className={`col-12 col-md-3 text-center py-2 ${
              activeReportTab === "rpp" && "border-purple"
            }`}
          >
            <span className="bold">Rates & Practice Policies</span>
          </div>
        )}
       {customerAccessControl?.Reports && (
          <div
            onClick={() => clickReportTab("r", "/reports")}
            className={`col-12 col-md-2 text-center py-2 ${
              activeReportTab === "r" && "border-purple"
            }`}
          >
            <span className="bold">Reports</span>
          </div>
        )}
        <div
          onClick={() => clickReportTab("nf", "/newsfeed")}
          className={`col-12 col-md-2 text-center py-2 ${
            activeReportTab === "nf" && "border-purple"
          }`}
        >
          <span className="bold">News Feed</span>
        </div>
        <div
          // onClick={() => clickReportTab("grc", "/guidanceandresource")}
          className={`col-12 col-md-3 text-center py-2 ${
            activeReportTab === "grc" && "border-purple"
          }`}
        >
          <span className="bold">Guidance & Resource Centre</span>
        </div>
      </div>
    </div>
  );
};

export default ClientPortalTabOptions;
