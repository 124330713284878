import axios from 'axios';
import PROD_API from '../baseEndpoint';

const ContactUsService = (token) => {
  const handleContactUsDetails = async (contactusDetails) => {
    // 1 is  contact us
    // 2 is App Report
    // 3 is API report
    const apiUrl = PROD_API + `send_support_email?accessToken=${token}`;
  
    const headers = {
      'Content-Type': 'application/json',
    };
  
    try {
  
  
      const response = await axios.post(apiUrl, contactusDetails, { headers });
  
      if (response.status === 200) {
        return response.data;  
      } else {
        const errorMessage = `${response.data.message}. Details: ${response.data.parm_extra}`;
        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error('Error during API call:', error);
      throw error;
    }
  };  

  return { handleContactUsDetails };
};

export default ContactUsService;