// useProcedureCodesStore.js
import { create } from "zustand";

const useProcedureCodesStore = create((set) => ({
  selectedProcedureCodes: [], // Initial state
  selectedRefDoc: [],
  serviceCenterData: [],
  selectedServCenter: [],
  servicecenterType: "",
  procedureCodeFlatListVisible:false, 
  modifier: "",
  activeTab:"icd10",
  activeTopTab:"Categories",
  setActiveTopTab: (activeTopTab) => set({ activeTopTab }),
  setActiveTab: (activeTab) => set({ activeTab }),
  setProcedureCodeFlatListVisible: (procedureCodeFlatListVisible) => set({ procedureCodeFlatListVisible }),
  setModifier: (modifier) => set({ modifier }),
  setservicecenterType: (servicecenterType) => set({ servicecenterType }),
  setSelectedServCenter: (selectedServCenter) => set({ selectedServCenter }),
  setSelectedRefDoc: (refDoc) => set({ selectedRefDoc: refDoc }),
  setServiceCenterData: (serviceCenterData) => set({ serviceCenterData }),
  // Function to set selected procedure codes
  setSelectedProcedureCodes: (codes) => set({ selectedProcedureCodes: codes }),

  selectedICD10Codes: [],

  setSelectedICD10Codes: (codes) => set({ selectedICD10Codes: codes }),

  selectedNappiCodes: [],
  setselectedNappiCodes: (codes) => set({ selectedNappiCodes: codes }),
  clearAllData: () =>
    set({
      selectedProcedureCodes: [],
      selectedRefDoc: [],
      serviceCenterData: [],
      selectedServCenter: [],
      servicecenterType: "",
      modifier: "",
      selectedICD10Codes: [],
      selectedNappiCodes: [],
    }),
}));

export default useProcedureCodesStore;
