import React, { createContext, useState } from 'react';

export const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
  const [username, setUsername] = useState('');
  const [entityDoctorName, setEntityDoctorName] = useState(''); 
  const [xpUserLogin, setXpUserLogin] = useState(''); 
  const [customerAccessControl, setCustomerAccessControl] = useState(''); 
  const [entityNumber, setEntityNumber] = useState(''); 
  const [entityDoctorNumber, setEntityDoctorNumber] = useState('');
  const [doctorSpeciality,setDoctorSpeciality] = useState('')
  const [email, setEmail] = useState(''); 
  const [password , setPassword] = useState(''); 

  return (
    <UserContext.Provider value={{ 
      username, 
      setUsername, 
      entityDoctorName, 
      setEntityDoctorName, 
      xpUserLogin, 
      setXpUserLogin,
      entityDoctorNumber, 
      setEntityDoctorNumber,
      email, 
      setEmail,
      customerAccessControl, 
      setCustomerAccessControl,
      entityNumber, 
      setEntityNumber,
      password,
      setPassword,
      doctorSpeciality,
      setDoctorSpeciality
    }}>
      {children}
    </UserContext.Provider>
  );
};
