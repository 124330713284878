import React, { useState, useEffect, useContext } from "react";
import "../../styles/components/Sidebar.scss";
import { useNavigate } from "react-router-dom";
import LogoutContainer from "./LogoutContainer";
import { AuthContext, useAuth } from "../../services/authentication/LoginService";
import MainFooter from "../common/MainFooter";
import { UserContext } from "../../services/authentication/UserContext";
import SidebarContainer from "./SidebarContainer";
import { getTrimmedUsername } from "../../utils/trimmedUsername";
import useWindowDimensions from "../../utils/screensize";
import useActiveTab from "../state/ActiveTab";
import useLogout from "../../utils/logoutFunction";

function Sidebar({ children, changeBackground = false, heading = "" }) {
  const { subTaskCount } = useAuth();
  const { width, height } = useWindowDimensions();
  const [showModal, setShowModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const { username, entityDoctorName, xpUserLogin } = useContext(UserContext);
  const { customerAccessControl,email, password } = useContext(UserContext);
  const { handleSignIn } = useContext(AuthContext);
  const navigate = useNavigate();
  const logoutFunction = useLogout();
  const trimmedUsername = getTrimmedUsername(username);
  const { setActiveTab } = useActiveTab();

  const signIn = async () => {
    try{
      const signInResult = await handleSignIn(email,password);
      if (signInResult?.success){
        return true;
      }
      return false;
    }catch{
      return false;
    }
  }
  // Function to handle logo click navigation based on xpUserLogin value
  const handleLogoClick = async () => {
    // Check if user is an XP user (xpUserLogin would be true)
    const hasRefreshed = await signIn()
    if (hasRefreshed){
      if (xpUserLogin === true) {
        navigate("/xpuserlogin");
      } else {
        setActiveTab("clientportal")
        navigate("/clientportal");
      }
    }else{
      logoutFunction();
    }
  };

  return (
    <div className="d-flex full-width">
      <div
        className={expanded ? (width > 900 ? "expand" : "random") : "wrapper"}
      >
        <div className={expanded ? "main-expanded" : "main"}>
          <div className="flexrow">
            <div>
              <div
                className="mt-2 pointer"
                onClick={() => setExpanded(!expanded)}
              >
                <div className="bg-danger bars">
                  <span className="dot">.</span>
                </div>
                <div className="bg-white bars">
                  <span className="dot">.</span>
                </div>
                <div className="bg-secondary bars">
                  <span className="dot">.</span>
                </div>
              </div>
            </div>
            {expanded && (
              <img
                onClick={handleLogoClick}
                className="xpimg pointer"
                src={require("../../assets/XPLOGO.png")}
                width={100}
                style={{ objectFit: "contain" }}
                alt="Logo"
              />
            )}
          </div>
          <SidebarContainer expanded={expanded} subTaskCount={subTaskCount} />
        </div>
      </div>

      <div
        className={`full-width main-body  ${changeBackground && "modal-overlay"}`}
      >
        <div className="top-bar">
          <div
            className="pointer mt-3 show-on-small"
            onClick={() => setExpanded(!expanded)}
          >
            <div className="bg-danger bars">
              <span className="dot">.</span>
            </div>
            <div className="bg-dark bars">
              <span className="dot">.</span>
            </div>
            <div className="bg-secondary bars">
              <span className="dot">.</span>
            </div>
          </div>

          {heading && (
            <div className="d-flex align-items-center w-100">
              <span className="heading">{heading}</span>
            </div>
          )}

          {showModal && <LogoutContainer />}

          <div className="initials">
            <div className="d-flex align-items-center">
              <div className="initials-text">
                <small className="t-text bold-color">{username}</small>
                <small className="t-text">-{entityDoctorName}</small>
              </div>
              <div className="profile pointer col-12">
                <small className="text-white t-text">{trimmedUsername}</small>
              </div>
            </div>

            <div className="d-flex justify-content-center align-items-center">
              <div
                onClick={() => setShowModal(!showModal)}
                className="pointer d-flex flex-column align-items-center dropdown-menu-bg"
              >
                <span className="dropdown-ellipses"></span>
                <span className="dropdown-ellipses"></span>
                <span className="dropdown-ellipses"></span>
              </div>
            </div>
          </div>
        </div>

        <div className="flex-grow">{children}</div>

        <div>
          <MainFooter />
        </div>
      </div>
    </div>
  );
}

export default Sidebar;