import React from "react";
import newQuery from "../../assets/newQuery.png";
import respondedtoXpedient from "../../assets/respondedtoXpedient.png";
import backBtnQuery from "../../assets/backBtnQuery.png";
import billing2 from "../../assets/billing2.png";
import resolvedQuery from "../../assets/icons/Green Tick Icon.png";
import CustomScroll from "../common/CustomScroll";
const BillingQueriesTable = ({
  sortQueryDataByDate,
  filteredData,
  toggleTable,
  rotate,
  rotated,
  expandedCase,
  renderAccordionDetails,
}) => {
  const getSubtaskCounts = (subTasks) => {
    if (!subTasks || subTasks.length === 0) {
      return { newQueryCount: 0, responseRequiredCount: 0 };
    }

    let newQueryCount = 0;
    let responseRequiredCount = 0;

    subTasks.forEach((task) => {
      if (task.SubtaskStatusDesc === "New Query") {
        newQueryCount++;
      } else if (task.SubtaskStatusDesc === "Response Required") {
        responseRequiredCount++;
      }
    });

    return { newQueryCount, responseRequiredCount };
  };

  const getSubtaskStatus = (subTasks) => {
    if (!subTasks || subTasks.length === 0) {
      return "";
    }

    const hasNewQuery = subTasks.some(
      (task) => task.SubtaskStatusDesc === "New Query"
    );
    if (hasNewQuery) return "New Query";

    const hasResponseRequired = subTasks.some(
      (task) => task.SubtaskStatusDesc === "Response Required"
    );
    if (hasResponseRequired) return "Response Required";

    const allRespondedToXpedient = subTasks.every(
      (task) => task.SubtaskStatusDesc === "Responded to Xpedient"
    );
    if (allRespondedToXpedient) return "Responded to Xpedient";

    const allResolved = subTasks.every(
      (task) => task.SubtaskStatusDesc === "Resolved"
    );
    if (allResolved) return "Resolved";

    const inProgresss = subTasks.every(
      (task) => task.SubtaskStatusDesc === "In Progress"
    );
    if (inProgresss) return "In Progress";

    return ""; // Default return if no conditions match
  };

  return (
    <CustomScroll maxHeight="60vh" smallerScroll={true}>
    <div className="table-responsive">
      <table className="table table-striped">
        <tbody>
          {filteredData?.length > 0 ? (
            sortQueryDataByDate(filteredData).map((item, index) => {
              const { newQueryCount, responseRequiredCount } = getSubtaskCounts(
                item.SubTasks
              );

              return (
                <React.Fragment key={index}>
                  <tr
                    className="pointer col-12"
                    onClick={() => toggleTable(index, item)}
                  >
                    {/* Status Icons */}
                    <td className="align-middle col-1">
                      {item.SubTasks[0].SubtaskStatusDesc === "New Query" && (
                        <img
                          width={30}
                          className="me-2"
                          src={newQuery}
                          alt="New Query"
                        />
                      )}
                      {item.SubTasks[0].SubtaskStatusDesc ===
                        "Responded to Xpedient" && (
                        <img
                          width={30}
                          className="me-2"
                          src={respondedtoXpedient}
                          alt="Responded to Xpedient"
                        />
                      )}
                      {item.SubTasks[0].SubtaskStatusDesc ===
                        "Response Required" && (
                        <img
                          width={30}
                          className="me-2"
                          src={backBtnQuery}
                          alt="Response Required"
                        />
                      )}
                      {item.SubTasks[0].SubtaskStatusDesc === "Resolved" && (
                        <img
                          width={30}
                          className="me-2"
                          src={resolvedQuery}
                          alt="Resolved"
                        />
                      )}
                      {item.SubTasks[0].SubtaskStatusDesc === "In Progress" && (
                        <img
                          width={30}
                          className="me-2"
                          src={billing2}
                          alt="In Progress"
                        />
                      )}
                    </td>

                    {/* Ticket Reference */}
                    <td className="align-middle col-4">
                      <span>Ticket Ref No | </span>
                      <span className="fw-bold">{item.CaseID}</span>
                      <span className="badge profileColor ms-2">
                      {newQueryCount > 0 && `${newQueryCount} New`}
                      {responseRequiredCount > 0 && `, ${responseRequiredCount} Response Required`}
                      </span>
                    </td>

                    {/* Subtask Description */}
                    <td className="align-middle col-4">
                      <span
                        className={`me-3 
                        ${
                          getSubtaskStatus(item.SubTasks) === "New Query"
                            ? "query-txt"
                            : ""
                        } 
                        ${
                          getSubtaskStatus(item.SubTasks) === "In Progress" &&
                          "text-dark"
                        } 
                        ${
                          getSubtaskStatus(item.SubTasks) ===
                            "Responded to Xpedient" || "Resolved"
                            ? "query-text-green"
                            : ""
                        } 
                        ${
                          getSubtaskStatus(item.SubTasks) ===
                          "Response Required"
                            ? "query-text-orange"
                            : ""
                        }`}
                      >
                        {getSubtaskStatus(item.SubTasks)}
                      </span>
                    </td>

                    {/* Date */}
                    <td className="align-middle col-2">
                      {item.CaseCreatedDate}
                    </td>

                    {/* Action Buttons */}
                    <td className="align-middle col-1">
                      {item.SubTaskStatus === "notResolved" ? (
                        <i className="fas fa-times text-danger fa-2x"></i>
                      ) : (
                        <img
                          width={30}
                          className={`arrow pointer ${
                            rotate === index
                              ? rotated
                                ? ""
                                : "arrow-rotate"
                              : "arrow-rotate "
                          }`}
                          src={require("../../assets/icons/Down Arrow Icon.png")}
                          alt="Toggle"
                        />
                      )}
                    </td>
                  </tr>

                  {/* Expanded Details Row */}
                  {expandedCase === item.CaseID && (
                    <tr>
                      <td colSpan="5" className="p-0">
                        {renderAccordionDetails(
                          item.SubTasks,
                          item.InvoiceReference
                        )}
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })
          ) : (
            <tr className="bg-white">No Queries</tr>
          )}
        </tbody>
      </table>
    </div>
    </CustomScroll>
  );
};

export default BillingQueriesTable;
