import React from "react";
import CustomScroll from "../../common/CustomScroll";
import { useCallback } from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import GenericSearch from "../../GenericSearch";
import ModifiersService from "../../../services/Billing/ModifiersService";
const ModifiersCodeList = () => {
  const modifierService = ModifiersService();
  const [loading, setLoading] = useState(false);
  const [filteredModifierData, setFilteredMofifierData] = useState([]);
  const [modifierData, setModifierDate] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [hasMounted, setHasMounted] = useState(false);

  const searchModifiers = () => {
    const codeFilter = modifierData.filter((each) =>
      each.modifierCode.includes(searchText)
    );

    // If nothing is found with code, then filter by description
    const filter =
      codeFilter.length > 0
        ? codeFilter
        : modifierData.filter((each) =>
            each.modifierDescription
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );

    setFilteredMofifierData(filter);
  };

  const handleSearch = useCallback(
    debounce(() => {
      searchModifiers();
    }, 10),
    [searchText]
  ); // Adjust the delay as needed
  useEffect(() => {
    if (hasMounted) {
      handleSearch();
    }
  }, [searchText]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    const fetchModifiers = async () => {
      setLoading(true);
      try {
        const data = await modifierService.ModifiersData();
        if (data) {
          setFilteredMofifierData(data);
          setModifierDate(data);
        } else {
          setModifierDate([]);
          setErrorMessage("Failed to fetch procedure codes");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch procedure codes");
      } finally {
        setLoading(false);
      }
    };
    fetchModifiers();
  }, []);

  const navigateMore = (desc, code) => {
    navigate("/moreinfocode", {
      state: {
        paragraph: desc,
        code,
        heading: "Modifiers",
        navigateBackText: "Modifiers",
      },
    });
  };

  return (
    <>
      <GenericSearch
        handleChange={(event) => setSearchText(event.target.value)}
        values={searchText}
        placeholder="Search Modifers"
      />
      <div className="mt-4">
        <CustomScroll smallerScroll={true} maxHeight={"50vh"}>
          {filteredModifierData.length > 0 ? (
            filteredModifierData.map((item, index) => (
              <div
                onClick={() =>
                  navigateMore(item.modifierDescription, item.modifierCode)
                }
                className={`d-flex justify-content-between align-left rounded pointer col-12 ${
                  index % 2 !== 0 ? "lightgray" : ""
                }`}
                key={index}
              >
                <span className="p-3 col-2">{item.modifierCode}</span>
                <span className="text-left p-3 col-10">
                  {item.modifierDescription.substring(0, 250) + "..."}
                </span>
              </div>
            ))
          ) : (
            <div className="text-center p-3">
              <span>No Modifiers Available</span>
            </div>
          )}
        </CustomScroll>
      </div>
    </>
  );
};

export default ModifiersCodeList;
