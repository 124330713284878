import React from "react";
import CustomScroll from "../../common/CustomScroll";
import { useEffect, useState } from "react";
import { debounce } from "lodash";
import { useCallback } from "react";
import RulesService from "../../../services/ProcedureCode/RulesService";
import GenericSearch from "../../GenericSearch";
import { useNavigate } from "react-router-dom";
const RulesCodesList = () => {
  const [filteredRuleData, setFilteredRuleData] = useState([]);
  const [rulesData, setRulesData] = useState([]);
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const rulesService = RulesService();
  const [loading, setLoading] = useState(false);
  const [hasMounted, setHasMounted] = useState(false);

  const searchRules = () => {
    const filter = rulesData.filter((each) =>
      each.ruleDesc.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredRuleData(filter);
  };

  const handleSearch = useCallback(
    debounce(() => {
      searchRules();
    }, 10),
    [searchText]
  ); // Adjust the delay as needed
  useEffect(() => {
    if (hasMounted) {
      handleSearch();
    }
  }, [searchText]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  useEffect(() => {
    const fetchRules = async () => {
      setLoading(true);
      try {
        const data = await rulesService.RulesData();

        if (data) {
          setFilteredRuleData(data);
          setRulesData(data);
        } else {
          setRulesData([]);
          setErrorMessage("Failed to fetch procedure codes");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch procedure codes");
      } finally {
        setLoading(false);
      }
    };
    fetchRules();
  }, []);

  const navigateMore = (desc, code) => {
    navigate("/moreinfocode", {
      state: {
        paragraph: desc,
        code,
        heading: "Rules",
        navigateBackText: "Rules",
      },
    });
  };
  return (
    <>
      <GenericSearch
        handleChange={(event) => setSearchText(event.target.value)}
        values={searchText}
        placeholder="Search Rules"
      />
      <div className="mt-4">
        <CustomScroll smallerScroll={true} maxHeight={"50vh"}>
          {filteredRuleData.length > 0 ? (
            filteredRuleData.map((item, index) => (
              <div
                onClick={() => navigateMore(item.ruleDesc, item.ruleCode)}
                className={`d-flex justify-content-between pointer align-left rounded col-12 ${
                  index % 2 !== 0 ? "lightgray" : ""
                }`}
                key={index}
              >
                <span className="p-3 col-2">{item.ruleCode}</span>
                <span className="text-left p-3 col-10">
                  {item.ruleDesc.substring(0, 150) + "..."}
                </span>
              </div>
            ))
          ) : (
            <div className="text-center p-3">
              <span>No Rules Available</span>
            </div>
          )}
        </CustomScroll>
      </div>
    </>
  );
};

export default RulesCodesList;
