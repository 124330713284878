import React from "react";
import NavigationButton from "../NavigationButton";
import Sidebar from "../Sidebar/Sidebar";
import { useLocation, useNavigate, useNavigation } from "react-router-dom";
import noImage from "../../assets/noImage.svg"
import yesImage from "../../assets/yesImage.svg"
import optionImg from "../../assets/questionMark.png"
const ConfirmReinvoice = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const reInvoice = location?.state?.reInvoice
  return (
    <Sidebar>
      <div className="container d-flex flex-column justify-content-center  h-100 ">
        <div className="d-flex flex-column justify-content-center align-items-center">
        <img height={100} width={100} alt=""  src={optionImg}/>
        <span className="mb-4">Create another invoice for this patient?</span>
        </div>
        <div className="responsive-flex">
          <NavigationButton
            header={"Yes"}
            color={"white"}
            
            handleClick={() =>  navigate("/invoicesteptwo",{state:{
                reInvoice
              }})}
            img={yesImage}
          />
          <NavigationButton
            handleClick={() => navigate(-1)}
            header={"No"}
            color={"white"}
            img={noImage}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default ConfirmReinvoice;
