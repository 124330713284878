// currencyFormatter.js
export const formatCurrency = (value) => {
    if (!value) return 'R 0.00';
    
    const number = parseFloat(value);
    return `R ${number.toLocaleString('en-ZA', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;
  };
  
  export const formatCurrencyWithoutZar = (value) => {
    if (!value) return '0.00';
    
    const number = parseFloat(value);
    return number.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };
