import React, { useState, useEffect } from "react";

const MedicalAidHighlights = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1024); // Adjust based on Bootstrap breakpoints
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const itemsToDisplay = isSmallScreen ? 2 : 4;

  return (
    <div className="d-flex flex-column p-3">
      <span className="text-white text-start mb-2 ml-1">
        MONTHLY HIGHLIGHTS
      </span>
      <div className="d-flex justify-content-center align-items-center medicalPadding">
        {[...Array(itemsToDisplay)].map((_, index) => (
          <div key={index} className="bg-white m-1 rounded p-1">
            <h4>12</h4>
            <small>Discovery Billing Submission</small>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MedicalAidHighlights;
