// Get the latest weight and height values from all selected procedure code
export const getLatestWeightAndHeight = (
    exceptionLineItems,
    selectedValues
  ) => {
    let latestWeight = "";
    let latestHeight = "";
    const weightCodes = ["0019", "0016", "0018"];
    if (Array.isArray(exceptionLineItems)) {
      exceptionLineItems
        .filter((item) => weightCodes.includes(item.code))
        .forEach((item) => {
          if (selectedValues && selectedValues[item.code]) {
            if (selectedValues[item.code].weight) {
              latestWeight = selectedValues[item.code].weight;
            }
            if (selectedValues[item.code].height) {
              latestHeight = selectedValues[item.code].height;
            }
          }
        });
    }
  
    return { latestWeight, latestHeight };
  };