import React, { useEffect, useState } from "react";
import SideNavbar from "./common/SideNavbar";
import NotesModal from "./ClinicalNote/ClinicalNoteModal";
import InvoiceAdjustmentService from "../services/Adjustment/AddInvoiceAdjustmentService";
import { useNavigate } from "react-router-dom";
import { formatDateWithLocale } from "../utils/dateUtils";
import SubTaskQueriesCommentService from "../services/Queries/SubTaskQueriesCommentService";
import { useAuth } from "../services/authentication/LoginService";
import SubTask from "./SubTask";
import SubTaskComments from "./SubTaskComments";
const AddInvoiceAdjustment = ({
  caseIDreferenceNumber = null,
  referenceNumber = null,
  invoiceAdjustmentClicked,
  closeFunc,
  taskID = null,
  adjustmentHistory = true,
  isBilling = false,
}) => {
  const { token } = useAuth();
  const [arrowClicked, setArrowClicked] = useState(false);
  const [noteText, setNoteText] = useState("");
  const navigate = useNavigate();
  const doctorNote = InvoiceAdjustmentService(token);
  const [adjustmentNoteModal, setAdjustmentNoteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedNote, setSelectedNote] = useState("");
  const [selectedSubtask, setSelectedSubtask] = useState(null);
  const toggleAdjustmentModal = (text, date) => {
    setAdjustmentNoteModal(true);
    setSelectedNote(text, date);
    setSelectedDate(date);
  };
  let documentType = 2; // Default to 1 if caseIDreferenceNumber is not empty
  const handlePress = async () => {
    try {
      setLoading(true);
      if (!noteText.trim()) {
        setIsEmpty(true);
        return;
      }
      setIsEmpty(false);
      if (isBilling) {
        const response = await doctorNote.handleAdjustmentInvoice({
          SubTaskActionID: 7,
          SubTaskInstruction: noteText,
          DocumentID: referenceNumber[0],
          DocumentType: documentType,
        });
        navigate("/confirmationpage", {
          state: {
            message: response.message,
            refNo: response?.parm_extra,
          },
        });
        const parmExtra = response?.parm_extra || "";
      } else {
        const response = await doctorNote.handleAdjustmentInvoice({
          SubTaskActionID: 7,
          SubTaskInstruction: noteText,
          DocumentID: referenceNumber,
          DocumentType: documentType,
        });
        navigate("/confirmationpage", {
          state: {
            message: response.message,
            refNo: response?.parm_extra,
          },
        });
        const parmExtra = response?.parm_extra || "";
      }
    } catch (error) {
      console.error("Error adding note:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (adjustmentHistory) {
      fetchComments(taskID);
    }
  }, []);

  const fetchComments = async (taskID) => {
    try {
      const service = SubTaskQueriesCommentService(token);

      const commentsData = await service.fetchSubTaskQueriesComment(taskID);
      const subTaskInstruction = commentsData.subtask.SubTaskInstruction || "";
      // Update state to include SubInstructionCreatedDate and comments
      setSelectedSubtask({
        subTaskCreatedDate: commentsData.subtask.SubTaskCreatedDate,
        instructions: subTaskInstruction,
        comments: commentsData.comments.map((comment) => ({
          commentDesc: comment.CommentDesc,
          commentDate: formatDateWithLocale(comment.CommentCreatedDate),
        })),
      });
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  return (
    <>
      <SideNavbar
        heading={"ADD INVOICE ADJUSTMENT NOTE"}
        notice={
          "Record and save patient encounter details on your device, securely stored in your clinical notes history for easy access and reference."
        }
        state={invoiceAdjustmentClicked}
        close={closeFunc}
        buttonText={
          loading ? (
            <i className="fas fa-spinner fa-spin mr-2"></i>
          ) : (
            "Save Note"
          )
        }
        proceed={handlePress}
      >
        {adjustmentHistory && (
          <div
            onClick={() => setArrowClicked(!arrowClicked)}
            className="d-flex light-gray-alt justify-content-between p-3 m-2 justify-content-center rounded pointer "
          >
            <div>
              <small className="bold">PREVIOUS ADJUSTMENT NOTES</small>
            </div>
            <div>
              <i
                className={` ${
                  arrowClicked ? "fa fa-chevron-down" : "fa fa-chevron-right"
                }`}
              ></i>
            </div>
          </div>
        )}
        {arrowClicked && (
          <div>
            {selectedSubtask?.subTaskCreatedDate ? (
              <div>
                <div
                  onClick={() =>
                    toggleAdjustmentModal(
                      selectedSubtask.instructions,
                      formatDateWithLocale(selectedSubtask.subTaskCreatedDate)
                    )
                  }
                  className="d-flex justify-content-between px-3 p-2 row "
                >
                  <div className="d-flex justify-content-center align-items-center col-3 ">
                    <span>
                      {formatDateWithLocale(selectedSubtask.subTaskCreatedDate)}
                    </span>
                  </div>

                  <SubTask selectedSubtask={selectedSubtask} />
                </div>

                <SubTaskComments
                  toggleAdjustmentModal={toggleAdjustmentModal}
                  selectedSubtask={selectedSubtask}
                />
              </div>
            ) : (
              <span>No Invoice Adjustments</span>
            )}
          </div>
        )}

        <div className="p-3">
          <small className="d-flex bold">ADD INVOICE AJUSTMENT NOTE</small>
          <textarea
            onChange={(e) => setNoteText(e.target.value)}
            placeholder=""
            className="clinical-input"
          />
        </div>
      </SideNavbar>
      {adjustmentNoteModal && (
        <NotesModal
          selectedDate={selectedDate}
          selectedNote={selectedNote}
          closeModal={() => setAdjustmentNoteModal(false)}
        />
      )}
    </>
  );
};

export default AddInvoiceAdjustment;
