import React from "react";
import NavigateBack from "../../NavigateBack";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";

const MoreInfoOnCode = () => {
  const location = useLocation();
  const { navigateBackText, heading, paragraph, code } = location?.state;
  return (
    <Sidebar>
      <div className="container">

        <NavigateBack text={navigateBackText} />


        <div className="d-flex mt-4 ml-2">
          <h4>{heading}:</h4>
          <h4 className="text-danger mx-1">{code}</h4>
        </div>
        <div className="mt-4 text-start ml-2">
          <span>{paragraph}</span>
        </div>
      </div>
    </Sidebar>
  );
};

export default MoreInfoOnCode;
