import { useFormikContext } from "formik";
import React, { useState } from "react";

const PatientsToRender = ({
  filteredPatientLists,
  patientLists,
  searchQuery,
  selectedPatients,
  setSelectedPatients,
}) => {
  const { setFieldValue } = useFormikContext();
  const [selectAll, setSelectAll] = useState(false);
  const patientsToRender =
    searchQuery.length > 0 ? filteredPatientLists : patientLists;
  const handleSelectAll = (checked, setFieldValue) => {
    setSelectAll(checked);
    if (checked) {
      // Select all patients
      patientsToRender.forEach((patient) => {
        if (
          !selectedPatients.some((p) => p.accNum === patient.AccountNo)
        ) {
          handleCheckboxChange(patient, true, setFieldValue);
        }
      });
    } else {
      // Deselect all patients
      patientsToRender.forEach((patient) => {
        handleCheckboxChange(patient, false, setFieldValue);
      });
    }
  };
  const handleCheckboxChange = (patient, newValue, setFieldValue) => {
    if (newValue) {
      setSelectedPatients((prev) => {
        const updatedList = [
          ...prev,
          { debtor_DbId: patient.DebtorID, patient_DbId: patient.PatientID,accNum:patient.AccountNo  },
        ];
        setFieldValue(
          "patients",
          updatedList.map((p) => p.accNum)
        );
        return updatedList;
      });
    } else {
      setSelectedPatients((prev) => {
        const updatedList = prev.filter(
          (p) => p.accNum !== patient.AccountNo
        );
        setFieldValue(
          "patients",
          updatedList.map((p) => p.accNum)
        );
        return updatedList;
      });
    }
  };
  return (
    <div className="selectedpatientscontainer">
      {/* Select All Checkbox */}
      {patientsToRender.length > 0 && (
        <div className="selectall">
          <input
            type="checkbox"
            checked={selectAll}
            onChange={(e) => handleSelectAll(e.target.checked, setFieldValue)}
            className="checkstyle"
          />
          <span className="fw-bold">SELECT ALL</span>
        </div>
      )}

      {/* First half of patients */}
      <div className="col-12 row">
        {patientsToRender
          .map((patient) => (
            <div
              // className="col-4"
              key={patient.AccountNo}
              className="col-md-4 col-12 d-flex p-3"
            >
           
              <input
                type="checkbox"
                checked={selectedPatients.some(
                  (p) => p.accNum === patient.AccountNo
                )}
                onChange={(e) =>
                  handleCheckboxChange(patient, e.target.checked, setFieldValue)
                }
                className="checkstyle"
              />
              <span>
                {patient.PatientName.toUpperCase()}{" "}
                {patient.PatientSurname.toUpperCase()} - {patient.AccountNo}
              </span>
            </div>
          ))}
      </div>
    </div>
  );
};

export default PatientsToRender;
