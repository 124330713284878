import React from 'react'

const ResourceCentre = () => {
  return ( //BG-YELLOW
    <div className='gray-color rounded p-2 mt-3'>
        <h5 className='text-white text-header  text-start'>YOUR GUIDANCE & RESOURCE CENTRE</h5>
        <div className='d-flex justify-content-center align-items-center'>
            <small className='text-white text-start'>
        Access expert advice, tutorials, FAQs, and more—all curated to enhance your Xpedient experience and guide your journey with precision and ease  
        </small>
        <img width={130} src={require("../../assets/ClientPortal/clientPortalCompass.png")} alt=''/>
        </div>
    </div>
  )
}

export default ResourceCentre