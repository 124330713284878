import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReportItem from "./ReportItem";
import Sidebar from "../../Sidebar/Sidebar";
import MonthlyReports from "./MonthlyReports";
import SavedReportsService from "../../../services/Reports/getSavedReports";
import { useAuth } from "../../../services/authentication/LoginService";
import GenericButton from "../../GenericButton";
import Loader from "../../../utils/Loader";
import GenerateReportSidebar from "./GenerateReportSidebar";
import useActiveHistoryReport from "../../state/ActiveHistoryReport";
import ClientPortalTabOptions from "./ClientPortalTabOptions";
const Reports = () => {
  
  const [generateReport, setGenerateReport] = useState(false);
  const { token } = useAuth();
  const [selectMonthly, setSelectMonthly] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { activeHistoryTab, setActiveHistoryTab, setActiveReportTab } = useActiveHistoryReport()
  const { getSavedReports } = SavedReportsService(token);

    useEffect(()=>{
      setActiveReportTab("r")
      },[]);
  const setReportGenerate = (value) => {
    setGenerateReport(value);
  };
  const getReports = async (type, activeTab) => {
    try {
      setLoading(true);
      setActiveHistoryTab(activeTab)
      const reports = await getSavedReports();
      let reportIndex;
      if (activeTab === "monthly"){
        reportIndex = reports?.SubDirectories.findIndex(item => 
          item?.DirectoryName?.includes("Monthly_Reports")
      )
      }else{
        reportIndex = reports?.SubDirectories.findIndex(item => 
          item?.DirectoryName?.includes("VAT_Reports")
      )
      }
      navigate("/savedreports", {
        state: {
          reports,
          reportType: type,
          reportIndex, //index of report in the result array
        },
      });
    } catch (e) {
      console.error("Failed to Fetch Reports");
      setActiveHistoryTab(null);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Sidebar heading={"Reports"}>
      <ClientPortalTabOptions/>
      <Loader loading={loading} />

      <div className="container">
        <div className="backbtnContainer bold-txt my-2"></div>
        {selectMonthly ? (
          <>
            <div className="d-flex">
              <button
                type="button"
                className="btn "
                onClick={() => setSelectMonthly(false)}
              >
                <i
                  style={{ color: "black" }}
                  className="fa fa-arrow-left"
                  aria-hidden="true"
                ></i>{" "}
                Back
              </button>
            </div>
            <MonthlyReports />
          </>
        ) : (
          <div>
        
            <span>
              Generate and access the latest report with up-to-date information
            </span>
            <div className="d-flex justify-content-center align-items-center">
              <GenericButton
                func={() => setGenerateReport(true)}
                text="GENERATE REPORT"
                textStyles="bold"
                styling="col-11 formButton"
              />
            </div>

            <div className="row border-bottom border-gray mt-4"></div>
            <div className="mt-4 mb-2">
              <span className="bold">VIEW HISTORY REPORTS</span>
            </div>
            <div className="row">
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"MONTHLY REPORTS"}
                  img={require("../../../assets/ClientPortal/MonthlyReports2.png")}
                  bgColor={activeHistoryTab === "monthly" ? "profileColor" : "bg-white"}
                  click={() => getReports("Monthly", "monthly")}
                  invertImage={activeHistoryTab === "monthly" && true}
                />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"VAT REPORTS"}
                  img={require("../../../assets/ClientPortal/VatReports.png")}
                  bgColor={activeHistoryTab === "vat" ? "profileColor" : "bg-white"}
                  click={() => getReports("VAT Reports", "vat")}
                  invertImage={activeHistoryTab === "vat" && true}
                />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"QR REPORTS"}
                  img={require("../../../assets/ClientPortal/qrReports.png")}
                  click={() => setActiveHistoryTab("qr")}
                  bgColor={activeHistoryTab === "qr" ? "profileColor" : "bg-white"}
                  invertImage={activeHistoryTab === "qr" && true}
                />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"ASSISTANT REPORTS"}
                  click={() => setActiveHistoryTab("assistant")}
                  img={require("../../../assets/ClientPortal/assistantReports.png")}
                  bgColor={activeHistoryTab === "assistant" ? "profileColor" : "bg-white"}
                  invertImage={activeHistoryTab === "assistant" && true}
                />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"ANALYSIS & RECOMMENDATIONS"}
                  click={() => setActiveHistoryTab("analysis")}
                  bgColor={activeHistoryTab === "analysis" ? "profileColor" : "bg-white"}
                  img={require("../../../assets/ClientPortal/analysisReccomendations.png")}
                  invertImage={activeHistoryTab === "analysis" && true}
                />
              </div>
              <div className="col-md-4 mb-4 d-flex align-items-center justify-content-center">
                <ReportItem
                  reportName={"CUSTOM REPORTS"}
                  bgColor={activeHistoryTab === "custom" ? "profileColor" : "bg-white"}
                  click={() => setActiveHistoryTab("custom")}
                  img={require("../../../assets/ClientPortal/customReport.png")}
                  invertImage={activeHistoryTab === "custom" && true}
                />
              </div>
            </div>
          </div>
        )}
        {/* SideNavbar for selecting report details */}
        <GenerateReportSidebar
          generateReport={generateReport}
          setGenerateReport={setReportGenerate}
        />
      </div>
    </Sidebar>
  );
};

export default Reports;
