import { create } from 'zustand';

const useClinicalNotePatient = create((set) => ({
    selectedPatient:"",
    isFound:false, 
    hideSearchBar:false,
    setHideSearchBar: (hideSearchBar) => set({ hideSearchBar }),
    setIsFound: (isFound) => set({ isFound }),
    setSelectedPatient: (selectedPatient) => set({ selectedPatient }),
    isPatientSelected:false, 
    setIsPatientSelected: (isPatientSelected) => set({ isPatientSelected }),
      
  }));
  


export default useClinicalNotePatient