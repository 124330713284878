import React, { useEffect, useState } from "react";
//import { useAuth } from '../../services/Authentication/AuthServices';
import Sidebar from "../Sidebar/Sidebar.js";
import "../../styles/components/login.scss";
import NavigationButton from "../NavigationButton.js";
import { useLocation, useNavigate } from "react-router-dom";
import AddInvoiceAdjustment from "../AddInvoiceAdjustment.js";
import useClearStores from "../../utils/clearStores.js";
import { ref } from "yup";
import { useContext } from "react";
import { UserContext } from "../../services/authentication/UserContext.js";

const ConfirmationPageInvoice = () => {
  const navigate = useNavigate();

  //const { token } = useAuth();
  const location = useLocation();
  const referenceNumber = location?.state?.referenceNumber || "";
  const { entityNumber } = useContext(UserContext);

  const [isAdjustmentSidebarOpen, setIsSidebarAdjustmentOpen] = useState(false);
  const clearStores = useClearStores();
  const {
    patientData,
    newManualPatientData,
    patientFullName,
    selectedICD10Codes,
    selectedProcedureCodes,
    lines,
    patient_name,
    selectedFileData,
    invoiceid,
  } = location?.state || "";
  // console.log("reference number", patientFullName)
  const handleSamePatient = async () => {
    navigate("/invoicesteptwo", {
      state: {
        patientData,
        newManualPatientData,
        patientFullName,
        selectedICD10Codes,
        patientName: patient_name,
        selectedProcedureCodes,
        lineItems: lines,
        selectedFileData,
      },
    });
  };
  const handleSamePatientEntityCond = async () => {
    navigate("/uploaddoc", {
      state: {
        patientData,
        newManualPatientData,
        patientFullName,
        selectedICD10Codes,
        patientName: patient_name,
        selectedProcedureCodes,
        lineItems: lines,
        selectedFileData,
      },
    });
  };

  return (
    <Sidebar>
      <div className="container">
        <div className="margintopsuccess">
          <div className="text-white d-flex align-items-center justify-content-center">
            <img
              width={100}
              height={100}
              style={{ objectFit: "contain" }}
              src={require("../../assets/success-green-check-mark-icon.png")}
              alt=""
            />
          </div>

          <div className="mt-4 mb-5 text-white d-flex align-items-center flex-column justify-content-center">
            <p style={{ color: "black" }}>
              Your invoice has been submitted:
            </p>
            <p style={{ fontWeight: "bold", color: "black" }}>
              Ref No: {referenceNumber}
            </p>
          </div>
          {!(invoiceid?.length > 1) && (
            <div>
              <button
                onClick={() => setIsSidebarAdjustmentOpen(true)}
                className="addadjustmentnote"
                //onClick={toggleSidebarAddAdjustmentNote}
              >
                <div className="text-white d-flex align-items-center justify-content-center">
                  <img
                    width={22}
                    height={22}
                    style={{ objectFit: "contain", filter: "invert(1)" }}
                    src={require("../../assets/icons/Add Clinical Note to Invoice Icon.png")}
                    alt=""
                  />
                  <span className="padleftt">
                    Submit an Invoice Adjustment Note
                  </span>
                </div>
              </button>
            </div>
          )}

          <div className="mt-5 text-white d-flex align-items-center justify-content-center">
            <p style={{ color: "black" }}>Submit Another Invoice</p>
          </div>

          <AddInvoiceAdjustment
            isBilling={true}
            referenceNumber={invoiceid}
            invoiceAdjustmentClicked={isAdjustmentSidebarOpen}
            closeFunc={() => setIsSidebarAdjustmentOpen(false)}
            adjustmentHistory={false}
          />

          <div className="responsive-flex">
            {entityNumber === "300" ? (
              <>
                <NavigationButton
                  header={"Same Patient"}
                  color={"white"}
                  img={require("../../assets/icons/New Patient Icon.png")}
                  handleClick={handleSamePatientEntityCond}
                />
                <NavigationButton
                  header={"New Patient"}
                  color={"white"}
                  img={require("../../assets/icons/New Patient Icon-1.png")}
                  route="uploaddoc"
                  handleClick={clearStores}
                />
              </>
            ) : (
              <>
                <NavigationButton
                  header={"Same Patient"}
                  color={"white"}
                  img={require("../../assets/icons/New Patient Icon.png")}
                  handleClick={handleSamePatient}
                />
                <NavigationButton
                  header={"New Patient"}
                  color={"white"}
                  img={require("../../assets/icons/New Patient Icon-1.png")}
                  route="patientsearch"
                  handleClick={clearStores}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ConfirmationPageInvoice;
