import React, { useState, useEffect, useCallback } from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavigationButton from "../NavigationButton";
import { useAuth } from "../../services/authentication/LoginService";
import "../../styles/components/ProcedureCodeSearch.scss";
import ICD10Service from "../../services/Billing/ICD10Service";
import { useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import GenericSearch from "../GenericSearch";
import CategoriesCodeList from "./ICD10CodeList/CategoriesCodeList";
import ChaptersCodeList from "./ICD10CodeList/ChaptersCodeList";
import GroupsCodeList from "./ICD10CodeList/GroupsCodeList";
import ConsultationsAndProcedures from "./C&PCodeList/C&PCodeList";
import ModifiersCodeList from "./C&PCodeList/ModifiersCodeList";
import useClearStores from "../../utils/clearStores";
import RulesCodesList from "./C&PCodeList/RulesCodesList";
import SearchedCategories from "./ICD10CodeList/SearchedCategories";
import Loader from "../../utils/Loader";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
function ProcedureCodeSearch() {
  const navigate = useNavigate();
  const clearStores = useClearStores();
  const [allICD10Data, setAllICD10Data] = useState([]);
  const [filteredICD10Data, setFilteredICD10Data] = useState([]);
  const [filteredICD10DataChapters, setFilteredICD10DataChapters] = useState(
    []
  );
  const { activeTab, setActiveTab, activeTopTab, setActiveTopTab } =
    useProcedureCodesStore();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filteredICD10DataGroups, setFilteredICD10DataGroups] = useState([]);
  const { token } = useAuth();
  const [currIndex, setCurrIndex] = useState(1);
  //Searching
  const [searchedData, setSearchedData] = useState([]);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [searchText, setSearchText] = useState("");
  const icd10Service = ICD10Service(token);
  const [firstSlice, setFirstSlice] = useState([]);
  const [secondSlice, setSecondSlice] = useState([]);
  const [combinedFilteredData, setCombinedFilteredData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    clearStores();
    setHasMounted(true);
  }, []);
  const handleCategoryClick = async (props) => {
    setLoading(true);
    try {
      const getCodeDetails = await icd10Service.getICD10Data(
        token,
        1,
        props[1]
      );

      if (getCodeDetails) {
        navigate("/morecategories", {
          state: {
            item: getCodeDetails,
          },
        });
      }
    } catch {
      return;
    } finally {
      setLoading(false);
    }
  };
  //Search Items
  const searchData = () => {
    if (searchText.length === 0) {
      setSearchPerformed(false);
      return;
    }
    const codeFilter = allICD10Data.filter((each) =>
      each.icd10Code.toLowerCase().includes(searchText.toLowerCase())
    );
    const filteredData =
      codeFilter.length > 0
        ? codeFilter
        : allICD10Data.filter((item) =>
            item.icd103Code_Desc
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
    setSearchedData(filteredData);
    setSearchPerformed(true);
  };
  const searchChapters = () => {
    const filteredDataChapters = allICD10Data.filter((item) =>
      item.chapterDesc.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredICD10DataChapters(filteredDataChapters);
  };
  const searchGroups = () => {
    const codeFilter = allICD10Data.filter((each) =>
      each.groupCode.includes(searchText)
    );
    const filteredDataGroups =
      codeFilter.length > 0
        ? codeFilter
        : allICD10Data.filter((item) =>
            item.groupDesc.toLowerCase().includes(searchText.toLowerCase())
          );

    setFilteredICD10DataGroups(filteredDataGroups);
  };
  useEffect(() => {
    setCombinedFilteredData(filteredICD10DataGroups);
  }, [filteredICD10DataGroups]);

  const filterUniqueGroups = (data) => {
    const uniqueGroups = [];
    const groupCodes = new Set();
    data.forEach((item) => {
      const groupCode = item.groupCode.replace(/\([^)]*\)/, "");
      if (!groupCodes.has(groupCode)) {
        uniqueGroups.push(item);
        groupCodes.add(groupCode);
      }
    });
    return uniqueGroups;
  };

  const handleFieldClick = (chapterDesc) => {
    navigate("/grouppagefromchapters", { state: { chapterDesc } });
  };
  const handleGroupClick = (groupDesc) => {
    navigate("/categoriesfromchapters", { state: { groupDesc } });
  };

  const filterUniqueChapters = (data) => {
    const uniqueChapters = [];
    const chapterNumbers = new Set();
    data.forEach((item) => {
      const chapterNo = item.chapterNo.replace("CHAPTER ", "");
      if (!chapterNumbers.has(chapterNo)) {
        uniqueChapters.push(item);
        chapterNumbers.add(chapterNo);
      }
    });

    return uniqueChapters;
  };

  const handleSearch = useCallback(
    debounce(() => {
      switch (activeTopTab) {
        case "Categories":
          searchData();
          break;
        case "Chapters":
          searchChapters();
          break;
        case "Groups":
          searchGroups();
          break;
        default:
          break;
      }
    }, 10),
    [searchText]
  ); // Adjust the delay as needed

  useEffect(() => {
    if (searchText.length === 0 && activeTopTab === "Categories") {
      setSearchPerformed(false);
      return;
    }
    if (hasMounted){
      handleSearch();
    }
  }, [searchText]);
  useEffect(() => {
    return () => {
      handleSearch.cancel();
    };
  }, [handleSearch]);
  useEffect(() => {
    const fetchICD10Codes = async () => {
      setLoading(true);
      try {
        const data = await icd10Service.ICD10DataAlt();
        if (data) {
          setAllICD10Data(data[0]);
          setFilteredICD10Data(data[0]);
          setFilteredICD10DataChapters(data[0]);
          setFilteredICD10DataGroups(data[0]);
          setCombinedFilteredData(data[0]);
          setFirstSlice(data[1][0]);
          setSecondSlice(data[1]);
        } else {
          setAllICD10Data([]);
          setFilteredICD10Data([]);
          setFilteredICD10DataChapters([]);

          setErrorMessage("Failed to fetch data");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch ICD-10 codes");
      } finally {
        setLoading(false);
      }
    };
    fetchICD10Codes();
  }, []);

  const fetchMore = async () => {
    setTimeout(() => {
      if (currIndex === 9) {
        setFirstSlice((prevItems) => [...prevItems, ...secondSlice[currIndex]]);
        setHasMore(false);
        return;
      }
      setFirstSlice((prevItems) => [...prevItems, ...secondSlice[currIndex]]);
      setCurrIndex((prev) => prev + 1);
    }, 1000);
  };
  return (
    <Sidebar heading={"ICD10 & PROCEDURE CODE SEARCH"}>
      <div className="d-flex justify-content-center py-4">
        <div
          onClick={() => {
            if (activeTab !== "icd10") {
              setActiveTab("icd10");
              setActiveTopTab("Categories");
            }
          }}
          className={`${
            activeTab === "icd10" ? "icd10Btn" : "icd10BtnDisabled"
          } pointer d-flex align-items-center justify-content-center`}
        >
          <span className="text-center">ICD 10</span>
        </div>

        <div
          onClick={() => {
            if (activeTab !== "procedureCode") {
              setActiveTab("procedureCode");
              setActiveTopTab("C&P");
            }
          }}
          className={`${
            activeTab === "procedureCode"
              ? "procedureCodeBtn"
              : "procedureCodeBtnDisabled"
          } pointer d-flex align-items-center justify-content-center`}
        >
          <span className="text-center">Consultations & Procedures</span>
        </div>
      </div>
      <Loader loading={loading} />
      <div className="container">
        {activeTab === "icd10" ? (
          <div className="responsive-flex mt-4 ">
            <NavigationButton
              handleClick={() => {
                setActiveTopTab("Categories")
                setSearchText("")
              }}
              header={"Categories"}
              color={"profileColor"}
            />
            <NavigationButton
              handleClick={() => {
                setFirstSlice(secondSlice[0]);
                setActiveTopTab("Chapters");
                setSearchText("")
              }}
              header={"Chapters"}
              color={"white"}
            />
            <NavigationButton
              handleClick={() => {
                setFirstSlice(secondSlice[0]);
                setActiveTopTab("Groups");
                setSearchText("")
              }}
              header={"Groups"}
              color={"gray"}
            />
          </div>
        ) : (
          <div className="responsive-flex mt-4">
            <NavigationButton
              handleClick={() => {
                setActiveTopTab("C&P");
              }}
              header={"Consultations & Procedures"}
              color={"profileColor"}
            />
            <NavigationButton
              handleClick={() => setActiveTopTab("Modifiers")}
              header={"Modifiers"}
              color={"white"}
            />
            <NavigationButton
              handleClick={() => setActiveTopTab("Rules")}
              header={"Rules"}
              color={"gray"}
            />
          </div>
        )}

        {activeTopTab !== "C&P" && //Search input for ICD10 Tab
          activeTopTab !== "Modifiers" &&
          activeTopTab !== "Rules" && (
            <GenericSearch
              handleChange={(event) => setSearchText(event.target.value)}
              values={searchText}
              placeholder={
                activeTopTab === "Categories"
                  ? "Search Categories"
                  : activeTopTab === "Chapters"
                  ? "Search Chapters"
                  : activeTopTab === "Groups"
                  ? "Search Groups"
                  : "Search"
              }
            />
          )}

        {activeTab === "icd10" ? (
          <>
            {activeTopTab === "Categories" ? (
              <div className="mt-4 search-padding">
                {searchPerformed ? (
                  searchedData.length > 0 ? (
                    <SearchedCategories
                      searchedData={searchedData}
                      handleCategoryClick={handleCategoryClick}
                    />
                  ) : (
                  <span>No Categories found</span>
                  )
                ) : (
                  <CategoriesCodeList
                    filteredICD10Data={filteredICD10Data}
                    firstSlice={firstSlice}
                    fetchMore={fetchMore}
                    hasMore={hasMore}
                    handleCategoryClick={handleCategoryClick}
                  />
                )}
              </div>
            ) : activeTopTab === "Chapters" ? (
              <div className="mt-4 search-padding">
                <ChaptersCodeList
                  filterUniqueChapters={filterUniqueChapters}
                  filteredICD10DataChapters={filteredICD10DataChapters}
                  handleFieldClick={handleFieldClick}
                />
              </div>
            ) : activeTopTab === "Groups" ? (
              <div className="mt-4 search-padding">
                <GroupsCodeList
                  filterUniqueGroups={filterUniqueGroups}
                  combinedFilteredData={combinedFilteredData}
                  handleGroupClick={handleGroupClick}
                />
              </div>
            ) : (
              <span>Invalid top tab</span>
            )}
          </>
        ) : activeTab === "procedureCode" ? (
          activeTopTab === "C&P" ? (
            <ConsultationsAndProcedures />
          ) : activeTopTab === "Modifiers" ? (
            <ModifiersCodeList />
          ) : activeTopTab === "Rules" ? (
            <RulesCodesList />
          ) : (
            <>
              <p>Loading</p>
            </>
          )
        ) : (
          <div>
            <p>No Content</p>
          </div>
        )}
      </div>
    </Sidebar>
  );
}

export default ProcedureCodeSearch;
