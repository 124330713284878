import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './services/authentication/LoginService';
import ProtectedRoute from './services/authentication/ProtectedRoute';
import { UserProvider } from "./services/authentication/UserContext";
import LoginPage from './screens/authentication/LoginPage';
import SignupPage from './screens/authentication/SignupPage';
import ForgotPasswordPage from './screens/authentication/ForgotPasswordPage';
import BillingHistory from './screens/patient/billingHistory';
import BillingSearch from './screens/patient/billingSearch';
import PatientDetailsOne from './screens/patient/PatientDetailsOne';
import RatesAndPolicies from './screens/ClientPortal/RatesAndPolicies';
import InvoiceHistory from './screens/InvoiceHistory/InvoiceHistory';
import PatientManualEntry from './screens/patient/ManualEntry/PatientManualEntry';
import ConfirmationPageNewPatient from './screens/ConfirmationPage/ConfirmationPageNewPatient';
import ClinicalNoteHistory from './screens/ClinicalNote/ClinicalNoteHistory';
import AddClinicalNote from './screens/ClinicalNote/AddClinicalNote';
import InvoiceStepTwo from './screens/Invoices/InvoiceStepTwo';
import GroupPageFromChapters from './screens/CodeSearch/GroupPageFromChapters';
import UploadDoc from './screens/UploadDoc';
import InvoiceHistoryView from './screens/InvoiceHistory/InvoiceHistoryView';
import BillingQueries from './screens/Queries/BillingQueries';
import MoreInfoOnCode from './screens/CodeSearch/C&PCodeList/MoreInfoOnCode';
import LoginConfirmation from './screens/ConfirmationPage/LoginConfirmation';
import BillingQueriesOpen from './screens/Queries/BillingQueriesOpen';
import BatchBilling from './screens/Billing/BatchBilling';
import PasswordConfirmationPage from './screens/ConfirmationPage/PasswordConfirmationPage';
import ReportDetailsPage from './screens/ClientPortal/Reports/ReportDetailsPage';
import SavedReports from './screens/ClientPortal/Reports/SavedReports';
import CodesPageFromChapters from './screens/CodeSearch/CodesPageFromChapters';
import MoreCategories from './screens/CodeSearch/MoreCategories';
import CategoriesPageFromChapters from './screens/CodeSearch/CategoriesPageFromChapters';
import MobileMessage from './screens/common/MobileMessage';
import ConfirmationPageInvoice from './screens/ConfirmationPage/ConfirmationPageInvoice';
import FavouriteHistoryScreen from './screens/Favourites/FavouriteHistoryScreen';
import AboutUsPage from './screens/AboutUs';
import ProcedureCodeSearch from './screens/CodeSearch/ProcedureCodeSearch';
import GeneralConfirmationPage from './screens/ConfirmationPage/GeneralConfirmationPage';
import FinancialDashboard from './screens/FinancialDashboard/FinancialDashboard';
import BatchBillingConfirmation from './screens/ConfirmationPage/BatchBillingConfirmation';
import ResetPassword from './screens/authentication/ResetPassword';
import CreatePasswordScreen from './screens/authentication/CreatePasswordScreen';
import ClientPortal from './screens/ClientPortal/ClientPortal';
import Reports from './screens/ClientPortal/Reports/Reports';
import MonthlyReports from './screens/ClientPortal/Reports/MonthlyReports';
import ReportDetails from './screens/ClientPortal/Reports/ReportDetails';
import NewsFeed from './screens/ClientPortal/NewsFeed';
import GuidanceAndResource from './screens/ClientPortal/GuidanceAndResource';
import XPUserLogin from './screens/authentication/XPUserLogin';
import TermsAndConditions from './screens/TermsAndConditions';
import ConfirmReinvoice from './screens/ConfirmationPage/ConfirmReinvoice';
const isMobileOrTablet = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  // Regex for mobile devices and tablets
  return /android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
};

function App() {
  const [showMobileMessage, setShowMobileMessage] = useState(false);
  const [isLoggedInFromMobile, setIsLoggedInFromMobile] = useState(false);

  const handleLoginSuccess = () => {

    if (isMobileOrTablet()) {
      setIsLoggedInFromMobile(true);
    }
  };

  useEffect(() => {
    setShowMobileMessage(false);
  }, [window.location.pathname]);

  return (
    <Router>
      <UserProvider>
        <AuthProvider>
          <div className="App">
            {isLoggedInFromMobile && showMobileMessage && <MobileMessage />}
            <Routes>

              <Route path="/" element={<LoginPage isMobile={isMobileOrTablet()} onLoginSuccess={handleLoginSuccess} />} />
              <Route path="/xpuserlogin" element={<XPUserLogin />} />
              <Route path="/signup" element={<SignupPage />} />
              <Route path="/T&Cs" element={<TermsAndConditions />} />
              <Route path="/forgotpassword" element={<ForgotPasswordPage />} />
              <Route path="/createpassword" element={<CreatePasswordScreen />} />
              <Route path="/patientdetailsone" element={<ProtectedRoute element={PatientDetailsOne} />} />
              <Route path="/clientportal" element={<ProtectedRoute element={ClientPortal} />} />
              <Route path="/batchbilling" element={<ProtectedRoute element={BatchBilling} />} />
              <Route path="/invoicehistory" element={<ProtectedRoute element={InvoiceHistory} />} />
              <Route path="/reinvoiceconfirmation" element={<ProtectedRoute element={ConfirmReinvoice} />} />

              <Route path="/invoicehistoryview" element={<ProtectedRoute element={InvoiceHistoryView} />} />
              <Route path="/patientsearch" element={<ProtectedRoute element={BillingSearch} mainRoute={"Billing"} />} />
              <Route path="/billinghistory" element={<ProtectedRoute element={BillingHistory} />} />
              <Route path="/ratesandpolicies" element={<ProtectedRoute element={RatesAndPolicies} />} />
              <Route path="/savedreports" element={<ProtectedRoute element={SavedReports} />} />
              <Route path="/newsfeed" element={<ProtectedRoute element={NewsFeed} />} />
              <Route path="/guidanceandresource" element={<ProtectedRoute element={GuidanceAndResource} />} />
              <Route path="/reports" element={<ProtectedRoute element={Reports} />} />
              <Route path="/reportDetails" element={<ProtectedRoute element={ReportDetails} />} />
              <Route path="/report-details-page" element={<ReportDetailsPage />} />
              <Route path="/confirmationpage" element={<ProtectedRoute element={GeneralConfirmationPage} />} />
              <Route path="/aboutus" element={<ProtectedRoute element={AboutUsPage} />} />
              <Route path="/invoicesteptwo" element={<ProtectedRoute element={InvoiceStepTwo} />} />
              <Route path="/uploaddoc" element={<ProtectedRoute element={UploadDoc} />} />
              <Route path="/passwordconfirmationpage" element={<PasswordConfirmationPage/>} />
              <Route path="/billingqueries" element={<ProtectedRoute element={BillingQueries} />} />
              <Route path="/clinicalnoteshistory" element={<ProtectedRoute element={ClinicalNoteHistory} />} />
              <Route path="/manualentry" element={<ProtectedRoute element={PatientManualEntry} />} />
              <Route path="/resetpassword" element={<ProtectedRoute element={ResetPassword} />} />
              <Route path="/confirmationpagenewpatient" element={<ProtectedRoute element={ConfirmationPageNewPatient} />} />
              <Route path="/addclinicalnote" element={<ProtectedRoute element={AddClinicalNote} />} />
              <Route path="/billingqueriesopen" element={<ProtectedRoute element={BillingQueriesOpen} />} />
              <Route path="/confirmationpageinvoice" element={<ProtectedRoute element={ConfirmationPageInvoice} />} />  
              <Route path="/batchbillingconfirmation" element={<ProtectedRoute element={BatchBillingConfirmation} />} />
              <Route path="/confirmlogin" element={<LoginConfirmation />} />
              <Route path='/favouritehistoryscreen' element={<FavouriteHistoryScreen />} />
              {/* <Route path='/addfavouritescreen' element={<AddFavouriteScreen />} /> */}
              <Route path='/procedurecodesearch' element={<ProcedureCodeSearch />} />
              <Route path='/morecategories' element={<MoreCategories />} />
              <Route path='/grouppagefromchapters' element={<GroupPageFromChapters />} />
              <Route path='/categoriesfromchapters' element={<CategoriesPageFromChapters />} />
              <Route path='/codepagefromchapters' element={<CodesPageFromChapters />} />
              <Route path='/moreinfocode' element={<MoreInfoOnCode />} />
              <Route path='/financedashboard'  element={ <ProtectedRoute mainRoute={'FinancialDashboard'} element={FinancialDashboard} />} />
            </Routes>
          </div>
        </AuthProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
