import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import NewsCarouselItem from './NewsCarouselItem';

function NewsItem({ loadingCallBack }) {
  const [rssItems, setRssItems] = useState([]);
  const [error, setError] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const decodeHtmlEntities = (html) => {
    const parser = new DOMParser();
    const decoded = parser.parseFromString(html, "text/html");
    return decoded.documentElement.textContent || "";
  };

  const parseXMLFeed = (xmlText) => {
    const parser = new DOMParser();
    const xml = parser.parseFromString(xmlText, "text/xml");
    
    if (xml.getElementsByTagName('parsererror').length > 0) {
      throw new Error('Error parsing XML');
    }

    const items = xml.querySelectorAll("item");
    const feedItems = Array.from(items).map((item) => {
      const title = item.querySelector("title")?.textContent || "";
      const description = item.querySelector("description")?.textContent || "";
      const link = item.querySelector("link")?.textContent || "";
      const date = item.querySelector("pubDate")?.textContent || "";
      const newDate = new Date(date);

      return {
        title: decodeHtmlEntities(title),
        description: decodeHtmlEntities(description).split("[…]")[0],
        link,
        date: newDate ? newDate.toISOString().split("T")[0] : ""
      };
    });

    if (feedItems.length === 0) {
      throw new Error('No items found in feed');
    }

    return feedItems;
  };

  const tryFetchRSS = async (proxyUrl, options = {}) => {
    const response = await fetch(proxyUrl, {
      headers: {
        'Accept': 'application/rss+xml, application/xml, text/xml',
        ...options.headers
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.text();
    return parseXMLFeed(data);
  };

  useEffect(() => {
    const fetchRSS = async () => {
      if (!initialized) {
        loadingCallBack(true);
        const RSS_URL = 'https://xpedient.co.za/feed/';
        
        // List of proxy services to try
        const proxyServices = [
          {
            url: `https://api.allorigins.win/raw?url=${encodeURIComponent(RSS_URL)}`,
            options: {}
          },
          {
            url: `https://corsproxy.io/?${encodeURIComponent(RSS_URL)}`,
            options: {}
          },
          {
            url: `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(RSS_URL)}`,
            options: {
              headers: { 'Accept': 'application/json' }
            },
            isJSON: true
          },
          {
            url: `https://proxy.cors.sh/${RSS_URL}`,
            options: {
              headers: {
                'x-cors-grida-api-key': 'YOUR_API_KEY_HERE' // Replace with your API key
              }
            }
          }
        ];

        let lastError = null;

        for (const service of proxyServices) {
          try {
            let feedItems;
            
            if (service.isJSON) {
              // Handle RSS2JSON service
              const response = await fetch(service.url, service.options);
              const jsonData = await response.json();
              
              if (jsonData.status === 'ok' && jsonData.items) {
                feedItems = jsonData.items.map(item => ({
                  title: item.title,
                  description: item.description,
                  link: item.link,
                  date: new Date(item.pubDate).toISOString().split("T")[0]
                }));
              } else {
                throw new Error('Invalid JSON feed data');
              }
            } else {
              // Handle XML-based services
              feedItems = await tryFetchRSS(service.url, service.options);
            }

            if (feedItems && feedItems.length > 0) {
              setRssItems(feedItems);
              setError(null);
              break;
            }
          } catch (err) {
            console.warn(`Proxy ${service.url} failed:`, err);
            lastError = err;
            continue;
          }
        }

        if (rssItems.length === 0 && lastError) {
          // Try JSONP as a last resort
          try {
            const script = document.createElement('script');
            const callbackName = 'jsonp_callback_' + Math.round(100000 * Math.random());
            
            const jsonpPromise = new Promise((resolve, reject) => {
              window[callbackName] = (data) => {
                if (data.status === 'ok' && data.items) {
                  resolve(data.items);
                } else {
                  reject(new Error('Invalid JSONP response'));
                }
              };
              
              setTimeout(() => reject(new Error('JSONP timeout')), 10000);
            });

            script.src = `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(RSS_URL)}&callback=${callbackName}`;
            document.body.appendChild(script);

            const items = await jsonpPromise;
            const feedItems = items.map(item => ({
              title: item.title,
              description: item.description,
              link: item.link,
              date: new Date(item.pubDate).toISOString().split("T")[0]
            }));

            setRssItems(feedItems);
            setError(null);
            
            // Cleanup
            document.body.removeChild(script);
            delete window[callbackName];
          } catch (jsonpError) {
            console.error('All fetching methods failed:', jsonpError);
            setError('Failed to load news feed. Please try again later.');
          }
        }

        loadingCallBack(false);
        setInitialized(true);
      }
    };

    fetchRSS();
  }, [loadingCallBack, initialized]);

  // Render a hidden placeholder with the same height to prevent layout shifts
  const placeholderHeight = "300px";

  return (
    <>
      {!initialized && (
        <div 
          className="bg-secondary rounded mt-3 p-4 text-center"
          style={{ height: placeholderHeight }}
        >
          <p>Loading news...</p>
        </div>
      )}
      
      <div 
        className="bg-secondary rounded mt-3"
        style={{ display: initialized ? 'block' : 'none' }}
      >
        {error ? (
          <div className="p-4 text-center">
            <p className="text-danger">{error}</p>
          </div>
        ) : rssItems.length > 0 ? (
          <Carousel
            indicators={true}
            pause={true}
            slide={true}
            interval={5000}
            controls={true}
          >
            {rssItems.map((item, index) => (
              <Carousel.Item key={`news-item-${index}`}>
                <NewsCarouselItem rssItem={item} />
              </Carousel.Item>
            ))}
          </Carousel>
        ) : null}
      </div>
    </>
  );
}

export default NewsItem;