import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavigationButton from "../NavigationButton";
import { useAuth } from "../../services/authentication/LoginService";
import { useLocation, useNavigate } from "react-router-dom";
import "../../styles/components/InvoiceHistory.scss";
import InvoiceHistoryDetailsService from "../../services/Invoice/invoiceHistoryDetailsService";
import { useRef } from "react";
import { exportToExcel } from "../../utils/excelUtils";
import BillingButtons from "../common/BillingButtons";
import InvoiceHistoryViewTable from "./InvoiceHistoryViewTable";
import DownloadInvoiceModal from "./DownloadInvoiceModal";
import NavigateBack from "../NavigateBack";
import AddInvoiceAdjustment from "../AddInvoiceAdjustment";
import AddClinicalNote from "../AddClinicalNote";
import Loader from "../../utils/Loader";
function InvoiceHistoryView() {

  const location = useLocation();
  const { token } = useAuth();
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const historyviewData = location?.state?.item;
  const referenceNumber = historyviewData && historyviewData?.InvoiceReference;
  const [downloadInvoiceModal, setDownloadInvoiceModal] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState([]);
  const targetRef = useRef();
  const [patientID, setPatientID] = useState(null);
  const [patientDbId,setPatientDbID] = useState(null)
  const [debtorDbId,setDebtorDbID] = useState(null)
  const [subTaskId,setSubTaskId] = useState("")
  const [clinicalNoteClicked, setClinicalNoteClicked] = useState(false);
  const [invoiceAdjustmentClicked, setInvoiceAdjustmentClicked] =useState(false);
  const [mergedData, setMergedData] = useState(null); 
  const [reinvoiceData,setReinvoiceData] = useState(null)
  const caseIDreferenceNumber = historyviewData?.CaseID || "";

  const invoiceAdjustmentClickedFunc = () => {
    if (invoiceAdjustmentClicked) {
      setInvoiceAdjustmentClicked(false);
    }
    setClinicalNoteClicked(false);
  };
  const clinicalNoteClickedFunc = () => {
    if (clinicalNoteClicked) {
      setClinicalNoteClicked(false);
    }
    setInvoiceAdjustmentClicked(false);
  };
  const toggleInvoiceAdjustment = () => {
    setInvoiceAdjustmentClicked(true);
    setClinicalNoteClicked(false);
  };
  const toggleClinicalNotes = () => {
    setClinicalNoteClicked(!clinicalNoteClicked);
    setInvoiceAdjustmentClicked(false);
  };
  const toggleDownloadInvoice = (toggle) => {
    setDownloadInvoiceModal(toggle);
  };

  const reInvoice = () => {
    navigate("/reinvoiceconfirmation",{state:{
        reInvoice:reinvoiceData
      }})
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);                                                                                                                                                     
      try {
        const invoiceDetailsResponse = await InvoiceHistoryDetailsService(
          token
        ).fetchInvoiceHistoryDetails(referenceNumber);
        // Check if status is equal to clinicalNoteClickedFunc
        if (invoiceDetailsResponse.status === 0) {
          const firstInvoice = invoiceDetailsResponse?.xpedientInvoiceDbDtl;
          if (firstInvoice) {
            const patientID = firstInvoice?.dbPatient?.PatientDbID || "";
            // const treatingDocs = firstInvoice?.dbDoctors?.map(doc => doc.DoctorName).join(", ") || "";
            const subtaskID = firstInvoice?.invoiceHeader?.SubTaskID || "";
            setPatientID(patientID);
            setDebtorDbID(firstInvoice?.dbDebtor?.DebtorSourceID)
            setPatientDbID(firstInvoice?.dbPatient?.PatientSourceID)
            setSubTaskId(subtaskID);
            setReinvoiceData(firstInvoice)
            setInvoiceDetails(firstInvoice?.invoiceLines || []);
            const doctorName = firstInvoice?.dbDoctors?.find(doctor => doctor?.DocType === "1")?.DoctorName || [];
            const mergedData = {
              ...historyviewData,  
              invoiceDoctors: doctorName,  
              invoiceCenters: firstInvoice?.dbCenter || [],  
              invoiceHeader: firstInvoice?.invoiceHeader || [],   
            };
            setMergedData(mergedData)
          } else {
            console.error(
              "Invalid format for invoice details:",
              invoiceDetailsResponse
            );
          }
        } else {
          console.error("Invalid status:", invoiceDetailsResponse.status);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  

  return (
    <Sidebar heading={"INVOICE HISTORY"}>

      {subTaskId && (
        <AddInvoiceAdjustment
        caseIDreferenceNumber={caseIDreferenceNumber}
        referenceNumber={referenceNumber}
        invoiceAdjustmentClicked={invoiceAdjustmentClicked}
        closeFunc={clinicalNoteClickedFunc}
        taskID={subTaskId}
        />
      )}
      <AddClinicalNote
        clinicalNoteClicked={clinicalNoteClicked}
        patientID={patientID}
        closeClinicalNote={invoiceAdjustmentClickedFunc}
        debtor_dbid={debtorDbId}
        patient_dbid={patientDbId}

      />
      <div className={`container mt-4`}>
        <NavigateBack
        text="INVOICE HISTORY"
        />
        <BillingButtons />

        <DownloadInvoiceModal
          reference={targetRef}
          visible={downloadInvoiceModal}
          toggleFunc={toggleDownloadInvoice}
        />
        <InvoiceHistoryViewTable
          reference={targetRef}
          historyviewData={historyviewData}
          invoiceDetails={invoiceDetails}
          mergedData={mergedData}
        />
        <Loader loading={loading}/>
        <div className="responsive-flex">     
            
          <NavigationButton
            header={"Invoice"}
            color={historyviewData?.InvoiceStatus === "Completed" ? "profileColor" : "gray"}
            invert={historyviewData?.InvoiceStatus === "Completed" ? true : false}
            handleClick={ historyviewData?.InvoiceStatus === "Completed" ? ()=>reInvoice() : ()=>{}}
            img={require("../../assets/icons/Invoice Icon.png")}
          />
          <NavigationButton
            handleClick={toggleInvoiceAdjustment}
            header={"Invoice Adjustments"}
            color={"profileColor"}
            img={require("../../assets/icons/Invoice Adjustments Icon.png")}
            invert={true}
          />
          <NavigationButton
            handleClick={toggleClinicalNotes}
            header={"Add Clinical Note"}
            color={"profileColor"}
            img={require("../../assets/icons/Invoice Adjustments Icon.png")}
            invert={true}
          />
          <div
            className="w-100 m-0 "
            onClick={() => toggleDownloadInvoice(true)}
          >
            <NavigationButton
              header={"Download"}
              color={"profileColor"}
              img={require("../../assets/icons/Upload Picture or Document Icon.png")}
              invert={true}
            />
          </div>
        </div>
      </div>
    </Sidebar>
  );
}

export default InvoiceHistoryView;
