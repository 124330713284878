import React from "react";
import "../../styles/components/ClientPortal.scss"
const NewsBlock = ({ heading, image, content, url }) => {
  return (
    <div className="d-flex flex-column newsItem w-75">
      <img alt="" src={image} style={{ objectFit: "contain"}} />
      
      <h5 className="text-danger text-start p-3 ">{heading}</h5>
      <small className="text-start p-3 text-secondary">{content}...</small>
      <div className="d-flex">
      <a href={url} target="_blank" rel="noopener noreferrer" className="text-danger text-start p-3 mt-3 pointer textDecoration">Read More 
       <i  className="fa fa-chevron-right ml-1 chevronSize"></i>
       <i className="fa fa-chevron-right chevronSize"></i>
      </a>
     
      </div>

    </div>
  );
};

export default NewsBlock;
