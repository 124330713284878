import React, { useEffect, useState } from "react";
import NewsBlock from "./NewsBlock";
import Sidebar from "../Sidebar/Sidebar";
import ClientPortalTabOptions from "./Reports/ClientPortalTabOptions";
import Loader from "../../utils/Loader";
import { Carousel } from "react-bootstrap";
import "../../styles/components/ClientPortal.scss"
import useWindowDimensions from "../../utils/screensize";
import useActiveHistoryReport from "../state/ActiveHistoryReport";

const NewsFeed = () => {
  const [rssItems, setRssItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setActiveReportTab } = useActiveHistoryReport();
  const { width } = useWindowDimensions();

  const decodeHtmlEntities = (html) => {
    const parser = new DOMParser();
    const decoded = parser.parseFromString(html, "text/html");
    return decoded.documentElement.textContent || "";
  };

  useEffect(() => {
    const fetchRSS = async () => {
      setActiveReportTab("nf");
      setLoading(true);

      const RSS_URL = 'https://xpedient.co.za/feed/';
      const proxyUrls = [
        `https://api.allorigins.win/raw?url=${encodeURIComponent(RSS_URL)}`,
        `https://thingproxy.freeboard.io/fetch/${RSS_URL}`,
        `https://api.codetabs.com/v1/proxy?quest=${RSS_URL}`,
        `https://corsproxy.io/?${encodeURIComponent(RSS_URL)}`
      ];

      let success = false;

      for (const proxyUrl of proxyUrls) {
        if (success) break;

        try {
          const response = await fetch(proxyUrl, {
            headers: {
              'Accept': 'application/rss+xml, application/xml, text/xml',
              'Cache-Control': 'no-cache'
            }
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const str = await response.text();
          const data = new window.DOMParser().parseFromString(str, "text/xml");

          // Check for parsing errors
          if (data.getElementsByTagName('parsererror').length > 0) {
            throw new Error('XML parsing error');
          }

          const items = data.querySelectorAll("item");
          const feedItems = Array.from(items).map((item) => {
            const title = item.querySelector("title")?.textContent || "";
            const description = item.querySelector("description")?.textContent || "";
            const link = item.querySelector("link")?.textContent || "";

            return {
              title: decodeHtmlEntities(title),
              description: decodeHtmlEntities(description).split("[…]")[0],
              link,
            };
          });

          if (feedItems.length > 0) {
            setRssItems(feedItems);
            success = true;
          } else {
            throw new Error('No items found in feed');
          }
        } catch (err) {
          console.warn(`Error with proxy ${proxyUrl}:`, err);
          // Continue to next proxy if this one fails
        }
      }

      setLoading(false);
    };

    fetchRSS();
  }, [setActiveReportTab]);

  // Function to slice the feedItems into chunks of 3
  const chunkedItems = [];
  for (let i = 0; i < rssItems.length; i += 3) {
    chunkedItems.push(rssItems.slice(i, i + 3));
  }

  return (
    <Sidebar>
      <ClientPortalTabOptions />
      <div className="container">
        <Loader loading={loading} />
        <div className="row">
          <div className="col-12">
            {rssItems.length > 0 && (
              <Carousel
                variant="dark"
                indicators={true}
                pause={true}
                slide={true}
                interval={null}
                controls={width > 1000}
              >
                {chunkedItems.map((chunk, index) => (
                  <Carousel.Item key={index}>
                    <div className="d-flex justify-content-between mb-5">
                      {chunk.map((item, subIndex) => (
                        <div key={subIndex} className="d-flex justify-content-center col-md-4">
                          <NewsBlock
                            heading={item.title}
                            content={item.description}
                            image={require("../../assets/ClientPortal/clientPortalNews.png")}
                            url={item.link}
                          />
                        </div>
                      ))}
                    </div>
                  </Carousel.Item>
                ))}
              </Carousel>
            )}
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default NewsFeed;