import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const InvoiceDetailsAccordian = ({ invoiceDetails }) => {
  const [isOpenTable, setIsOpenTable] = useState(false);
  const toggleAccordionChevronCodes = () => {
    setIsOpenTable(!isOpenTable);
  };
  return (
    <div
      className="code-table-bq mt-4 pointer"
      onClick={toggleAccordionChevronCodes}
      data-toggle="collapse"
      data-target="#collapseTwo"
      aria-expanded={isOpenTable}
      aria-controls="collapseTwo"
    >
      <div id="accordiontable" className="scrollcontainerparent">
        <div
          className="code-header d-flex justify-content-between align-items-center"
          id="headingOne"
        >
          <span className="generalmarg bold-txt">Invoice Details</span>
          <div className="icon-buttons">
            <FontAwesomeIcon
              icon={isOpenTable ? faChevronDown : faChevronRight}
              className="chevron-icon"
            />
          </div>
        </div>

        <div
          id="collapseTwo"
          className="collapse scrollcontainer"
          aria-labelledby="headingOne"
          data-parent="#accordiontable"
        >
          <div className="bgcolor card-body">
            <div className="d-flex mt-2 row">
              <div className="table-responsive">
                <table className="table rounded">
                  <thead className="code-header">
                    <tr className="bold-txt">
                      <th>Item</th>
                      <th>ICD-10 Code</th>
                      <th>Procedure Code</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceDetails.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.Diagnosis[0]}</td>
                        <td>{item.Code}</td>
                        <td>{item.LineNo}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetailsAccordian;
