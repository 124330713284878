import React, { useState, useEffect, useCallback } from "react";
import { ErrorMessage, Field, useFormikContext } from "formik";
import PatientGroupListService from "../../services/BatchBillingService/PatientGroupListService";
import { useAuth } from "../../services/authentication/LoginService";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore";

const GroupSelection = ({
  setSelectedPatients,
  selectedServCenterId,
  setPatientLists,
  setPatientListMessage,
  setSelectedGroupId,
  setFilteredPatientLists
}) => {
  const { setFieldValue } = useFormikContext();
  const { token } = useAuth();
  const [patientGroups, setPatientGroups] = useState([]);
  const [selectedGroupName, setSelectedGroupName] = useState("");
  const [isPatientGroupFocused, setIsPatientGroupFocused] = useState(false);
  const {
selectedServCenter
  } = useServiceCenterCodesStore();
  // Create service instance outside of any effects or callbacks
  const patientGroupListService = PatientGroupListService(token);

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setIsPatientGroupFocused(false);
    }
  };

  const handleFocus = () => {
    setIsPatientGroupFocused(true);
  };

  const handleGroupSelection = useCallback(async (group) => {
    if (!selectedServCenterId) {
      setPatientListMessage("Please select a service center first.");
      return;
    }
    setSelectedPatients([]);
    setSelectedGroupId(group.id);
    setSelectedGroupName(group.name);
    setIsPatientGroupFocused(false);
    setFieldValue("patientGroup", group.id);     
    // setFieldValue("patients",[])
    try {
      const response = await patientGroupListService.PatientListData(
        selectedServCenterId,
        group.id
      );


      if (Array.isArray(response)) {
        if (response.length === 0) {
          setPatientLists([]);
          setPatientListMessage("No patients found.");
        } else {
          setPatientLists(response);
          setPatientListMessage("");
        }
      } else if (response.message) {
        setPatientLists([]);
        setPatientListMessage(response.message);
      }
    } catch (error) {
      console.error("Failed to fetch patient lists:", error);
      setPatientLists([]);
      setPatientListMessage("Error fetching patient lists.");
    }
  }, [selectedServCenterId]);

  const fetchPatientGroups = useCallback(async () => {
    if (!selectedServCenterId) {
      setPatientGroups([]);
      return;
    }

    try {
      const response = await patientGroupListService.PatientGroupData(selectedServCenterId);
      
      if (Array.isArray(response)) {
        setPatientGroups(
          response.map((group) => ({
            name: group.GroupDesc,
            id: group.GroupID,
          }))
        );
      } else if (response.message) {
        console.error(response.message);
        setPatientGroups([]);
      }
    } catch (error) {
      console.error("Failed to fetch patient groups:", error);
      setPatientGroups([]);
    }
  }, [selectedServCenterId, patientGroupListService]);


  const resetGroups = () => {
    setSelectedGroupName("")
    setSelectedGroupId("")
    setFilteredPatientLists([])
    setPatientLists([])
    setSelectedPatients([])
    setFieldValue("patients",[])
  }
  useEffect(() => {
    resetGroups();
    fetchPatientGroups();
  }, [selectedServCenter]);

  return (
    <div className="col-12 col-lg">
      <label htmlFor="patientGroup">
        Patient Group<span className="error-text">*</span>
      </label>
      <div
        onBlur={handleBlur}
        tabIndex={-1}
        style={{ position: "relative" }}
      >
        <Field
          type="text"
          name="patientGroup"
          className="form-control"
          id="patientGroup"
          value={selectedGroupName ? selectedGroupName.toUpperCase() : ""}
          placeholder={selectedServCenterId ? "Select a Patient Group" : "Please select a service center first"}
          onFocus={handleFocus}
          readOnly
          disabled={!selectedServCenterId}
        />
        <ErrorMessage
          name="patientGroup"
          component="div"
          className="d-flex error-text"
        />
  
        {isPatientGroupFocused && selectedServCenterId && patientGroups.length > 0 && (
          <div className="patientGroupDropdown">
            <ul
              style={{
                listStyleType: "none",
                margin: 0,
                padding: 0,
              }}
            >
              {patientGroups.map((group,index) => (
                <li
                  key={index}
                  className="patientGroupItem"
                  onClick={() => handleGroupSelection(group)}
                >
                  {group.name}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default GroupSelection;