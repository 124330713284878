import React, { useContext, useEffect, useState } from "react";
import Sidebar from "../Sidebar/Sidebar";
import BoxItem from "./BoxItem";
import NewsItem from "./NewsItem";
import LatestReports from "./LatestReports";

import ResourceCentre from "./ResourceCentre";
import MonthlyHighlights from "./MonthlyHighlights";
import { useNavigate } from "react-router-dom";
import Contact from "./Contact";

import { useAuth } from "../../services/authentication/LoginService";
import vatReportService from "../../services/Reports/vatReportService";
import useClearStores from "../../utils/clearStores";
import Loader from "../../utils/Loader";
import { UserContext } from "../../services/authentication/UserContext";



const ClientPortal = () => {
  const navigate = useNavigate();
  const clearStores = useClearStores()
  const [loading,setLoading] = useState(false)
  useEffect(()=>{
    clearStores()
  },[])

  const setLoadingState = (state) => {
    setLoading(state)
  }
  const { customerAccessControl,email, password } = useContext(UserContext);

  return (
    <Sidebar heading={"YOUR SPECIALIST PRACTICE DASHBOARD"}>

      <div className="container mt-4">
        <div className="row">
          <Contact />
          <div className="col-md-6 col-12">
            <div>
              <NewsItem loadingCallBack={setLoadingState} />
            </div>
            {/* <div>
              <LatestReports />
            </div> */}
          </div>
        </div>
        <Loader loading={loading}/>
        <div className="row">
          <div className="col-lg-6 col-12">
            <div className="row">
              <div className="col-md-6 col-12 mt-1">
                <BoxItem
                  heading={""}
                  image={require("../../assets/ClientPortal/clientPortalRatesandPolicies.png")}
                  footer={"RATES & PRACTICE POLICIES"}
                  bgColor={customerAccessControl?.RatesAndPolicies ? "bg-blue" : "gray-color"} //bg-blue
                  // clickFunc={()=>navigate("/ratesandpolicies")}
                />
              </div>
              <div className="col-md-6 col-12 mt-1">
                <BoxItem
                  heading={""}
                  image={require("../../assets/ClientPortal/clientPortalReports.png")}
                  footer={"REPORTS"}
                  bgColor={customerAccessControl?.Reports ? "bg-green" : 'gray-color'} //bg-green
                  // bgColor={"gray-color"} 
                  clickFunc={()=>customerAccessControl?.Reports && navigate("/reports")}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-12 mt-1">
                <BoxItem
                  heading={"MY PRACTICE SCORE"}
                  image={require("../../assets/ClientPortal/clientPortalGrayStar.jpg")}
                  footer={"IMPROVE MY PRACTICE SCORE"}
                  stars={true}
                  bgColor={"gray-color"} //bg-blue
                />
              </div>
              <div className="col-md-6 col-12 mt-1">
                <BoxItem
                  heading={""}
                  image={require("../../assets/ClientPortal/queryBell.png")}
                  footer={"QUERIES"}
                  bgColor={customerAccessControl?.Queries ? "bg-red" : "gray-color"} //bg-red
                  queries={true}
                  clickFunc={() => customerAccessControl?.Queries && navigate("/billingqueries")}
                />
         
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-12">
            <ResourceCentre />
            <MonthlyHighlights />
          </div>
        </div>
      </div>
    </Sidebar>
  );
};

export default ClientPortal;
