// useModifiersStore.js
import { create } from "zustand";

const useModifiersStore = create((set) => ({
  showAssistant: false,
  nameSurname: "",
  searchTermSpeciality: "",
  fromTime: "",
  toTime: "",
  weight: "",
  height: "",
  times: {},
  filteredAssistants: [],
  manualAssistantName:"",
  manualAssistantSpeciality:"",
  setManualAssistantSpeciality: (manualAssistantSpeciality) => set({ manualAssistantSpeciality }),
  setManualAssistantName: (manualAssistantName) => set({ manualAssistantName }),
  setFilteredAssistant: (filteredAssistants) => set({ filteredAssistants }),
  selectedAssistant: null,
  selectedValues: {},
  setSelectedValues: (updatedKey, updatedValue) =>
    set((state) => {
      const currentValues = { ...state.selectedValues };
      currentValues[updatedKey] = {
        ...(currentValues[updatedKey] || {}),
        ...updatedValue,
      };
      return { selectedValues: currentValues };
    }),
  setTimes: (selectedCode, picker, selectedTime, setFieldValue) => {
    set((state) => {
      const updatedTimes = { ...state.times };
      const currentTimes = updatedTimes[selectedCode] || {
        fromTime: "",
        toTime: "",
      };

      if (picker === "from") {
        updatedTimes[selectedCode] = {
          ...currentTimes,
          fromTime: selectedTime,
        };
        setFieldValue("fromTime", selectedCode);
      } else if (picker === "to") {
        updatedTimes[selectedCode] = {
          ...currentTimes,
          toTime: selectedTime,
        };
        setFieldValue("toTime", selectedCode);
      }

      return { times: updatedTimes };
    });
  },
  setFromTime: (fromTime) => set({ fromTime }),
  setToTime: (toTime) => set({ toTime }),
  setWeight: (weight) => set({ weight }),
  setSelectedAssistant: (selectedAssistant) => set({ selectedAssistant }),
  setHeight: (height) => set({ height }),
  setSearchTermSpeciality: (searchTermSpeciality) =>
    set({ searchTermSpeciality }),
  setNameSurname: (nameSurname) => set({ nameSurname }),
  setShowAddAssistant: (showAssistant) => set({ showAssistant }),

  clearModifiersStore: () =>
    set({
      showAssistant: false,
      nameSurname: "",
      searchTermSpeciality: "",
      fromTime: "",
      toTime: "",
      weight: "",
      height: "",
      times: {},
      filteredAssistants: [],
      selectedAssistant: null,
      selectedValues: {},
    }),
}));

export default useModifiersStore;
