import React from "react";
import { getTrimmedUsername } from "../../utils/trimmedUsername";
import CustomScroll from "../common/CustomScroll";
const SubTaskComment = ({ subTasks, initials }) => {
  return (
    <>
      {subTasks.comments.map((comment) => (
        <div key={comment.CommentID} className={`d-flex flex-row  mt-4`}>
            {comment.CommentIsInternal === "1" ? (
              <div className="row col-12">
                <div className="profile-circle-internal col-3">
                  <img
                    src={require("../../assets/xp-icon-new.png")}
                    alt="Profile"
                    width={30}
                    height={30}
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="col-9" style={{ flex: 1, marginLeft: "10px" }}>
                  <small className="d-flex">{comment.CommentCreatedDate}</small>
                  <div className="subtaskcomment-box">
                    <p className="comment-desc">{comment.CommentDesc}</p>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row col-12">
                <div style={{ flex: 1 }} className="col-3">
                  <small className="d-flex text-secondary">
                    {comment.CommentCreatedDate}
                  </small>
                  <CustomScroll smallerScroll={true} maxHeight="20vh">
                  <div className="subtaskcomment-box">
                    <p className="comment-desc">{comment.CommentDesc}</p>
                  </div>
                  </CustomScroll>

                </div>
            
                <div className="profile-circle-external col-9">
                  <p className="profile-initials">{initials}</p>
                </div>
              </div>
            )}
        </div>
      ))}
    </>
  );
};

export default SubTaskComment;
