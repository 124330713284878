import specialtiesData from '../../assets/Codes/specialties.json';

const cacheKey = 'disciplineTypesdata';

const specialitiesService = () => {
  const loadFromCache = () => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
       // console.log('Loaded data from cache for discipline type');
        return JSON.parse(cachedData);
      }
    } catch (error) {
      console.error('Error loading from cache:', error);
    }
    return null;
  };

  const saveToCache = (data) => {
    try {
      localStorage.setItem(cacheKey, JSON.stringify(data));
     // console.log('Data cached for discipline type');
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  };

  const disciplineTypeData = async () => {
    try {
      const cachedData = loadFromCache();
      if (cachedData) {
        return cachedData;
      }

      if (specialtiesData && specialtiesData.specialty && specialtiesData.specialty.length > 0) {
        const newData = specialtiesData.specialty.map((item) => ({
          Specialty: item,
        }));

        saveToCache(newData);

        return newData;
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch discipline data. An error occurred:', error);
      throw error;
    }
  };

  return {
    disciplineTypeData,
  };
};

export default specialitiesService;
