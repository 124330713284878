import React, { useState } from "react";
import WeightPickerModal from "./ModalInvoice/WeightPickerModal";
import HeightPickerModal from "./ModalInvoice/HeightPickerModal";
import ModifierCode from "./ModifierCode";
import useModifiersStore from "../state/ModifiersStore";
import { useFormikContext } from "formik";
import TimeFromTo from "./TimeFromTo";
import Assistants from "./Assistants";
import useProcedureCodesStore from "../state/ProcedureCodeStore";

function Modifiers({
  exceptionLineItems,
  handleFavouriteQuantityChange,
  handleRemoveItem,
  weightCodes,
  assistantVals,
  onAssistantSelect
}) {
  const { setFieldValue, setFieldTouched, values, validateField } = useFormikContext();
  const { selectedProcedureCodes } = useProcedureCodesStore();
  const { times, setTimes, setSelectedValues } = useModifiersStore();
  const assistantCodes = ["0008", "0009", "0076", "0029"];
  const timeFromToCodes = [
    "0023",
    "0029",
    "0033",
    "0039",
    "0090",
    "1211",
    "0016",
    "0011",
    "0011A",
  ];

  const [modalWeightVisible, setModalWeightVisible] = useState(false);
  const [modalHeightVisible, setModalHeightVisible] = useState(false);
  const [selectedCode, setSelectedCode] = useState("");

  const toggleWeightModal = () => setModalWeightVisible(prev => !prev);
  const toggleHeightModal = () => setModalHeightVisible(prev => !prev);

  const handleSelectTimes = (selectedTime, selectedCode, picker, setFieldValue) => {
    setTimes(selectedCode, picker, selectedTime, setFieldValue);
  };

  const handleWeightSelect = (weight, setFieldValue) => {
    setFieldValue("weight", weight, true);
    setSelectedValues(selectedCode, { weight }); 
    toggleWeightModal();
  };

  const handleHeightSelect = (height, setFieldValue) => {
    setFieldValue("height", height, true);
    setSelectedValues(selectedCode, { height });
    toggleHeightModal();
  };

  const getModifierIndex = (item) => {
    return selectedProcedureCodes.findIndex(
      (prodCode) => item.code === prodCode.code
    );
  };

  // Updated handler for assistant selection
  const handleAssistantSelection = (code, assistant) => {
    // Update the assistants field for the specific code
    setFieldValue(`assistants.${code}`, assistant);
    
    // Update the validation status
    setFieldTouched(`assistants.${code}`, true, false);
    
    // Maintain a list of all selected assistants
    const currentAssistants = values.assistants || {};
    const updatedAssistants = {
      ...currentAssistants,
      [code]: assistant
    };
    setFieldValue('assistants', updatedAssistants);

    // Call the parent handler if it exists
    if (onAssistantSelect && typeof onAssistantSelect === 'function') {
      onAssistantSelect(code, assistant);
    }
  };

  return (
    <div>
      {exceptionLineItems.map((item) => (
        <div key={item.code} className="mb-4">
          <ModifierCode
            item={item}
            index={getModifierIndex(item)}
            handleFavouriteQuantityChange={handleFavouriteQuantityChange}
            handleRemoveItem={handleRemoveItem}
          />
          
          <div className="flex">
            <TimeFromTo
              timeFromToCodes={timeFromToCodes}
              item={item}
              handleSelectTimes={handleSelectTimes}
              times={times}
            />
          </div>
          {assistantCodes.includes(item.code) && (
          <Assistants
            item={item}
            assistantVals={assistantVals}
            onAssistantSelect={handleAssistantSelection}
            // Pass the current assistant value for this code
            value={values.assistants?.[item.code]}
          />
        )}
          {weightCodes.includes(item.code) && (
            <div>
              <WeightPickerModal
                isVisible={modalWeightVisible}
                onSelect={(weight) => handleWeightSelect(weight, setFieldValue)}
                onCancel={toggleWeightModal}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
              />

              {item.code === "0018" && (
                <HeightPickerModal
                  isVisible={modalHeightVisible}
                  onSelect={(height) => handleHeightSelect(height, setFieldValue)}
                  onCancel={toggleHeightModal}
                  setFieldValue={setFieldValue}
                  setFieldTouched={setFieldTouched}
                />
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default Modifiers;