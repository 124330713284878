import React, { useEffect } from 'react'
import GenericButton from '../../GenericButton'
import { generateReportFilename } from '../../../utils/reportFileName';
import { exportToExcel } from '../../../utils/excelUtils';
import { saveDocument } from '../../../services/Utils/saveDocument';
import { exportToBase64 } from '../../../utils/excelUtils';
import SaveReportService from '../../../services/Reports/SaveReportService';
import * as XLSX from 'xlsx';

import { useAuth } from '../../../services/authentication/LoginService';
const ExcelExporter = ({data,selectedDate}) => {
    const {token} = useAuth()
    const { saveReport } = SaveReportService(token);
    const saveReportDoc = async (base64String) => {
        const reportDoc = {
          FileName: "VatReport.xlsx",
          ContentType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          AttachmentData: base64String,
        };
        try{
          const response = await saveReport(selectedDate,4,reportDoc);
        }catch(err){
          console.log(err)
        }
      }
      const handleExport = (initial=false) => { //initial var to save excel file to api
        const wb = XLSX.utils.book_new();
        let taxDesc = ""
        let amountExcl = 0
        let amountVat = 0
        let totalInclVat = 0
        const ws = XLSX.utils.aoa_to_sheet([
          [
            "Entity No", 
            "Entity Name", 
            "Transaction Description", 
            "Ledger Description", 
            "VAT Percentage", 
            "VAT Description", 
            "Amount (Excl.)", 
            "Amount (VAT)", 
            "Total (Incl. VAT)"
          ],
          ...data.vat_report_data.flatMap((item,idx) => {
            const isLastIndex = idx === data.vat_report_data.length - 1;
            if (item.tax_flag_desc === taxDesc && !isLastIndex) { //add values for current tax desc
              taxDesc = item.tax_flag_desc;
              amountExcl +=  parseFloat(item.amount_excl)
              totalInclVat +=  parseFloat(item.amount_incl)
              amountVat += parseFloat(item.vat_amount)
              return [
                [
                  item.entity_no,
                  item.entity_name,
                  item.transaction_desc,
                  item.ledger_desc,
                  item.vat_perc,
                  item.vat_desc,
                  item.amount_excl,
                  item.vat_amount,
                  item.amount_incl
                ]
              ];
            } else {
              if (isLastIndex){ //last item
                amountExcl +=  parseFloat(item.amount_excl)
                totalInclVat +=  parseFloat(item.amount_incl)
                amountVat += parseFloat(item.vat_amount)
                let returnValue = [
                  [
                        item.entity_no,
                        item.entity_name,
                        item.transaction_desc,
                        item.ledger_desc,
                        item.vat_perc,
                        item.vat_desc,
                        item.amount_excl,
                        item.vat_amount,
                        item.amount_incl
                      ],
                      ["","","","","","",amountExcl,amountVat,totalInclVat],
                ]
                return returnValue
              }
              else if (!taxDesc){ //first item
                taxDesc = item.tax_flag_desc;
                amountExcl +=  parseFloat(item.amount_excl)
                totalInclVat +=  parseFloat(item.amount_incl)
                amountVat += parseFloat(item.vat_amount)
                return [
                  [item.tax_flag_desc],
                  [
                    item.entity_no,
                    item.entity_name,
                    item.transaction_desc,
                    item.ledger_desc,
                    item.vat_perc,
                    item.vat_desc,
                    item.amount_excl,
                    item.vat_amount,
                    item.amount_incl
                  ]
                ];
              }else{ //When there is a new tax description add new values
                
                taxDesc = item.tax_flag_desc;
    
                let returnValue = [
                  ["","","","","","",amountExcl,amountVat,totalInclVat],
                  [item.tax_flag_desc],
                  [
                    item.entity_no,
                    item.entity_name,
                    item.transaction_desc,
                    item.ledger_desc,
                    item.vat_perc,
                    item.vat_desc,
                    item.amount_excl,
                    item.vat_amount,
                    item.amount_incl
                  ]
                ]
                amountExcl = parseFloat(item.amount_excl)
                totalInclVat =  parseFloat(item.amount_incl)
                amountVat = parseFloat(item.vat_amount)
                return returnValue
    
              }
    
            }
          })
        ])

        XLSX.utils.book_append_sheet(wb, ws, "VAT Report", true);

        if (initial) {
          const excelFile = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
          const binaryString = String.fromCharCode.apply(null, new Uint8Array(excelFile));
          const base64ExcelData = btoa(binaryString); // Base64 encoded Excel file
          saveReportDoc(base64ExcelData);  // Send to your API
          return;
        }

        XLSX.writeFile(wb, "VATReport.xlsx");

      };
      useEffect(()=>{
        handleExport(true)
      },[])
  return (
    <GenericButton
    func={() => handleExport(false)}
    text="Download"
    bgColor={"bg-queryFilter"}
    styling="p-2 w-full rounded text-center"
  />
  )
}

export default ExcelExporter