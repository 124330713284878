import React, { useEffect } from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { getMonthName } from "../../../services/Utils/getMonthName";
import { saveDocument } from "../../../services/Utils/saveDocument";
import SaveReportService from "../../../services/Reports/SaveReportService";
import { useAuth } from "../../../services/authentication/LoginService";
import GenericButton from "../../GenericButton";
const drawVerticalBarGraph = (doc, data, startY, maxValue, width, height) => {
  const padding = { left: 50, right: 30, top: 20, bottom: 50 };
  const graphWidth = width - padding.left - padding.right;
  const graphHeight = height - padding.top - padding.bottom;
  const startX = padding.left;

  // Draw Y axis
  doc.line(
    startX,
    startY + padding.top,
    startX,
    startY + height - padding.bottom
  );

  // Draw X axis
  doc.line(
    startX,
    startY + height - padding.bottom,
    startX + graphWidth,
    startY + height - padding.bottom
  );

  // Group the data by month (every 2 items form a pair)
  const pairedData = [];
  for (let i = 0; i < data.length; i += 2) {
    if (i + 1 < data.length) {
      pairedData.push({
        turnover: data[i],
        receipting: data[i + 1],
        label: data[i].label, // The month label
      });
    }
  }

  // Calculate available space for all month pairs
  const totalPairs = pairedData.length;
  const pairWidth = Math.min(24, graphWidth / totalPairs);
  const barWidth = Math.min(5, pairWidth * 0.35);
  const barSpacing = Math.min(8, pairWidth * 0.3);
  const pairSpacing = Math.min(14, pairWidth * 0.3);

  const totalPairsSpace = pairWidth * totalPairs;
  const leftMargin = (graphWidth - totalPairsSpace) / 2;
  let xPos = startX + leftMargin;

  pairedData.forEach((pair, index) => {
    let turnoverHeight;
    let receiptingHeight;
    if (pair?.turnover?.value === 0 && maxValue === 0) {
      turnoverHeight = 0;
    } else {
      turnoverHeight = (pair?.turnover?.value / maxValue) * graphHeight;
    }
    if (pair?.receipting?.value === 0 && maxValue === 0) {
      receiptingHeight = 0;
    } else {
      receiptingHeight = (pair?.receipting?.value / maxValue) * graphHeight;
    }
    const pairCenterX = xPos + pairWidth / 2;
    doc.setFillColor(pair.turnover.frontColor || "#7999CC");
    doc.rect(
      xPos + (pairWidth - barWidth * 2 - barSpacing) / 2,
      startY + height - padding.bottom - turnoverHeight,
      barWidth,
      turnoverHeight,
      "F"
    );

    // Draw receipting bar
    doc.setFillColor(pair.receipting.frontColor || "#7660A9");
    doc.rect(
      xPos +
        (pairWidth - barWidth * 2 - barSpacing) / 2 +
        barWidth +
        barSpacing,
      startY + height - padding.bottom - receiptingHeight,
      barWidth,
      receiptingHeight,
      "F"
    );

    // Add value labels above bars
    doc.setFontSize(4);
    doc.text(
      `R${Math.round(pair.turnover.value).toLocaleString()}`,
      xPos + (pairWidth - barWidth * 2 - barSpacing) / 2 + barWidth / 2,
      startY + height - padding.bottom - turnoverHeight - 5,
      { align: "center" }
    );

    doc.text(
      `R${Math.round(pair.receipting.value).toLocaleString()}`,
      xPos +
        (pairWidth - barWidth * 2 - barSpacing) / 2 +
        barWidth +
        barSpacing +
        barWidth / 2,
      startY + height - padding.bottom - receiptingHeight - 5,
      { align: "center" }
    );

    if (pair.label) {
      doc.setFontSize(6);
      doc.text(pair.label, pairCenterX, startY + height - padding.bottom + 15, {
        align: "center",
      });
    }

    xPos += pairWidth + pairSpacing;
  });

  doc.setFontSize(6);
  const steps = 5;
  for (let i = 0; i <= steps; i++) {
    const value = (maxValue / steps) * i;
    const yPos = startY + height - padding.bottom - (i * graphHeight) / steps;
    doc.text(`R${Math.round(value).toLocaleString()}`, startX - 5, yPos, {
      align: "right",
    });
  }

  // Move legend to the bottom center
  const legendY = startY + height - padding.bottom + 30;
  const legendSquareSize = 10;
  const legendWidth = 150;
  const legendCenterX = startX + graphWidth / 2 - legendWidth / 2;

  doc.setFillColor("#7999CC");
  doc.rect(legendCenterX, legendY, legendSquareSize, legendSquareSize, "F");
  doc.setFontSize(6);
  doc.text(
    "Turnover",
    legendCenterX + legendSquareSize + 5,
    legendY + legendSquareSize - 2
  );

  // Receipting legend - position to the right of Turnover legend
  doc.setFillColor("#7660A9");
  doc.rect(
    legendCenterX + 80,
    legendY,
    legendSquareSize,
    legendSquareSize,
    "F"
  );
  doc.text(
    "Receipting",
    legendCenterX + 80 + legendSquareSize + 5,
    legendY + legendSquareSize - 2
  );
};

const drawHorizontalBarGraph = (
  doc,
  turnoverValue,
  receiptingValue,
  maxValue,
  startY
) => {
  const width = 180;
  const height = 60;
  const padding = { left: 40, right: 20, top: 10, bottom: 20 };
  const graphWidth = width - padding.left - padding.right;
  const barHeight = 12;
  const spacing = 10;
  const startX = padding.left;

  doc.line(
    startX,
    startY + padding.top,
    startX,
    startY + height - padding.bottom
  );
  doc.line(
    startX,
    startY + height - padding.bottom,
    startX + graphWidth,
    startY + height - padding.bottom
  );

  // Draw Turnover bar
  doc.setFillColor("#7999CC");
  let turnoverWidth;
  if (turnoverValue === 0 && maxValue === 0) {
    turnoverWidth = 0;
  } else {
    turnoverWidth = (turnoverValue / maxValue) * graphWidth;
  }
  doc.rect(startX, startY + padding.top, turnoverWidth, barHeight, "F");

  // Draw Receipting bar
  doc.setFillColor("#7660a9");
  let receiptingWidth;
  if (receiptingValue === 0 && maxValue === 0) {
    receiptingWidth = 0;
  } else {
    receiptingWidth = (receiptingValue / maxValue) * graphWidth;
  }
  doc.rect(
    startX,
    startY + padding.top + barHeight + spacing,
    receiptingWidth,
    barHeight,
    "F"
  );

  doc.setFontSize(6);
  doc.text("Turnover", startX - 5, startY + padding.top + barHeight / 2, {
    align: "right",
  });
  doc.text(
    "Receipting",
    startX - 5,
    startY + padding.top + barHeight * 1.5 + spacing,
    { align: "right" }
  );

  doc.text(
    `R${Math.round(turnoverValue).toLocaleString()}`,
    startX + turnoverWidth + 5,
    startY + padding.top + barHeight / 2
  );
  doc.text(
    `R${Math.round(receiptingValue).toLocaleString()}`,
    startX + receiptingWidth + 5,
    startY + padding.top + barHeight * 1.5 + spacing
  );

  doc.setFontSize(6);
  const steps = 4;
  for (let i = 0; i <= steps; i++) {
    const value = (maxValue / steps) * i;
    const xPos = startX + (graphWidth * i) / steps;
    doc.text(
      `R${Math.round(value).toLocaleString()}`,
      xPos,
      startY + height - padding.bottom + 15,
      { align: "center" }
    );
  }
};

const MonthlyPDFExporter = ({
  month,
  reports,
  ageAnalysis,
  ageAnalysisStatus,
  ledger,
  selectedDate,
}) => {
  const { token } = useAuth();
  const { saveReport } = SaveReportService(token);

  const saveReportDoc = async (base64String) => {
    const reportDoc = {
      FileName: "MonthlyReport.pdf",
      ContentType: "application/pdf",
      AttachmentData: base64String,
    };
    try {
      const response = await saveReport(selectedDate, 5, reportDoc);
    } catch (err) {
      console.log(err);
    }
  };
  // Helper function to format currency
  const formatCurrency = (value) => {
    if (!value) return "R0.00";
    const numValue = typeof value === "string" ? parseFloat(value) : value;
    return `R${numValue.toLocaleString("en-ZA", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`;
  };

  const generatePDF = (initial, isView = false) => {
    const doc = new jsPDF();
    let yOffset = 20;

    doc.setFontSize(6);

    doc.setFontSize(12);
    doc.text(`${getMonthName(month)} Reports`, 20, yOffset);
    yOffset += 15;

    doc.setFontSize(12);
    doc.text("Performance", 20, yOffset);
    yOffset += 8;

    doc.setFontSize(6);

    // Filter months (excluding Average and Total)
    const filteredReports = reports.map((report) => ({
      ...report,
      month_list: Object.fromEntries(
        Object.entries(report.month_list).filter(
          ([key]) => key !== "Average" && key !== "Total"
        )
      ),
    }));

    const months = filteredReports.length
      ? Object.keys(filteredReports[0].month_list)
      : [];

    // Create performance table data
    const performanceTableHead = [["Type", ...months]];
    const performanceTableBody = filteredReports.map((report) => [
      report.breakdown_type,
      ...months.map((month) =>
        typeof report.month_list[month] === "number"
          ? report.month_list[month].toLocaleString("en-ZA", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
          : report.month_list[month]
      ),
    ]);

    doc.autoTable({
      head: performanceTableHead,
      body: performanceTableBody,
      startY: yOffset,
      margin: { top: 10, left: 20, right: 20 },
      theme: "striped",
      styles: { fontSize: 8 },
      headStyles: { fontSize: 6, fontStyle: "bold" },
      bodyStyles: { fontSize: 6 },
      columnStyles: {
        0: { cellWidth: 20 },
      },
    });

    yOffset = doc.lastAutoTable.finalY + 15;

    // Calculate chart data
    const turnoverReport = reports.find((r) => r.breakdown_type === "Turnover");
    const receiptingReport = reports.find(
      (r) => r.breakdown_type === "Receipting"
    );

    const totalTurnover = Object.values(
      turnoverReport?.month_list || {}
    ).reduce((sum, val) => {
      const parsedVal = parseFloat(val);
      return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
    }, 0);

    const totalReceipting = Math.abs(
      Object.values(receiptingReport?.month_list || {}).reduce((sum, val) => {
        const parsedVal = parseFloat(val);
        return sum + (Number.isFinite(parsedVal) ? parsedVal : 0);
      }, 0)
    );

    doc.setFontSize(12);
    doc.text(
      "Monthly Performance Graph",
      doc.internal.pageSize.getWidth() / 2,
      yOffset,
      { align: "center" }
    );
    yOffset += 8;

    doc.setFontSize(6);

    const verticalChartData = months.flatMap((month) => {
      const turnoverValue = parseFloat(turnoverReport?.month_list[month]) || 0;
      const receiptingValue =
        parseFloat(receiptingReport?.month_list[month]) || 0;
      return [
        {
          value: turnoverValue,
          spacing: 10,
          label: month.slice(0, 3),
          frontColor: "#7999CC",
        },
        { value: Math.abs(receiptingValue), frontColor: "#7660A9" },
      ];
    });

    const maxVerticalValue = Math.max(...verticalChartData.map((d) => d.value));
    // Adjust width to use the full page width with margins
    const pageWidth = doc.internal.pageSize.getWidth();
    const chartWidth = pageWidth - 40; // 20px margin on each side
    drawVerticalBarGraph(
      doc,
      verticalChartData,
      yOffset,
      maxVerticalValue,
      chartWidth,
      90
    );
    yOffset += 100;

    doc.setFontSize(12);
    doc.text("Turnover vs Receipting Summary", 20, yOffset);
    yOffset += 8;

    doc.setFontSize(6);

    const maxHorizontalValue = Math.max(totalTurnover, totalReceipting) * 1.2;
    drawHorizontalBarGraph(
      doc,
      totalTurnover,
      totalReceipting,
      maxHorizontalValue,
      yOffset
    );
    yOffset += 70;

    [
      {
        title: "Age Analysis",
        head: [
          [
            "Age Range",
            "Total",
            "Current",
            "30 Days",
            "60 Days",
            "90 Days",
            "120 Days",
            "150 Days",
            "180+ Days",
          ],
        ],
        body: [
          [
            "Total",
            formatCurrency(ageAnalysis.total),
            formatCurrency(ageAnalysis.current),
            formatCurrency(ageAnalysis.days_30),
            formatCurrency(ageAnalysis.days_60),
            formatCurrency(ageAnalysis.days_90),
            formatCurrency(ageAnalysis.days_120),
            formatCurrency(ageAnalysis.days_150),
            formatCurrency(ageAnalysis.days_180_plus),
          ],
        ],
        columnStyles: { 0: { cellWidth: 20 } }, // Added smaller width for first column
      },
      {
        title: "Age Analysis by Status",
        head: [
          [
            "Status",
            "Total",
            "Current",
            "30 Days",
            "60 Days",
            "90 Days",
            "120 Days",
            "150 Days",
            "180+ Days",
          ],
        ],
        body: ageAnalysisStatus.map((status) => [
          status.account_status,
          formatCurrency(status.age_analysis.total),
          formatCurrency(status.age_analysis.current),
          formatCurrency(status.age_analysis.days_30),
          formatCurrency(status.age_analysis.days_60),
          formatCurrency(status.age_analysis.days_90),
          formatCurrency(status.age_analysis.days_120),
          formatCurrency(status.age_analysis.days_150),
          formatCurrency(status.age_analysis.days_180_plus),
        ]),
        columnStyles: { 0: { cellWidth: 20 } }, // Added smaller width for first column
      },
      {
        title: "Ledger",
        head: [
          ["Date", "Description", "Debit", "Credit", "Total", "Running Total"],
        ],
        body: ledger.map((entry) => [
          entry.date,
          entry.description,
          formatCurrency(entry.debit),
          formatCurrency(entry.credit),
          formatCurrency(entry.total),
          formatCurrency(entry.running_total),
        ]),
        columnStyles: { 0: { cellWidth: 20 }, 1: { cellWidth: 40 } },
      },
    ].forEach((section) => {
      // Section title with medium font
      doc.setFontSize(12);
      doc.text(section.title, 20, yOffset);
      yOffset += 8;

      doc.setFontSize(6);

      doc.autoTable({
        head: section.head,
        body: section.body,
        startY: yOffset,
        margin: { top: 10, left: 20, right: 20 },
        theme: "striped",
        styles: { fontSize: 6 },
        headStyles: { fontSize: 6, fontStyle: "bold" },
        bodyStyles: { fontSize: 6 },
        columnStyles: section.columnStyles || {},
      });

      yOffset = doc.lastAutoTable.finalY + 15;
    });

    if (initial) {
      const pdfOutput = doc.output("arraybuffer");
      const uint8Array = new Uint8Array(pdfOutput);
      let binaryString = "";
      for (let i = 0; i < uint8Array.length; i++) {
        binaryString += String.fromCharCode(uint8Array[i]);
      }
      const base64PdfData = btoa(binaryString);
      saveReportDoc(base64PdfData);
      // saveDocument(base64PdfData, "monthlyReport.pdf", "application/pdf");
      return;
    }
    if (isView) {
      const previewOutput = doc.output("bloburl");
      window.open(previewOutput, "_blank");
      return
    }
    doc.save(`Monthly_Report_${selectedDate}.pdf`);
  };
  useEffect(() => {
    generatePDF(true);
  }, []);
  return (
    <div className="d-flex ">
      <div className="w-100">
      <GenericButton
        func={() => generatePDF(false)}
        bgColor={"bg-queryFilter"}
        styling="p-2  rounded text-center"
        text="Download"
      />
      </div>
    <div className="w-100">
    <GenericButton
        func={()=>generatePDF(false,true)}
        text="View"
        bgColor={"bg-queryFilter"}
        styling="p-2  rounded text-center"
      />
    </div>
    </div>
  );
};

export default MonthlyPDFExporter;
