import React from 'react'
import { formatDate } from '../../utils/dateUtils'
import { useState,useEffect, useRef } from 'react';
import { getCurrentDate } from '../../utils/dateUtils'
import { getRandomColor } from '../../utils/pieChartColors'
import Chart from 'react-google-charts';    
import { exportToExcel } from '../../utils/excelUtils';
import AgeAnalysisService from '../../services/FinancialDashboard/AgeAnalysisService';
import { useAuth } from '../../services/authentication/LoginService';
import Loader from '../../utils/Loader';
import { exportWithChartImage, exportAgeAnalysisByStatus } from './ExportDocument';
import { formatCurrencyWithoutZar } from '../../services/Utils/formatCurrency';

const AgeAnalysisTab = () => {
    const {token} =useAuth()
    const chartRef = useRef(null);
    const ageAnalysiToken = AgeAnalysisService(token);
    const [selectedDate, setSelectedDate] = useState(getCurrentDate());
    const [dailyAnalysisData, setDailyAnalysisData] = useState([]);
    const [selectedDaily,setSelectedDaily] = useState(0);
    const [medicalAidChartData, setMedicalAidChartData] = useState([]);
    const [medicalAidAnalysisData, setMedicalAidAnalysisData] = useState([]);
    const [dataReady, setDataReady] = useState(false);
    const [statusAnalysisData, setStatusAnalysisData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [chartColors, setChartColors] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleExportWithChart = () => {
      exportWithChartImage(medicalAidAnalysisData, chartRef);
    };
    const handleDateChange = (e) => {
        const date = e.target.value;
        setSelectedDate(date);
      };

      useEffect(() => {
        handleViewAgeAnalysisClick();
      }, []);
        const handleViewAgeAnalysisClick = async () => {
          // const formattedDate = formatDate(selectedDate);
          const formattedDate = formatDate("2023-01-01");
        
          if (formattedDate) {
            setLoading(true);
            setDataReady(false);
            try {
              const fetchAge = await ageAnalysiToken.fetchAgeAnalysis(formattedDate);
              if (fetchAge) {
                
                // 1. General Age Analysis
                if (fetchAge.age_analysis) {
                  const mappedAnalysis = [
                    { heading: "Total", amount: fetchAge.age_analysis.total },
                    { heading: "Current", amount: fetchAge.age_analysis.current },
                    { heading: "30 Days", amount: fetchAge.age_analysis.days_30 },
                    { heading: "60 Days", amount: fetchAge.age_analysis.days_60 },
                    { heading: "90 Days", amount: fetchAge.age_analysis.days_90 },
                    { heading: "120 Days", amount: fetchAge.age_analysis.days_120 },
                    { heading: "150 Days", amount: fetchAge.age_analysis.days_150 },
                    { heading: "180+ Days", amount: fetchAge.age_analysis.days_180_plus },
                  ];
                  setDailyAnalysisData(mappedAnalysis);
                }
        
                // 2. Age Analysis by Status
                if (fetchAge.age_analysis_status && Array.isArray(fetchAge.age_analysis_status)) {
                  const mappedStatusAnalysis = {};
                  fetchAge.age_analysis_status.forEach((status) => {
                    mappedStatusAnalysis[status.account_status] = {
                      total: status.age_analysis.total,
                      current: status.age_analysis.current,
                      days_30: status.age_analysis.days_30,
                      days_60: status.age_analysis.days_60,
                      days_90: status.age_analysis.days_90,
                      days_120: status.age_analysis.days_120,
                      days_150: status.age_analysis.days_150,
                      days_180_plus: status.age_analysis.days_180_plus,
                    };
                  });
                  setStatusAnalysisData(mappedStatusAnalysis);
                } else {
                  console.error("Invalid or missing age analysis by status data.");
                }
                // 3. Age Analysis by Medical Aid
                if (fetchAge.age_analysis_medical_aid && Array.isArray(fetchAge.age_analysis_medical_aid)) {
                  const grandTotal = fetchAge.age_analysis.total;
        
                  const medicalAidData = fetchAge.age_analysis_medical_aid
                  .filter(medicalAid => 
                    medicalAid?.age_analysis && 
                    medicalAid.medical_aid.toLowerCase() !== 'grand total' 
                  )
                  .map((medicalAid) => {
                    const totalAmount = parseFloat(medicalAid.age_analysis.total) || 0;
                    const sum = fetchAge.age_analysis_medical_aid
                      .filter(item => 
                        item?.age_analysis && 
                        item.medical_aid.toLowerCase() !== 'grand total' 
                      )
                      .reduce((acc, curr) => acc + (parseFloat(curr.age_analysis.total) || 0), 0);
                    
                    const percentage = sum > 0 ? (totalAmount / sum) * 100 : 0;
                    
                    return {
                      name: medicalAid.medical_aid,
                      total: totalAmount,
                      percentage: percentage.toFixed(2),
                      color: medicalAid.color || getRandomColor()
                    };
                  });
        
                  setMedicalAidChartData(medicalAidData);
                  const chartData = [
                    ['Medical Aid', 'Total'],
                    ...medicalAidData.map((item) => [item.name, item.total]),
                  ];
        
                  const chartColors = medicalAidData.map((item) => item.color);
        
                  // Update medical aid analysis data (table data)
                  if (Array.isArray(fetchAge.age_analysis_medical_aid)) {
                    const mappedMedicalAidAnalysis = fetchAge.age_analysis_medical_aid
                      .map((medicalAid) => {
                        if (!medicalAid || !medicalAid.age_analysis) {
                          console.error("Medical aid or age analysis data is missing:", medicalAid);
                          return null;
                        }
        
                        return {
                          heading: medicalAid.medical_aid,
                          ...medicalAid.age_analysis
                        };
                      }).filter(Boolean);
                    
                    setMedicalAidAnalysisData(mappedMedicalAidAnalysis);
                  } else {
                    console.error("age_analysis_medical_aid is not an array:", fetchAge.age_analysis_medical_aid);
                  }
        
                  setChartData(chartData);
                  setChartColors(chartColors);
                }
              }
              setDataReady(true);
            } catch (error) {
              console.error("Error fetching age analysis data:", error);
            } finally {
              setLoading(false);
            }
          }
        };
        const handleExportAgeAnalysis = () => {
            const headers = [
              "Account Status",
              ...Object.keys(statusAnalysisData[Object.keys(statusAnalysisData)[0]]).map(key =>
                key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")
              )
            ];
        
            const data = Object.keys(statusAnalysisData).map(row => {
              const currentRowData = statusAnalysisData[row];
              return [
                row,
                ...Object.keys(currentRowData).map(col => currentRowData[col] !== undefined ? currentRowData[col] : "0.00")
              ];
            });
        
            const sheetData = {
              data: [headers, ...data],
              sheetName: "Age Analysis by Status"
            };
        
            exportToExcel([sheetData], "age_analysis_by_status.xlsx");
          };
  return (
    <div>
  
    <Loader loading={loading} />

    {dataReady && !loading && (
      <>
        <div className="bg-light mt-4">
          <div className="p-4">
            <h4 className="d-flex">Age Analysis</h4>
            <span className="d-flex">
              Age Analysis as at 17h30 for the previous business day.
            </span>
            <div className="row">
              {dailyAnalysisData.map((item, idx) => (
                <div
                  onClick={() => setSelectedDaily(idx)}
                  className={`${
                    selectedDaily === idx
                      ? "col-lg col-12 daily-item"
                      : "col-lg col-12 white-custom"
                  } bottom-tabs rounded pointer m-1 border-0`}
                  key={idx}
                >
                  <div>
                    <span
                      className={`${
                        selectedDaily === idx ? "text-white" : "text-dark"
                      }`}
                    >
                      {item.heading}
                    </span>
                  </div>
                  <span
                    className={`${
                      selectedDaily === idx ? "text-white" : "text-darkblue bold-color"
                    }`}
                  >
                    {formatCurrencyWithoutZar(item.amount)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mt-4">
        <div className="d-flex align-items-center mt-4 justify-content-between">
          <h4 className="bold-color">Age Analysis By Status</h4>
          <button onClick={handleExportAgeAnalysis} className="download-btn">
            <i className="fas fa-share-square"></i> <span>Export</span>
          </button>
        </div>

          <span className="d-flex">
            Age Analysis by status as at 17h30 for the previous business day.
          </span>
          {Object.keys(statusAnalysisData).length === 0 ? (
            <div className="text-center">No data available</div>
          ) : (
            <div className='table-responsive'>
            <table className="table">
              <thead>
                <tr>
                  <th>Account Status</th>
                  {Object.keys(
                    statusAnalysisData[Object.keys(statusAnalysisData)[0]]
                  ).map((key, index) => (
                    <th key={index}>
                      {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.keys(statusAnalysisData).map((row, rowIndex) => {
                  const currentRowData = statusAnalysisData[row];
                  return (
                    <tr key={rowIndex}>
                      <td>{row}</td>
                      {Object.keys(currentRowData).map((col, colIndex) => (
                        <td key={colIndex}>
                        {currentRowData[col] !== undefined
                          ? formatCurrencyWithoutZar(currentRowData[col])
                          : "0.00"}
                      </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            </div>
          )}
        </div>

        <div className="mt-4">
          <div >
            <div>
            <div className="d-flex align-items-center mt-4 justify-content-between">
              <h4 className="bold-color">Age Analysis By Medical Aid</h4>
              <button onClick={handleExportWithChart} className="download-btn">
                <i className="fas fa-share-square"></i> <span>Export</span>
              </button>
            </div>
          <span className="d-flex">
            Age Analysis by Medical Aid as at 17h30 for the previous business day.
          </span>
            </div>
            <div>
            </div>
          </div>
          <Chart
            chartType="PieChart"
            data={chartData}
            colors={chartColors}
            width={"100%"}
            height={"400px"}
            ref={chartRef}
            
          />
          {Object.keys(medicalAidAnalysisData).length === 0 ? (
            <div className="text-center">No data available</div>
          ) : (
            <div className='table-responsive'>
            <table className="table">
              <thead className="thead-light">
              <th>Medical Aid</th>
              {Object.keys(medicalAidAnalysisData[Object.keys(medicalAidAnalysisData)[0]])
                .slice(1)
                .map((key, index) => (
                  <th key={index}>{key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}</th>
              ))}
              </thead>
              <tbody>
                {Object.values(medicalAidAnalysisData).map((rowData, rowIndex) => (
                   <tr key={rowIndex}>
                   {Object.keys(rowData).map((col, colIndex) => (
                     <td key={colIndex}>
                       {rowData[col] !== undefined 
                         ? colIndex === 0 
                           ? rowData[col] 
                           : formatCurrencyWithoutZar(rowData[col]) 
                         : "0.00"} 
                     </td>
                   ))}
                 </tr>
                ))}
              </tbody>
            </table>
            </div>
          )}
        </div>
      </>
    )}
  </div>
  )
}

export default AgeAnalysisTab