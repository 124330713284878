import React from "react";
import { useState, useEffect } from "react";
import GenericSearch from "../../GenericSearch";
import CustomScroll from "../../common/CustomScroll";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useAuth } from "../../../services/authentication/LoginService";
import { useNavigate } from "react-router-dom";
import leadingZeroService from "../../../services/ProcedureCode/leadingZeroService";
import MoreInfoOnCode from "./MoreInfoOnCode";
const ConsultationsAndProcedures = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const [filteredProcedureData, setfilteredProcedureData] = useState([]);
  const [allProcedureData, setAllProcedureData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const leadingZeros = leadingZeroService(token);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasMounted, setHasMounted] = useState(false);
  const [loading, setLoading] = useState(false);

  const searchConsultations = () => {
    const codeFilter = allProcedureData.filter((each) =>
      each.code.includes(searchText)
    );

    // If nothing is found with code, then filter by description
    const filter =
      codeFilter.length > 0
        ? codeFilter
        : allProcedureData.filter((each) =>
            each.description.toLowerCase().includes(searchText.toLowerCase())
          );

    setfilteredProcedureData(filter);
  };

  useEffect(() => {
    if (hasMounted) {
      handleSearch();
    }
  }, [searchText]);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const handleSearch = useCallback(
    debounce(() => {
      searchConsultations();
    }, 10),
    [searchText]
  ); // Adjust the delay as needed
  useEffect(() => {
    const fetchProcedureCodes = async () => {
      setLoading(true);
      try {
        const data = await leadingZeros.ProcedureCodeData();
        if (data) {
          setAllProcedureData(data);
          setfilteredProcedureData(data);
        } else {
          setAllProcedureData([]);
          setfilteredProcedureData([]);
          setErrorMessage("Failed to fetch procedure codes");
        }
      } catch (error) {
        setErrorMessage("Failed to fetch procedure codes");
      } finally {
        setLoading(false);
      }
    };

    // if (activeTabbottom === "Consultations & Procedures") {
    fetchProcedureCodes();
    // }
  }, []);

  const navigateMore = (desc, code) => {
    navigate("/moreinfocode", {
      state: {
        paragraph: desc,
        code,
        heading: "Procedures",
        navigateBackText: "Consultations & Procedures",
      },
    });
  };

  return (
    <>
      <GenericSearch
        handleChange={(event) => setSearchText(event.target.value)}
        values={searchText}
        placeholder="Search C&P"
      />
      <div className="mt-4">
        <CustomScroll smallerScroll={true} maxHeight={"50vh"}>
          {filteredProcedureData.length > 0 ? (
            filteredProcedureData.map((item, index) => (
              <div
                className={`d-flex justify-content-between align-left rounded col-12 pointer ${
                  index % 2 !== 0 ? "lightgray" : ""
                }`}
                onClick={() => navigateMore(item.description, item.code)}
                key={index}
              >
                <span className="p-3 col-2">{item.code}</span>
                <span className="text-left p-3 col-10">{item.description}</span>
              </div>
            ))
          ) : (
            <div className="text-center p-3">
              <span>No Consultations and Procedures Available</span>
            </div>
          )}
        </CustomScroll>
      </div>
    </>
  );
};

export default ConsultationsAndProcedures;
