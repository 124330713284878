import rules from '../../assets/Codes/rules.json';

const cacheKey = 'rules';
const cacheVersion = 1; // Initial version number

const RulesService = () => {
  const loadFromCache = () => {
    try {
      const cachedData = localStorage.getItem(cacheKey);
      if (cachedData) {
        const parsedData = JSON.parse(cachedData);
        if (parsedData.version === cacheVersion) {
          // console.log('Loaded data from cache for rules code');
          return parsedData.data; // Return cached data if version matches
        } else {
          // console.log('Cache version mismatch, clearing cache');
          clearCache(); // Trigger cache invalidation if version differs
        }
      }
    } catch (error) {
      console.error('Error loading from cache:', error);
    }
    return null;
  };

  const saveToCache = (data) => {
    try {
      const dataToCache = { version: cacheVersion, data }; // Include version number in cached data
      localStorage.setItem(cacheKey, JSON.stringify(dataToCache));
      // console.log('Data cached for rules code');
    } catch (error) {
      console.error('Error saving to cache:', error);
    }
  };

  const clearCache = () => {
    try {
      localStorage.removeItem(cacheKey);
      console.log('Cache cleared for rules code');
    } catch (error) {
      console.error('Error clearing cache:', error);
    }
  };

  const RulesData = async () => {
    try {
      const cachedData = loadFromCache();
      if (cachedData) {
        return cachedData;
      }

      if (rules && rules.length > 0) {
        const newData = rules.map((item) => ({
          ruleCode: item.ruleCode,
          ruleDesc: item.ruleDescription
        }));

        saveToCache(newData);

        return newData;
      } else {
        console.error('Failed to fetch data. Data not available.');
        return [];
      }
    } catch (error) {
      console.error('Failed to fetch rules data. An error occurred:', error);
      throw error;
    }
  };

  return {
    RulesData,
    clearCache
  };
};

export default RulesService;
