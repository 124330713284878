import React from "react";
import { useNavigate } from "react-router-dom";

const NavigateBack = ({ text }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex justify-content-start align-items-start backbtnContainer bold-txt my-2 flex-column">
      <button type="button" className="btn " onClick={() => navigate(-1)}>
        <i
          style={{ color: "black" }}
          className="fa fa-arrow-left"
          aria-hidden="true"
        ></i>{" "}
        {text}
      </button>
    </div>
  );
};

export default NavigateBack;
