import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
///--services--
import { useAuth } from "../../services/authentication/LoginService";
import useProcedureCodesStore from "../state/ProcedureCodeStore.js";
import ProcessBillingRequestService from "../../services/Billing/ProcessBillingRequestService";
import ICD10 from "./ICD10Codes.js";
import AddClinicalNoteService from "../../services/ClinicalNotes/AddClinicalNoteService.js";
import { getLatestWeightAndHeight } from "../../utils/getLatestWeightAndHeight.js";
import TreatingDoctor from "./TreatingDoctor.js";
import ProcedureCode from "./ProcedureCodes.js";
import useServiceCenterCodesStore from "../state/ServiceCenterCodeStore.js";
import { useLocation } from "react-router-dom";
import { Toast, ToastContainer } from "react-bootstrap";
import NappiCodeSelector from "./NappiCodes.js";
import Sidebar from "../Sidebar/Sidebar.js";
import useModifiersStore from "../state/ModifiersStore.js";
import ServiceCenter from "./ServiceCenter.js";
import ReferringDoctor from "./ReferringDoctor.js";
import useTreatingDoctorStore from "../state/TreatingDoctorStore.js";
import specialties from "../../assets/Codes/specialties.json";
import ServiceDateField from "./ServiceDate.js";
import { useFavouritesService } from "./Constants/FavouritesConstants.js";
import useServiceDateStore from "../state/ServiceDateStore.js";
import AddButton from "./AddButton.js";
import NavigateBack from "../NavigateBack.js";
import AddClinicalNote from "../AddClinicalNote.js";
import useReferringDoctorStore from "../state/ReferringDoctorStore.js";
import BillingFavourites from "../BillingFavourites.js";
import MultipleDatePicker from "./MultipleDatePicker.js";
import GenericButton from "../GenericButton.js";
import useNappiCodeStore from "../state/NappiCodeStore.js";
import useICD10CodesStore from "../state/ICD10CodesStore.js";
import Loader from "../../utils/Loader.js";
import validationSchema from "./InvoiceValidationSchema.js";
// import "../../styles/components/invoice.scss";
function InvoiceStepTwo() {
  const navigate = useNavigate();
  const { token } = useAuth();
  const location = useLocation();
  const currentDate = new Date().toISOString();
  const [show, setShow] = useState(false);
  const [filteredSpecialties, setfilteredSpecialties] = useState(
    specialties.specialty
  );
  const addClinicalNoteService = AddClinicalNoteService(token);
  
  //DATA LOCATION
  const reInvoice = location?.state?.reInvoice;
  const selectedFileData = location?.state?.selectedFile;
  const patientFullName = location?.state?.patientFullName;
  const patientData = location?.state?.patientData;
  const newManualPatientData = location?.state?.newManualPatientData;
  const goodx_modified = location?.state?.goodx_modified;
  const patient_name = reInvoice ? reInvoice.dbPatient?.PatientName :  location?.state?.patientName;
  //Process Billing
  const processBillingRequestService = ProcessBillingRequestService(token);
  //------------------------GENERAL CONST -------------------------------//
  const [clinicalNote, setClinicalNote] = useState("");
  const [toggleClinicalNote, setToggleClinicalNote] = useState(false);
  const [hasSelectedProcedureCode, setHasSelectedProcedureCode] =
    useState(false);
  const [selectedCode, setSelectedCode] = useState("");
  const [selectedAssistants, setSelectedAssistants] = useState({});
  const uniqueAssistants = Object.values(selectedAssistants)


  const {
    selectedProcedureCodes,
    selectedICD10Codes,
    selectedNappiCodes,
    selectedRefDoc,
    modifier,
    setProcedureCodeFlatListVisible,
  } = useProcedureCodesStore();

  const { setNappiCodeFlatListVisible } = useNappiCodeStore();
  const { setIsICD10FlatListVisible } = useICD10CodesStore();
  const {
    selectedTreatingDoc,
    defaultTreatingDoc,
    defaultTreatingDocID,
    treatingDocData,
  } = useTreatingDoctorStore();
  const { dateToDisplay , setDateToDisplay,multiDates, setMultiDates } = useServiceDateStore();
  const {
    surnameAndName,
    isRefDocAdIconClicked,
    practiceNumber,
    specialityCode,
    setIsRefDocFlatListVisible,
  } = useReferringDoctorStore();
  const {
    serviceCenterData,
    selectedServCenter,
    servicecenterType,
    authorizationNumber,
    setIsServCenterFlatListVisible,
    isServCAdIconClicked,
    serviceCenterManualName,
  } = useServiceCenterCodesStore();
  const {
    showAssistant,
    searchTermSpeciality,
    nameSurname,
    toTime,
    fromTime,
    times,
    selectedAssistant,
    selectedValues,
    filteredAssistants,
    manualAssistantName,
    manualAssistantSpeciality
  } = useModifiersStore();

  //------------------------FAVOURITES ------------------------------------//
  const { isSidebarOpenAddFavourites, setIsSidebarOpenAddFavourites } =
    useFavouritesService();
  //------------------------------------- COMMON CALLS ----------------------------------//
  useEffect(() => {
    
    if (reInvoice){
      setMultiDates(reInvoice?.invoiceHeader?.InvoiceDate)
      setDateToDisplay(reInvoice?.invoiceHeader?.InvoiceDate)
    }
  }, []);
  useEffect(() => {
    const hasProcedureCode = selectedProcedureCodes.length > 0;
    setHasSelectedProcedureCode(hasProcedureCode);
  }, [selectedProcedureCodes]);

  const noteAdded = (text) => {
    setClinicalNote(text);
    setToggleClinicalNote(false);
    setShow(true);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scroll animation
    });
  };

  const toggleSidebar = (value) => {
    setIsSidebarOpenAddFavourites(value);
  };


  const [assistant, setAssistant] = useState(null);
  const [exceptionLineItems, setExceptionLineItems] = useState([]);  
  const { latestWeight, latestHeight } = getLatestWeightAndHeight(exceptionLineItems, selectedValues);
  const selectedWeight = selectedValues[selectedCode]?.weight || "";
  const selectedHeight = selectedValues[selectedCode]?.height || "";
  

  const toggleClinicalNoteSidebar = () => {
    setToggleClinicalNote(true);
    if (isSidebarOpenAddFavourites) {
      setIsSidebarOpenAddFavourites(false);
    }
  };
  const toggleSidebarAddToFavourites = () => {
    setIsSidebarOpenAddFavourites(true);
    setToggleClinicalNote(false);
  };
  const handleClinicalNoteSend = async () => {
    try {
      const result = await addClinicalNoteService.handleClinicalNote({
        Note: clinicalNote,
        PatientIdNo: patientData?.id || newManualPatientData?.patientID || "",
        GoodXDebtorId:  location?.state?.patientData?.debtor_dbid,
        GoodXPatinetId: location?.state?.patientData?.patient_dbid,
      });
      return result;
    } catch (error) {
      throw error;
    }
  };
  const closeFlatlists = () => {
    setIsServCenterFlatListVisible(false);
    setNappiCodeFlatListVisible(false);
    setIsICD10FlatListVisible(false);
    setProcedureCodeFlatListVisible(false);
    setIsRefDocFlatListVisible(false);
  };
  const schema = validationSchema(
    serviceCenterData, treatingDocData, 
    filteredSpecialties, filteredAssistants, showAssistant,isServCAdIconClicked,
    isRefDocAdIconClicked,times,selectedProcedureCodes
  );
  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      setSubmitting(true);
      const {
        selectedICD10Codes,
        serviceDate,
        assistantList,
      } = values;
      const codes = selectedProcedureCodes
        .map((code, index) => ({
          line_nr: index + 1 + (reInvoice?.invoiceLines?.length || 0),
          code: code.code,
          quantity: code.quantity,
          diagnosis: selectedICD10Codes
            .map((icd10) => icd10.icd10Code)
            .join(","),
          administered_timestamp: currentDate?.toString() || "",
          description: code.description,
        }))
        .filter((code) => code.code !== undefined);

      const lines = [];
      const mergedLineItems = [...codes];
      mergedLineItems.forEach((item, procedureIndex) => {
        const { fromTime: lineFromTime, toTime: lineToTime } = times[
          item.code
        ] || { fromTime: "", toTime: "" };
        const assistant = selectedAssistants[item.code];
        const procedureLine = {
          line_nr: lines.length + 1,
          procedure_or_nappi_code: item.code,
          quantity: item.quantity,
          icd10_codes: selectedICD10Codes.map((icd10) => icd10.icd10Code),
          assitant_doc_council_no: assistant ? [assistant.council_nr] : [],
          procedure_service_date: serviceDate,
          procedure_start_time: lineFromTime || fromTime || "",
          procedure_end_time: lineToTime || toTime || "",
          procedure_description: item.description,
          auth_no: authorizationNumber || "",
          favourite: false,
        };
        lines.push(procedureLine);
      });

      for (
        let procedureIndex = 0;
        procedureIndex < selectedNappiCodes.length;
        procedureIndex++
      ) {
        const nappiLine = {
          line_nr: lines.length + 1,
          procedure_or_nappi_code: selectedNappiCodes[procedureIndex].code,
          quantity: selectedNappiCodes[procedureIndex].quantity,
          icd10_codes: selectedICD10Codes
            .map((icd10) => icd10.icd10Code)
            .join(","),
          procedure_service_date: serviceDate,
          procedure_description: selectedNappiCodes[procedureIndex].name, //nappi code desc
          auth_no: authorizationNumber || "",
          favourite: false,
        };
        lines.push(nappiLine);
      }
      const invoiceDetails = {
        debtor: {
          debtor_dbid: "",
       
          debtor_source_id: patientData?.debtor_dbid || reInvoice?.dbDebtor.DebtorSourceID || "",
          debtor_modified: false,
          debtor_title: "",
          debtor_initials: "",
          debtor_name: "",
          debtor_surname: "",
          debtor_idnumber: "",
          debtor_idtype: 0,
          debtor_vatnr: "",
          debtor_gender: "",
          debtor_language: "",
          debtor_dateofbirth: "",
          debtor_weight_kg: 0,
          debtor_height_cm: 0,
          debtor_nextofkin_name: "",
          debtor_nextofkin_cellnr: "",
          debtor_nextofkin_email: "",
          debtor_ma_schemename: "",
          debtor_ma_schemecode: "",
          debtor_ma_optionname: "",
          debtor_ma_optioncode: "",
          debtor_ma_number: "",
          debtorContactDetails: {
            email_address: "",
            telephone: "",
            cell_number: "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
        },
        patient: {
          is_debtor: newManualPatientData?.patientID ? true : false,
          patient_dbid: "",
          patient_source_id: patientData?.patient_dbid || reInvoice?.dbPatient.PatientSourceID  || "",
         
          patient_modified: goodx_modified || "",
          patient_auth: "",
          patient_title: "",
          patient_initials: "",
          patient_name:
            newManualPatientData?.patientName || patientData?.name  || "",
          patient_surname:
            newManualPatientData?.patientSurname || patientData?.surname || "",
          patient_fullname: patientFullName || "",
          patient_idnumber:
            newManualPatientData?.patientID || patientData?.id || reInvoice?.dbPatient.PatientIdNumber || "",
          patient_type: "",
          patient_dateofbirth:
            newManualPatientData?.patientDOB || patientData?.dob || "",
          patient_gender: "",
          patient_weight_kg: latestWeight || selectedWeight || 0,
          patient_height_meter: latestHeight || selectedHeight || 0,
          patient_nextofkin_name:
            patientData?.nextOfKinName || newManualPatientData?.nextofkin || "",
          patientNextofkinContactDetails: {
            email_address: "",
            telephone:
              patientData?.nextOfKinNumber ||
              newManualPatientData?.nextOfKinContact ||
              "",
            cell_number:
              patientData?.nextOfKinNumber ||
              newManualPatientData?.nextOfKinContact ||
              "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
          patient_ma_schemename:
            newManualPatientData?.medicalAidSelectFromList ||
            patientData?.medical_aid_option ||
            "",
          patient_ma_schemecode: "",
          patient_ma_optionname:
            newManualPatientData?.selectPlan ||
            patientData?.medical_aid_plan ||
            "",
          patient_ma_optioncode: "",
          patient_ma_number:
            newManualPatientData?.medicalAidNumber ||
            patientData?.medical_aid_number ||
            "",
          patientContactDetails: {
            email_address:
              patientData?.patient_email || newManualPatientData?.email || "",
            telephone: patientData?.modifiedFields?.patientContactNumber
              ? patientData?.contact_number
              : newManualPatientData?.contactNumber || "",
            cell_number: patientData?.modifiedFields?.patientContactNumber
              ? patientData?.contact_number
              : newManualPatientData?.contactNumber || "",
            fax_number: "",
            address_line1: patientData?.address
              ? patientData?.address
              : newManualPatientData?.patientAddress
              ? newManualPatientData?.patientAddress
              : "",
            address_line2: patientData?.address
              ? patientData?.address
              : newManualPatientData?.patientAddress
              ? newManualPatientData?.patientAddress
              : "",
            address_line3: patientData?.address
              ? patientData?.address
              : newManualPatientData?.patientAddress
              ? newManualPatientData?.patientAddress
              : "",
            postcode: "",
          },
          Attachments: selectedFileData ? selectedFileData : [],
        },
        doctors: [
          {
            doctor_dbid: "",
            doctor_source_id:
              defaultTreatingDocID?.toString() || //DEFAULT TREATING DOCTOR ID
              selectedTreatingDoc?.id || //IF USER SELECTS A TREATING DOCTOR ID
              reInvoice?.dbDoctors?.[0]?.DoctorSourceID || //REINVOICE TREATING DOCTOR ID
              "",
            doc_type: 1,
            title: "",
            initials: "",
            name: "",
            qualification: "",
            own_code: "",
            speciality_code: "",
            council_nr: "",
            practice_nr: "",
            doctorContactDetails: {
              email_address: "",
              telephone: "",
              cell_number: "",
              fax_number: "",
              address_line1: "",
              address_line2: "",
              address_line3: "",
              postcode: "",
            },
          },
          ...[
            // Referring doctor (conditional)
            selectedRefDoc?.id || (surnameAndName && specialityCode) ? {
              doctor_dbid: "",
              doctor_source_id: selectedRefDoc?.id?.toString() || "",
              doc_type: 2,
              title: selectedRefDoc?.title || "",
              initials: "",
              name: surnameAndName || selectedRefDoc?.name || "",
              qualification: "",
              own_code: "",
              speciality_code: specialityCode || selectedRefDoc?.speciality_code || "",
              council_nr: selectedRefDoc?.council_nr || practiceNumber || "",
              practice_nr: practiceNumber || selectedRefDoc?.practice_nr || "",
              doctorContactDetails: {
                email_address: "",
                telephone: "",
                cell_number: "",
                fax_number: "",
                address_line1: "",
                address_line2: "",
                address_line3: "",
                postcode: "",
              },
            } : null,
            // Assistant doctor (conditional)
           // Assistant doctor (conditional)
           ...uniqueAssistants.map(assistant => ({
            doctor_dbid: assistant.id || "",
            doctor_source_id: assistant.council_nr || "",
            doc_type: 3, // Doc Type = Assistant
            title: "",
            initials: "", 
            name: assistant.name || "",
            qualification: "",
            own_code: "",
            speciality_code: assistant.speciality_code || "",
            council_nr: assistant.council_nr || "",
            practice_nr: assistant.practice_nr || "",
            doctorContactDetails: {
              email_address: "",
              telephone: "",
              cell_number: "",
              fax_number: "",
              address_line1: "",
              address_line2: "", 
              address_line3: "",
              postcode: "",
            }
          })),
          ...(manualAssistantName
            ? [
                {
                  doctor_dbid: "",
                  doctor_source_id: "",
                  doc_type: 3, // Assistant
                  title: "",
                  initials: "",
                  name: manualAssistantName,
                  qualification: "",
                  own_code: "",
                  speciality_code: manualAssistantSpeciality,
                  council_nr: "",
                  practice_nr: "",
                  doctorContactDetails: {
                    email_address: "",
                    telephone: "",
                    cell_number: "",
                    fax_number: "",
                    address_line1: "",
                    address_line2: "",
                    address_line3: "",
                    postcode: "",
                  },
                },
              ]
            : []),

        ].filter(Boolean)
        ],
        serviceCenter: {
          center_dbid: "",
          center_source_id:
            selectedServCenter?.id?.toString() ||
            reInvoice?.dbCenter?.ServiceCenterSourceID || "0",
          ServiceCenterTypeDesc: servicecenterType || "",
          centerContactDetails: {
            email_address: "",
            telephone: "",
            cell_number: "",
            fax_number: "",
            address_line1: "",
            address_line2: "",
            address_line3: "",
            postcode: "",
          },
          description:
            reInvoice?.dbCenter?.ServiceCenterName ||
            serviceCenterManualName ||
            "",
        },
        invoice: {
          invoice_dates:
            multiDates.length > 0
              ? multiDates.map((date) => ({ invoice_date: date.trim() }))
              : 
              // serviceDate.map((date) => ({ invoice_date: date.trim() })),
              [{ invoice_date: serviceDate.trim() }],
          lines: lines,
          modifier: modifier,
        },
        Attachments: [],
      };
      
      // console.log("Sending data to GoodX:", JSON.stringify(invoiceDetails, null, 2));

      try {
        let responseData;
        responseData = await processBillingRequestService.handleSubmitBilling(
          invoiceDetails
        );
        const referenceNumber =
          responseData?.parm_extra === "None"
            ? responseData.message
            : responseData?.parm_extra || "";

        if (responseData && responseData.status === 0) {
          //send clinical note on submit
          await handleClinicalNoteSend(clinicalNote);
          navigate("/confirmationpageinvoice", {
            state: {
              referenceNumber,
              patientData,
              selectedICD10Codes,
              selectedProcedureCodes,
              lines,
              selectedFileData,
              patient_name,
              newManualPatientData,
              invoiceid:responseData?.invoiceIds
            },
          });
        } else {
          //send clinical note on submit
          await handleClinicalNoteSend(clinicalNote);
          navigate("/confirmationpageinvoice", {
            state: {
              referenceNumber,
              patientData,
              selectedICD10Codes,
              selectedProcedureCodes,
              lines,
              patient_name,
              newManualPatientData,
              invoiceid:responseData?.invoiceIds
            },
          });
        }
        return responseData;
      } catch (error) {
        console.error("Error:", error);
      }
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div>
      <Sidebar heading={"INVOICE DETAILS"}>
        <div className="container" onClick={closeFlatlists}>
          <NavigateBack text={patient_name} />
          <div>
            <Formik
            enableReinitialize={reInvoice ? true : false}
              initialValues={{
                treatingDoctor:   reInvoice?.dbDoctors[0]?.DoctorName ||
                defaultTreatingDoc ||
                selectedTreatingDoc?.name || "",
                assistants: {},
                referringDoctor:
                  reInvoice?.dbDoctors[1]?.DoctorName ||
                  selectedRefDoc?.name ||
                  "",
                serviceCenter:
                  reInvoice?.dbCenter?.ServiceCenterName ||
                  selectedServCenter?.name ||
                  "",
                authorizationNumber: reInvoice?.invoiceHeader?.AuthNo || "",
                nameandsurname: nameSurname,
                serviceDate: dateToDisplay || reInvoice?.invoiceHeader?.InvoiceDate,
                pracNumber: practiceNumber || "",
                nameAndsurname: surnameAndName,
                specCode: specialityCode,
                servCenterName: serviceCenterManualName,
                servCenterType: servicecenterType,
                email: "",
                mobileNumber: "",
                disciplineType: "",
                serviceDates: "",
                selectedICD10Codes:
                  selectedICD10Codes.length > 0 ? selectedICD10Codes : [],

                selectedProcedureCodes:
                  selectedProcedureCodes.length > 0
                    ? selectedProcedureCodes
                    : [],
                nappiCodes: "",
                assistantList: selectedAssistant?.name || "",
                specialty: searchTermSpeciality,
                clinicalNote: "",
                invoiceAdjustment: "",
                weight: selectedWeight,
                height: selectedHeight,
                fromTime: "",
                toTime: "",
              }}
              validationSchema={schema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, handleSubmit, isSubmitting, values,errors }) => (
                <Form onSubmit={handleSubmit} className="search-padding">
                  <div className="row align-details">
                    {/* TREATING DOCTOR FIELD */}
                    <TreatingDoctor />
                    <ReferringDoctor />
                    <ServiceDateField
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </div>
                  <ServiceCenter
                    serviceCenterType={values.servCenterType}
                    serviceCenterName={values.servCenterName}
                  />
                  <ICD10 reInvoice={reInvoice} />
                  <ProcedureCode
                     reInvoice={reInvoice}
                     sidebar={false}
                     setSelectedAssistants={setSelectedAssistants}
                  />
                  <NappiCodeSelector />

                  {/* Add code bundle to favourites */}
                  {hasSelectedProcedureCode && (
                    <AddButton
                      isSubmitting={isSubmitting}
                      func={() => toggleSidebarAddToFavourites()}
                      src={require("../../assets/icons/Favourite Icon.png")}
                      text={"Add Code Bundles to Favourites"}
                    />
                  )}
                  {/* Sidebar add favourites*/}
                  <BillingFavourites
                    selectedICD10Codes={selectedICD10Codes}
                    selectedNappiCodes={selectedNappiCodes}
                    selectedProcedureCodes={selectedProcedureCodes}
                    isSidebarOpenAddFavourites={isSidebarOpenAddFavourites}
                    setIsSidebarOpenAddFavourites={toggleSidebar}
                  />
                  <MultipleDatePicker  />
                  <AddButton
                    isSubmitting={isSubmitting}
                    func={() => toggleClinicalNoteSidebar()}
                    src={require("../../assets/icons/Add Clinical Note to Invoice Icon.png")}
                    text={"Add Clinical Note to Invoice"}
                  />
                  <AddClinicalNote
                    clinicalNoteClicked={toggleClinicalNote}
                    patientID={
                      patientData?.id || newManualPatientData?.patientID || ""
                    }
                    closeClinicalNote={setToggleClinicalNote}
                    onNoteAdded={noteAdded}
                    isSubmit={false}
                  />
  
                  <ToastContainer position="top-end" className="p-3">
                    <Toast
                      show={show}
                      color="black"
                      autohide
                      bg="light"
                      onClose={() => setShow(false)}
                      delay={3000} // 3000ms = 3 seconds
                    >
                      <Toast.Header>
                        <strong className="me-auto">Clinical Note</strong>
                        <small>1s ago</small>
                      </Toast.Header>
                      <Toast.Body>Clinical note saved.</Toast.Body>
                    </Toast>
                  </ToastContainer>
                  <Loader loading={isSubmitting} />
                  <div className="d-flex justify-content-center">
                    <GenericButton
                      func={handleSubmit}
                      text={"Submit"}
                      disabled={isSubmitting}
                    />
                  </div>
                  <ErrorMessage
                    name="auth"
                    component="div"
                    className="error-text"
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </Sidebar>
    </div>
  );
}

export default InvoiceStepTwo;
