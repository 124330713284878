import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
const PatientAccordian = ({ debtorID, patientName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordionChevron = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      className="code-table-bq mt-4 pointer"
      onClick={toggleAccordionChevron}
      data-toggle="collapse"
      data-target="#collapseOne"
      aria-expanded={isOpen}
      aria-controls="collapseOne"
    >
      <div id="accordion" className="scrollcontainerparent">
        <div
          className="code-header d-flex justify-content-between align-items-center"
          id="headingOne"
        >
          <span className="bold-txt generalmarg">Patient Details</span>
          <div className="d-flex justify-content-center align-items-center">
            <FontAwesomeIcon
              icon={isOpen ? faChevronDown : faChevronRight }
              className="chevron-icon"
            />
          </div>
        </div>
        <div
          id="collapseOne"
          className="collapse scrollcontainer"
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body bgcolor">
            <div className="d-flex row">
              <div className="d-flex row mt-4">
                <div className="mt-2 me-2 col-12 col-lg">
                  <small className="d-flex mb-2 text-black normal-txt">
                    Account Number
                  </small>
                  <input
                    value={debtorID || ""}
                    className="form-control bg-white normal-txt"
                    disabled
                  />
                </div>
                <div className="mt-2 me-1 col-12 col-lg">
                  <small className="d-flex mb-2 text-black normal-txt">
                    Patient Name and Surname
                  </small>
                  <input
                    value={patientName || ""}
                    className="form-control bg-white normal-txt"
                    disabled
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientAccordian;
