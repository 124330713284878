import React, { useState, useEffect, useCallback } from "react";
import { ErrorMessage, useFormikContext } from "formik";
import { useMemo } from "react";
import useProcedureCodesStore from "../state/ProcedureCodeStore";
import _ from "lodash";
const SearchProcedureCode = ({
  allProcedureData,
  modifierData,
  favouriteData,
  sidebar,
  handleSelectProcedures,
  filteredProcedureData,
  setFilteredProcedureData,
}) => {
  const [searchTermProcedureCode, setSearchTermProcedureCode] = useState("");
  const { setFieldValue } = useFormikContext();
  const {
    selectedProcedureCodes,
    setSelectedProcedureCodes,
    procedureCodeFlatListVisible,
    setProcedureCodeFlatListVisible,
  } = useProcedureCodesStore();
  
  //New requirement: If code 0011 and 0008/ 0009 = then show immediately on UI 0011A
  // If the combination is found, add '0011A' to selectedProcedureCodes
  const check0011 = (code,item) => {
      if (selectedProcedureCodes.some((code) => code.code === "0011A")) {
        setSelectedProcedureCodes(
          selectedProcedureCodes.filter(
            (code) => code.code !== "0011A" && code.code !== "0011"
          )
        );
        return;
      }
      if (code === "0011A") {
        return;
      }

    // const has0011 = selectedProcedureCodes.some((code)=>code.code === "0011");
    const has0008Or0009 = selectedProcedureCodes.some(
      (code) => code.code === "0008" || code.code === "0009"
    );
    if (has0008Or0009) {
      setSelectedProcedureCodes([
        ...selectedProcedureCodes,
        { code: "0011", quantity: 1 },
        { code: "0011A", quantity: 1 },
      ]);
      return;
    }else {
      addProcedureCode(item)
    }
  };
  const addProcedureCode = (item) => {
    const isCodeSelected = selectedProcedureCodes.some(
      (code) => code.code === item.code
    );
    let newSelectedCodes;

    if (isCodeSelected) {
      newSelectedCodes = selectedProcedureCodes.filter(
        (code) => code.code !== item.code
      );
    } else {
      const newItem = {
        code: item.code,
        quantity: 1,
        description: item.description || "",
      };
      newSelectedCodes = [...selectedProcedureCodes, newItem];
      if (item.isFavorite) {
        handleSelectProcedures(item, setFieldValue);
      }
    }

    setSelectedProcedureCodes(newSelectedCodes);
    setFieldValue("selectedProcedureCodes", newSelectedCodes);
    setProcedureCodeFlatListVisible(false); // Hide the procedure code list
    setSearchTermProcedureCode("");
  }
  const checkAssistant = (item) => {
    if (selectedProcedureCodes.some(item => item.code === "0011")) {
        setSelectedProcedureCodes([
          ...selectedProcedureCodes,
          { code: "0011A", quantity: 1 },
          {code :item.code,quantity:1 }
        ]);
       }else {
        addProcedureCode(item)
       }
  }
  const handleSelectProcedureCode = (item) => {
    if (item.code ==="0008" || item.code ==="0009"){
      checkAssistant(item)
      return;
    }
    if (item.code === "0011" || item.code === "0011A") {
      check0011(item.code,item);
      return;
    }
    addProcedureCode(item)

   
  };
  const combinedData = useMemo(
    () => [...allProcedureData, ...modifierData],
    [allProcedureData, modifierData]
  );

  const handleSearchProcedureCodeInput = (text) => {
  // Combine all data sources
  // Only include favorites that aren't already in combinedData
const filteredProcedureData = [...combinedData]
    .filter((item) => {
      const lowerCasedSearchTerm = text.toLowerCase();

      // Handle procedure codes
      const code = item.code || '';
      const description = item.description || '';
      const modifierCode = item.modifierCode || '';
      const modifierDescription = item.modifierDescription || '';
      
      const codeFavourite = item.codeFavourite || item.id || ''; 
      const favouriteDescription = item.BillingTemplateDesc || ''; 

      return (
        code.toLowerCase().includes(lowerCasedSearchTerm) ||
        description.toLowerCase().includes(lowerCasedSearchTerm) ||
        modifierCode.toLowerCase().includes(lowerCasedSearchTerm) ||
        modifierDescription.toLowerCase().includes(lowerCasedSearchTerm) ||
        codeFavourite.toString().toLowerCase().includes(lowerCasedSearchTerm) ||
        favouriteDescription.toLowerCase().includes(lowerCasedSearchTerm)
      );
    })
    .sort((a, b) => {
      // Prioritize exact matches
      if (a.code === text || a.codeFavourite === text) return -1;
      if (b.code === text || b.codeFavourite === text) return 1;
      return 0;
    })
    .map((item) => ({
      code: item.code || item.codeFavourite || item.id, 
      description: item.description || item.BillingTemplateDesc || '', 
      modifierCode: item.modifierCode || '',
      modifierDescription: item.modifierDescription || '',
      isFavorite: Boolean(item.codeFavourite || item.id) 
    }))
    .slice(0, 20);

  setFilteredProcedureData(filteredProcedureData);
};
  const handleSearchCodeInputChange = (text) => {
    setSearchTermProcedureCode(text);
    if (text === "") {
      setSearchTermProcedureCode("");
    } else {
      handleSearchProcedureCodeInput(text);
      // debouncedSearchProcedureCode(text);
    }
    setProcedureCodeFlatListVisible(true);
  };
  return (
    <div className={`${!sidebar && "col-md-4"} text-font prodCodeMrgn`}>
      <label htmlFor="selectedProcedureCodes">
        Procedure Codes<span className="error-text">*</span>
      </label>
      <div style={{ position: "relative" }}>
        <input
          placeholder="Type Here to Search"
          type="text"
          value={searchTermProcedureCode}
          onChange={(e) => handleSearchCodeInputChange(e.target.value)}
          className="form-control"
          id="selectedProcedureCodes"
        />
        <i className="fas fa-search searchIcon"></i>
        {procedureCodeFlatListVisible && filteredProcedureData.length > 0 && (
          <div className="dropdown-list">
            <ul className="suggestions-list">
              {filteredProcedureData.map((item, index) => (
                <li
                  key={`${item.code || item.modifierCode}_${
                    item.description
                  }_${index}`}
                  onClick={() => handleSelectProcedureCode(item)}
                  className="d-flex justify-content-between"
                >
                  {item.code
                    ? typeof item.code === "object"
                      ? JSON.stringify(item.code)
                      : item.code
                    : "No Code"}{" "}
                  -
                  {item.description
                    ? typeof item.description === "object"
                      ? JSON.stringify(item.description)
                      : item.description
                    : "No Description"}
                    {item.isFavorite && (
                  <img 
                    src={require("../../assets/starIcon.png")}

                    alt="Favorite"
                    style={{ width: '20px', height: '20px', marginLeft: '8px' }}
                  />
                )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <ErrorMessage
        name="selectedProcedureCodes"
        component="div"
        className="error-text d-flex"
      />
    </div>
  );
};

export default SearchProcedureCode;
