// ExampleCarouselImage.js
import React from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/components/NewsItem.scss"
const NewsCarouselItem = ({ rssItem }) => {
  const navigate = useNavigate();
  return (
    <div className="p-1 pointer" onClick={() => navigate("/newsfeed")}>
      <div className="d-flex justify-content-start">
        <span className="text-white text-start">Latest News</span>
      </div>
      <div className="newsFlexBox">
        <img
          width={210}
          height={210}
          style={{ objectFit: "contain"}}
          src={require("../../assets/ClientPortal/clientPortalNews.png")}
          alt="First slide"
        />
        <div>
          <div className="d-flex flex-column justify-content-start">
            <span className="text-white text-header text-start ml-2">
              {rssItem.title}
            </span>
            <small className="text-white text-start ml-2">{rssItem.date}</small>

            <small  className="text-white text-start ml-2 show showHideDesc">
              {rssItem.description.slice(0, 120)}...
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsCarouselItem;
