import axios from "axios";
import PROD_API from "../baseEndpoint";

const monthlyStatisticService = (token) => {
  const fetchAgeAnalysis = async (year, month) => {
    try {
      const apiUrl = `${PROD_API}OPS/reports/get_stats_monthly?accessToken=${token}&year=${year}&month=${month}`;
      // console.log("MONTHLY AGE ANALYSIS111", apiUrl);

      const response = await axios.post(apiUrl);

      if (response.status === 200 && response.data) {
        return response.data;
      } else {
        console.error("Failed to retrieve invoice details. Status code:", response.status);
      }
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  return {
    fetchAgeAnalysis,
  };
};

export default monthlyStatisticService;
