import React, { useState } from 'react';

const InfoModal = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <span 
        onClick={() => setShowModal(true)}
        style={{ 
          cursor: 'pointer',
          border: '2px solid #0066cc',
          borderRadius: '50%',
          width: '24px',
          height: '24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginLeft: '8px',
          color: '#0066cc',
          fontWeight: 'bold'
        }}
      >
        i
      </span>

      {showModal && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000
        }}>
          {/* Modal Content */}
          <div style={{
            background: 'white',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '500px',
            width: '90%',
            position: 'relative',
            boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
          }}>
            {/* Header */}
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '15px',
              borderBottom: '1px solid #eee',
              paddingBottom: '10px'
            }}>
              <h3 style={{ margin: 0 }}>Monthly Breakdown Information</h3>
              <button 
                onClick={() => setShowModal(false)}
                style={{
                  border: 'none',
                  background: 'none',
                  fontSize: '20px',
                  cursor: 'pointer',
                  padding: '5px'
                }}
              >
                ×
              </button>
            </div>

            <div style={{ marginBottom: '20px', textAlign: 'justify', }}>
              <p>
                Figures are based on the selected month. If the current month is selected, 
                the data up to 17:30 of the previous business day will reflect.
              </p>
              <p>
                The monthly breakdown values of the selected month shows a percentage 
                comparison to the values of the previous month.
              </p>
            </div>

            {/* Footer */}
            <div style={{
              borderTop: '1px solid #eee',
              paddingTop: '15px',
              display: 'flex',
              justifyContent: 'flex-end'
            }}>
              <button
                onClick={() => setShowModal(false)}
                style={{
                  padding: '8px 16px',
                  background: '#0066cc',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer'
                }}
              >
                Close
              </button>
            </div>
          </div>

          {/* Overlay */}
          <div 
            onClick={() => setShowModal(false)}
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: 'rgba(0, 0, 0, 0.3)',
              zIndex: -1
            }}
          />
        </div>
      )}
    </>
  );
};

export default InfoModal;